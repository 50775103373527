import skDate from '@skello-utils/dates';
import {
  BRAIN_API_PROVENANCE_LABEL, isOverlapingShift,
} from './brain_transformers';

const brainShiftsDuplicateFilter = list => {
  const existing = {};
  return list.filter(item => {
    const key = `${item.attributes.startsAt}-${item.attributes.endsAt}-${item.attributes.userId || 'none'}-${item.attributes.posteId}`;
    if (existing[key]) {
      return false;
    }
    existing[key] = true;
    return true;
  });
};

// this function will remove the brain shifts user assignations when they are
// inside an existing shift or unavailability on the planning. So those shifts
// will be unassigned and the user will have to assign them manually.
export const brainShiftsFilterForPlanning = ({
  planningsShifts,
  brainShifts,
  planningsUsers,
  availabilities,
}) => {
  const newShifts = brainShifts.reduce((allShifts, brainShift) => {
    // check if the user exists on the planning
    const isUserExistOnPlanning = !!planningsUsers.find(u => u.id === brainShift.attributes.userId);
    const startsAt = skDate.utc(brainShift.attributes.startsAt);
    const endsAt = skDate.utc(brainShift.attributes.endsAt);

    const existingShifts = planningsShifts.filter(current => {
      const currentStartsAt = skDate.utc(current.attributes.startsAt);
      const currentEndsAt = skDate.utc(current.attributes.endsAt);

      if (
        !currentStartsAt.isSame(currentEndsAt, 'day') &&
        `${currentEndsAt.hour()}:${currentEndsAt.minute()}` === '0:0'
      ) {
        currentEndsAt.seconds(currentEndsAt.seconds() - 1);
      }

      return currentStartsAt.isSame(startsAt, 'day');
    });

    // check if the brain shift is inside an existing shift on the planning
    const isInsideExistingAssignedShift = existingShifts.filter(
      current => current.attributes.userId !== null &&
        current.attributes.userId.toString() === String(brainShift.attributes.userId),
    ).length > 0;

    const isInsideExistingBrainShift = existingShifts.filter(
      current => current.attributes.provenance === BRAIN_API_PROVENANCE_LABEL,
    ).length > 0;

    // check if the brain shift is inside an unavailability on the planning
    const isInsideUnAvailibility = availabilities
      .filter(a => a.attributes.userId !== null &&
        a.attributes.status === 'unavailable' &&
        a.attributes.userId === brainShift.attributes.userId,
      )
      .reduce((insideUnAvailibility, current) => {
        const isWeeklyRecurring = current.attributes.recurrence === 'weekly';
        const currentStartsAt = skDate.utc(current.attributes.startsAt);
        const currentEndsAt = skDate.utc(current.attributes.endsAt);

        if (isWeeklyRecurring) {
          currentStartsAt.set({
            year: startsAt.year(),
            month: startsAt.month(),
            date: startsAt.date(),
          });
        }

        if (isWeeklyRecurring) {
          currentEndsAt.set({
            year: endsAt.year(),
            month: endsAt.month(),
            date: endsAt.date(),
          });
        }

        insideUnAvailibility ||= currentStartsAt <= startsAt && endsAt <= currentEndsAt;

        return insideUnAvailibility;
      }, false);

    const isOverlapingBrainShift = allShifts.find(shift => {
      const isSameUserId = shift.attributes.userId === brainShift.attributes.userId;

      return isSameUserId && isOverlapingShift({
        shiftStartsAtSkDate: startsAt,
        shiftEndsAtSkDate: endsAt,
        anotherShiftStartsAtSkDate: skDate.utc(shift.attributes.startsAt),
        anotherShiftEndsAtSkDate: skDate.utc(shift.attributes.endsAt),
      });
    });

    if (
      !isUserExistOnPlanning ||
      (
        isUserExistOnPlanning &&
        (
          isInsideExistingAssignedShift ||
          isInsideUnAvailibility
        )
      )
    ) {
      brainShift.attributes.userId = null;
      brainShift.relationships.user = null;
    }

    if (!isInsideExistingBrainShift && !isOverlapingBrainShift) {
      allShifts.push(brainShift);
    }

    return allShifts;
  }, []);

  const uniqueNewShifts = brainShiftsDuplicateFilter(newShifts);

  const sortedNewShifts = uniqueNewShifts.sort((a, b) => {
    const aStartsAt = skDate.utc(a.attributes.startsAt);
    const bStartsAt = skDate.utc(b.attributes.startsAt);

    if (aStartsAt.isBefore(bStartsAt)) {
      return -1;
    }
    if (aStartsAt.isAfter(bStartsAt)) {
      return 1;
    }
    return 0;
  });

  return sortedNewShifts;
};
