import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';

export default class GroupedShift {
  constructor(shifts) {
    this.type = 'groupedShifts';
    this.shifts = sortBy(shifts, 'attributes.startsAt');
  }

  get id() {
    const ids = this.shifts.map(shift => shift.id);

    return ids.join('#');
  }

  get relationships() {
    return {
      poste: this.shifts[0].relationships.poste,
      previsionalPoste: this.shifts[0].relationships.previsionalPoste,
    };
  }

  get attributes() {
    return {
      userId: this.userId,
      absenceCalculation: this.absenceCalculation,
      absencePosteId: this.absencePosteId,
      absencePosteName: this.absencePosteName,
      startsAt: this.startsAt,
      previsionalStart: this.previsionalStart,
      endsAt: this.endsAt,
      previsionalEnd: this.previsionalEnd,
      pauseTime: this.pauseTime,
      nbMeal: this.nbMeal,
    };
  }

  get startsAt() {
    return this.shifts[0].attributes.startsAt;
  }

  get endsAt() {
    return this.shifts[this.shifts.length - 1].attributes.endsAt;
  }

  get previsionalStart() {
    return this.shifts[0].attributes.previsionalStart;
  }

  get previsionalEnd() {
    return this.shifts[this.shifts.length - 1].attributes.previsionalEnd;
  }

  get userId() {
    return this.shifts[0].attributes.userId;
  }

  get pauseTime() {
    return sumBy(this.shifts, shift => shift.attributes.pauseTime);
  }

  get nbMeal() {
    return sumBy(this.shifts, shift => shift.attributes.nbMeal);
  }

  get absenceCalculation() {
    return this.shifts[0].attributes.absenceCalculation;
  }

  get absencePosteId() {
    return this.absenceCalculation !== '' ? this.shifts[0].relationships.poste.id : null;
  }

  get absencePosteName() {
    return this.absenceCalculation !== '' ? this.shifts[0].relationships.poste.attributes.name : null;
  }
}
