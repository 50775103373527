import skDate from '@skello-utils/dates';
import {
  httpClient,
  svcEmployeesClient,
} from '@skello-utils/clients';
import {
  RttManager,
  countWeekendDaysBetween,
} from '@skelloapp/skello-absences';

const PAID_LEAVES_COUNT = 25;

const fillPeriods = (firstPeriodStart, yearsToFillCount) => (
  Array(yearsToFillCount)
    .fill()
    .map((_, yearNumber) => ({
      isClosed: true,
      isEmpty: true,
      startDate: firstPeriodStart.clone().add(yearNumber, 'year'),
      endDate: firstPeriodStart.clone().add(yearNumber + 1, 'year').subtract(1, 'day'),
    }))
);

const fillPeriodMissingCounters = (counters, periodStart) => (
  Array(12)
    .fill()
    .map((_, monthNumber) => {
      const periodMonth = periodStart.clone().add(monthNumber, 'month').startOf('month');
      const monthTimestamp = periodMonth.valueOf();

      return (
        counters.find(({ month }) => month.getTime() === monthTimestamp) ??
        { month: periodMonth.clone().toDate(), isPrevisional: true, showDash: true }
      );
    })
);

const mapToLibShift = shifts => shifts.map(shift => ({
  date: new Date(shift.attributes.startsAt),
  duration: shift.attributes.absenceCalculation === 'half-day' ? 'half-day' : 'day',
}));

const initialState = {
  error: null,
  balance: 0,
  bankHolidaysCount: 0,
  manualChangesCount: 0,
  owedRtt: 0,
  periodOwedRtt: 0,
  reducedRtt: 0,
  takenRtt: 0,
  periodSummary: {},
  employeeAbsenceConfig: {},
  shopRttConfig: {},
  contracts: [],
  reducingRttAbsences: [],
  rttShifts: [],
  periods: [],
  areHolidaysLoading: false,
  areRttShiftsLoading: false,
  isAbsenceConfigLoading: false,
  isRttDataLoading: false,
  areRttReducingAbsencesLoading: false,
  currentPeriodStartDate: null,
  currentPeriodEndDate: null,
  selectedPeriodStartDate: null,
  selectedPeriodEndDate: null,
  rttManager: new RttManager({}),
};

const mutations = {
  fetchEmployeeAbsenceConfigSuccess(state, payload) {
    state.employeeAbsenceConfig = payload;
  },
  fetchContractsSuccess(state, payload) {
    state.contracts = payload.data.map(contract => ({
      startDate: contract.attributes.startDate ? skDate.utc(contract.attributes.startDate).startOf('day').toDate() : null,
      endDate: contract.attributes.endDate ? skDate.utc(contract.attributes.endDate).startOf('day').toDate() : null,
      workedDaysCount: contract.attributes.workDays,
    }));
  },
  fetchRttShiftsSuccess(state, payload) {
    state.rttShifts = mapToLibShift(payload.data);
  },
  fetchRttReducingAbsencesSuccess(state, payload) {
    state.reducingRttAbsences = mapToLibShift(payload.data);
  },
  performingRequest(state, key) {
    state[key] = true;
  },
  requestComplete(state, key) {
    state[key] = false;
  },
  requestError(state, error) {
    state.error = error;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
  setBalance(state, balance) {
    state.balance = balance;
  },
  setBankHolidaysCount(state, bankHolidaysCount) {
    state.bankHolidaysCount = bankHolidaysCount;
  },
  setCurrentPeriodDates(state, { startDate, endDate }) {
    state.currentPeriodStartDate = startDate;
    state.currentPeriodEndDate = endDate;
  },
  setSelectedPeriodDates(state, { startDate, endDate }) {
    state.selectedPeriodStartDate = startDate;
    state.selectedPeriodEndDate = endDate;
  },
  setShopRttConfig(state, rttConfig) {
    state.shopRttConfig = rttConfig;
  },
  setEmployeeAbsenceConfig(state, absenceConfig) {
    const updates = absenceConfig.updates?.map(({ date, value }) => (
      { date: new Date(date), value }
    ));

    state.employeeAbsenceConfig = {
      ...absenceConfig,
      ...(!!updates && { updates }),
    };
  },
  setManualChangesCount(state, manualChangesCount) {
    state.manualChangesCount = manualChangesCount;
  },
  setOwedRtt(state, owedRtt) {
    state.owedRtt = owedRtt;
  },
  setPeriodOwedRtt(state, periodOwedRtt) {
    state.periodOwedRtt = periodOwedRtt;
  },
  setPeriods(state, periods) {
    state.periods = periods;
  },
  setParticularPeriod(state, { index, period }) {
    state.periods.splice(index, 1, period);
  },
  setReducedRtt(state, reducedRtt) {
    state.reducedRtt = reducedRtt;
  },
  setTakenRtt(state, taken) {
    state.takenRtt = taken;
  },
};

const actions = {
  computePeriods({ state, commit }) {
    const { selectedPeriodStartDate, selectedPeriodEndDate } = state;

    const restDays = countWeekendDaysBetween(
      selectedPeriodStartDate.toDate(),
      selectedPeriodEndDate.toDate(),
    );

    state.rttManager.setProps({
      absences: state.reducingRttAbsences,
      acquisitionMode: state.shopRttConfig.acquisition_mode,
      bankHolidaysCount: state.bankHolidaysCount,
      contracts: state.contracts,
      initializationDate: state.employeeAbsenceConfig.initializationDate,
      manualChanges: state.employeeAbsenceConfig.updates,
      paidLeavesCount: PAID_LEAVES_COUNT,
      reinitializationDate: selectedPeriodEndDate.clone().add(1, 'day').startOf('day').toDate(),
      restDaysCount: restDays,
      takenRtt: state.rttShifts,
    });

    const rawCounters = state.rttManager.computeRttMonthlyCounters();
    const rttCounters = fillPeriodMissingCounters(rawCounters, selectedPeriodStartDate);

    const countersInitializationDate = skDate.utc(state.shopRttConfig.initialization_date);
    const currentDate = skDate.utc();

    let selectedPeriodMonths = rttCounters.map(counter => {
      const month = skDate.utc(counter.month);
      const isAcquisitionMonthStart =
        month.year() === countersInitializationDate.year() &&
        month.month() === countersInitializationDate.month();

      return {
        acquired: counter.acquired,
        balance: counter.balance,
        date: month,
        isAcquisitionMonthStart,
        initializationCounter: counter.initializationCounter ?? 0,
        isPrevisional: counter.isPrevisional || month > currentDate,
        manualChanges: counter.manualChanges,
        reduced: counter.reduced,
        showDash: counter.showDash,
        taken: counter.taken,
      };
    });

    // handle future period display:
    // all dashed when no taken nor reduced RTT, otherwise dashed after last reduced or taken RTT
    if (selectedPeriodStartDate > currentDate) {
      const lastTakenOrReducedIndex = Math.max(
        selectedPeriodMonths.findLastIndex(({ reduced }) => reduced !== 0),
        selectedPeriodMonths.findLastIndex(({ taken }) => taken !== 0),
      );

      const dashedMonths = lastTakenOrReducedIndex === -1 ?
        selectedPeriodMonths.splice(0) :
        selectedPeriodMonths.splice(lastTakenOrReducedIndex + 1);

      selectedPeriodMonths = selectedPeriodMonths.concat(
        dashedMonths.map(counter => ({ ...counter, showDash: true })),
      );
    }

    const initializationDate = skDate.utc(state.employeeAbsenceConfig.initializationDate);
    const initializationPeriodStartDate = skDate
      .utc(state.shopRttConfig.reset_date)
      .year(initializationDate.year());

    if (initializationPeriodStartDate.isAfter(initializationDate)) {
      initializationPeriodStartDate.subtract(1, 'year');
    }

    let yearsCountFromInitPeriod =
      selectedPeriodStartDate.year() - initializationPeriodStartDate.year();
    const firstPeriodStartDate = selectedPeriodStartDate.clone().subtract(yearsCountFromInitPeriod, 'year');

    // future period is the next right after current period
    const yearsCountToFuturePeriod = skDate.utc().add(1, 'year').year() - selectedPeriodEndDate.year();
    const nextPeriodStartDate = selectedPeriodEndDate.clone().add(1, 'day');

    const currentPeriod = {
      isClosed: false,
      months: selectedPeriodMonths,
      startDate: selectedPeriodStartDate,
      endDate: selectedPeriodEndDate,
    };

    if (yearsCountFromInitPeriod < 0) yearsCountFromInitPeriod = 0;

    const periods = [
      ...fillPeriods(firstPeriodStartDate, yearsCountFromInitPeriod),
      currentPeriod,
      ...fillPeriods(nextPeriodStartDate, yearsCountToFuturePeriod),
    ];

    const now = skDate.utc().toDate();

    const owedRtt = rawCounters
      .filter(({ month }) => month <= now)
      .reduce((owedSum, counter) => owedSum + counter.acquired ?? 0, 0);

    const manualChangesCount = state.rttManager.computeRttManualChangesTotal();
    const periodOwedRtt = state.rttManager.computeRttPeriodCount();
    const takenRtt = state.rttManager.computeTakenRttPeriodCount();
    const reducedRttCount = state.rttManager.computeRttPeriodReducedCount();
    const balance = state.rttManager.computeRttPeriodBalance(owedRtt);

    commit('setBalance', balance);
    commit('setManualChangesCount', manualChangesCount);
    commit('setOwedRtt', owedRtt);
    commit('setPeriodOwedRtt', periodOwedRtt);
    commit('setReducedRtt', reducedRttCount);
    commit('setTakenRtt', takenRtt);

    commit('setPeriods', periods);
  },
  fetchCounterData({ commit, dispatch, state }, { userId, shopId, period, silentLoading }) {
    commit('performingRequest', 'isRttDataLoading');

    const selectedPeriodStartDate = period?.startDate ?? state.currentPeriodStartDate;
    const selectedPeriodEndDate = period?.endDate ?? state.currentPeriodEndDate;

    commit('setSelectedPeriodDates', {
      startDate: selectedPeriodStartDate,
      endDate: selectedPeriodEndDate,
    });

    const params = {
      endDate: selectedPeriodEndDate,
      startDate: selectedPeriodStartDate,
      silentLoading,
      userId,
    };

    return Promise.all([
      dispatch('fetchBankHolidays', { ...params, shopId }),
      dispatch('fetchContracts', params),
      dispatch('fetchRttShifts', params),
      dispatch('fetchRttReducingAbsences', params),
    ]).then(() => {
      dispatch('computePeriods');
      commit('requestComplete', 'isRttDataLoading');
    });
  },
  fetchContracts({ commit }, { userId, startDate, endDate, silentLoading }) {
    if (!silentLoading) {
      commit('performingRequest', 'loadingContracts');
    }

    const params = { start_date: startDate, end_date: endDate };

    return httpClient
      .get(`/v3/api/users/${userId}/contracts/in_period`, { params })
      .then(response => commit('fetchContractsSuccess', response.data))
      .catch(error => {
        commit('requestError', error);
        throw error;
      })
      .finally(() => { commit('requestComplete', 'loadingContracts'); });
  },
  fetchEmployeeAbsenceConfig({ commit }, { userId, shopId, absenceType, silentLoading }) {
    if (!silentLoading) {
      commit('performingRequest', 'isAbsenceConfigLoading');
    }

    return svcEmployeesClient
      .findOneAbsenceConfigByShopIdAbsenceTypeUserId(shopId, absenceType, userId)
      .then(response => {
        commit('fetchEmployeeAbsenceConfigSuccess', response);
      })
      .catch(error => {
        if (error.code !== 404) {
          commit('requestError', error);

          throw error;
        }
        commit('setEmployeeAbsenceConfig', {});
      })
      .finally(() => commit('requestComplete', 'isAbsenceConfigLoading'));
  },
  fetchBankHolidays({ commit }, { shopId, userId, startDate, endDate, silentLoading }) {
    if (!silentLoading) {
      commit('performingRequest', 'areHolidaysLoading');
    }

    const params = {
      shop_id: shopId, start_date: startDate, end_date: endDate, use_holiday_gem: true,
    };

    return httpClient
      .get(`/v3/api/users/${userId}/holiday_settings`, { params })
      .then(response => {
        commit('requestComplete', 'areHolidaysLoading');

        const payload = response.data;
        const bankHolidaysCount = payload.data.reduce((holidaysCount, setting) => {
          const weekDay = skDate.utc(setting.date ?? setting.attributes.date).isoWeekday();

          return weekDay < 6 ? holidaysCount + 1 : holidaysCount;
        }, 0);

        commit('setBankHolidaysCount', bankHolidaysCount);

        return bankHolidaysCount;
      })
      .catch(error => {
        commit('requestError', error);

        throw error;
      })
      .finally(() => commit('requestComplete', 'areHolidaysLoading'));
  },
  fetchRttShifts({ commit }, { userId, startDate, endDate, silentLoading }) {
    if (!silentLoading) {
      commit('performingRequest', 'areRttShiftsLoading');
    }

    const params = {
      user_id: userId,
      absence_key: ['work_time_recovery', 'fixed_price_contract_rest'],
      start_date: startDate.format(),
      end_date: endDate.format(),
      skip_pagination: 'true',
    };

    return httpClient
      .get('/v3/api/shifts', { params })
      .then(response => {
        commit('requestComplete', 'areRttShiftsLoading');
        commit('fetchRttShiftsSuccess', response.data);
      })
      .catch(error => commit('requestError', error))
      .finally(() => commit('requestComplete', 'areRttShiftsLoading'));
  },
  fetchRttReducingAbsences({ commit }, { userId, startDate, endDate, silentLoading }) {
    if (!silentLoading) {
      commit('performingRequest', 'areRttReducingAbsencesLoading');
    }

    const params = {
      user_id: userId,
      absence_flag: 'rtt_reducing',
      start_date: startDate.format(),
      end_date: endDate.format(),
      skip_pagination: 'true',
    };

    return httpClient
      .get('/v3/api/shifts', { params })
      .then(response => {
        commit('fetchRttReducingAbsencesSuccess', response.data);
      })
      .catch(error => commit('requestError', error))
      .finally(() => commit('requestComplete', 'areRttReducingAbsencesLoading'));
  },
  setPeriodDates({ commit, state }) {
    const currentDate = skDate.utc();
    const currentMonth = currentDate.month();

    const resetDate = skDate.utc(state.shopRttConfig.reset_date);

    const periodEndDate = resetDate.clone().subtract(1, 'day');
    const periodEndMonth = periodEndDate.month();
    const periodEndYear = periodEndMonth < currentMonth ?
      currentDate.year() + 1 :
      currentDate.year();

    periodEndDate.year(periodEndYear);
    const periodStartDate = periodEndDate.clone().subtract(1, 'year').add(1, 'day');

    commit('setCurrentPeriodDates', { startDate: periodStartDate, endDate: periodEndDate });
    commit('setSelectedPeriodDates', { startDate: periodStartDate, endDate: periodEndDate });
  },
};

const getters = {
  hasCounters: state => Object.keys(state.employeeAbsenceConfig).length > 0,
  isRttCounterLoading: state => (
    state.areHolidaysLoading ||
    state.areRttReducingAbsencesLoading ||
    state.areRttShiftsLoading ||
    state.isAbsenceConfigLoading
  ),
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: { ...initialState },
};
