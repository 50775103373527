// eslint-disable-next-line
export const truncateString = (string, charLimit) => {
  const limit = charLimit || 50;
  return string.length > limit ? `${string.substring(0, limit)}...` : string;
};

// Elegant capitalize names
// Capitalize first letter, lower everything else
// Captialize after hyphens and spaces
// Examples: capitalize('jean-clément') > 'Jean-Clément'
// capitalize('JEAN-CLÉMENT') > 'Jean-Clément
export const capitalize = string => {
  if (!string || string.length < 1) return '';

  const substring = Array.from(string.toLowerCase().substring(1));
  for (let i = 0; i < substring.length - 1; i += 1) {
    if (substring[i] === '-' || substring[i] === ' ') {
      substring[i + 1] = substring[i + 1].toUpperCase();
    }
  }

  return string.charAt(0).toUpperCase() + substring.join('');
};

export const nlToBr = text => {
  if (typeof text !== 'string') return '';

  return text.replace(/\n/g, '<br>');
};

export const camelToSnake = string => string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const employeeFullName = (firstName, lastName) => `${capitalize(firstName) } ${capitalize(lastName)}`;

export const toSnakeCase = string => string.replace(/\s+/g, '_').toLowerCase();
