import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatShopPaidBreakDisabledContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    pause_compensation_starts_at: data.pauseCompensationStartsAt,
  },
});

export const formatShopPaidBreakDisabled =
  async (store, data) => [formatShopPaidBreakDisabledContext(store, data)];
