import { httpClient } from '@skello-utils/clients';

const initialState = {
  error: null,
  isShopAbsenceConfigLoading: false,
  shopAbsenceConfig: null,
};

const mutations = {
  performingRequest(state) {
    state.isShopAbsenceConfigLoading = true;
  },

  requestComplete(state) {
    state.isShopAbsenceConfigLoading = false;
  },

  shopAbsenceConfigSuccess(state, payload) {
    state.shopAbsenceConfig = payload.data;
  },

  shopAbsenceConfigDeleteSuccess(state) {
    state.shopAbsenceConfig = null;
  },

  catchShopAbsenceConfigError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchShopAbsenceConfig({ commit }, shopId) {
    commit('performingRequest');

    return httpClient
      .get(`/v3/api/shops/${shopId}/shop_absence_config`)
      .then(response => {
        commit('shopAbsenceConfigSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchShopAbsenceConfigError', error.response);
        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
  createAbsenceConfig({ commit }, params) {
    commit('performingRequest');

    return httpClient
      .patch(`/v3/api/shops/${params.shop_id}/shop_absence_config`, params.shopAbsenceConfig)
      .then(response => {
        commit('shopAbsenceConfigSuccess', response.data);
        return response;
      })
      .catch(error => {
        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
  deleteAbsenceConfig({ commit }, shopId) {
    commit('performingRequest');

    return httpClient
      .delete(`/v3/api/shops/${shopId}/shop_absence_config`)
      .then(() => {
        commit('shopAbsenceConfigDeleteSuccess');
      })
      .catch(error => {
        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
