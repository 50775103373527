import { httpClient } from '@skello-utils/clients';
import { FEATURES } from '../../constants/features';

/**
 * @namespace
 */
const initialState = {
  loading: false,
  /**
   * @type {{
   *     [key: number]: {
   *       [key: string]: object
   *     }
   *   }}
   */
  features: {},
  /**
   * @type {boolean}
   */
  featuresFetched: false,
};

/**
 * @type {import('vuex').Module<initialState>}
 */
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setFeatures: (state, newFeatures) => {
      state.features = newFeatures;
    },
    setLoading: (state, newLoading) => {
      state.loading = newLoading;
    },
    setFeaturesFetched: (state, newFeaturesFetched) => {
      state.featuresFetched = newFeaturesFetched;
    },
  },

  getters: {
    // We check that the shop is part of the new bundle system first,
    // Then we check if the shop has the timeclock but not the planning feature.
    hasTimeclockButNotPlanning: (_state, getters) => (shopId = 'all') => {
      if (!getters.isBundleSystemActive(shopId)) return false;

      return (
        getters.isFeatureEnabled(FEATURES.FEATURE_TIMECLOCK, shopId) &&
        !getters.isFeatureEnabled(FEATURES.FEATURE_PLANNING)
      );
    },
    // We check that the FF for the new bundle system is enabled.
    // If not, we return false
    // If yes, we check that the shop in params has the feature "new_bundle_system" in its features_states
    isBundleSystemActive: (state, _getters, _rootState, rootGetters) => (shopId = 'all') => {
      const bundleSystemEnabled = rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_NEW_STANDALONE_BUNDLES');
      if (!bundleSystemEnabled) return false;

      if (shopId === 'all') return Object.values(state.features).some(shop => FEATURES.FEATURE_NEW_BUNDLE_SYSTEM in shop);

      const shopFeatures = state.features[shopId] || new Set();
      return FEATURES.FEATURE_NEW_BUNDLE_SYSTEM in shopFeatures;
    },
    isFeatureEnabled: (state, getters) => (feature, shopId = 'all', fallback = () => false) => {
      if (!getters.isBundleSystemActive(shopId)) return fallback();
      if (shopId === 'all') return Object.values(state.features).some(shop => feature in shop);

      return feature in state.features[shopId];
    },
    areFeaturesEnabled: (_, getters) => (features, shopId = 'all', fallback = () => false) => (
      features.every(feature => getters.isFeatureEnabled(feature, shopId, fallback))
    ),
  },
  actions: {
    fetchFeatures: async ({ state, commit }, shopId = 'all') => {
      if (state.featuresFetched) return Promise.resolve();

      commit('setLoading', true);

      const url = shopId === 'all' ? '/v3/api/features' : `/v3/api/features/${shopId}`;

      return httpClient(url)
        .then(response => {
          commit('setFeatures', response.data.features_by_shop);
        })
        .finally(() => {
          commit('setLoading', false);
          commit('setFeaturesFetched', true);
        });
    },
  },
};
