// Helpers used to manage business logic related to plannings

import skDate from '@skello-utils/dates';
import {
  ABSENCE_TYPE_HOURS,
  ABSENCE_TYPE_DAY,
  ABSENCE_TYPE_HALF_DAY,
} from '@app-js/shared/constants/shift';

/*
 * openingAndClosingTimeAt returns correct opening and closing time skDate object for a given day
 * shopOpeningTime and shopClosingTime must be in HH:mm format as you would find it in
 * currentShop.attributes.openingTime and currentShop.attributes.closingTime
 * date must be in ISO 8601 (.format()) but in UTC0 e.g. 2021-09-27T00:00:00Z
 * Example of a correct call: openingAndClosingTimeAt('08:00', '18:00', '2021-09-27T00:00:00Z')
 */
export const openingAndClosingTimeAt = (shopOpeningTime, shopClosingTime, date) => {
  const checkHHmm = /^[0-2][0-9]:?[0-5][0-9]$/;
  const skCurrentDate = skDate(date, skDate.ISO_8601).utc();
  if (
    !checkHHmm.test(shopOpeningTime) ||
    !checkHHmm.test(shopClosingTime) ||
    !skCurrentDate.isValid() ||
    date.slice(-1) !== 'Z'
  ) {
    throw new Error('openingAndClosingTimeAt(): invalid parameters, please check function comment');
  }
  const openingTimeAsString = shopOpeningTime.split(':');
  const openingTime = skCurrentDate.clone()
    .hours(openingTimeAsString[0])
    .minutes(openingTimeAsString[1])
    .seconds(0)
    .milliseconds(0);
  const closingTimeAsString = shopClosingTime.split(':');
  const closingTime = skCurrentDate.clone()
    .hours(closingTimeAsString[0])
    .minutes(closingTimeAsString[1])
    .seconds(0)
    .milliseconds(0);

  // If starts_at is >= ends_at -> change ends_at to next day
  if (closingTime.isSameOrBefore(openingTime)) {
    closingTime.add(1, 'd');
  }

  return { openingTime, closingTime };
};

// The following two-level object contains computed openingAndClosingTimeAt results
// stored by shop id first and by date second
// Structure is as follows:
// {
//   "123": {
//       "2022-02-23": openingAndClosingTimeAt(...),
//       "2022-02-24": openingAndClosingTimeAt(...),
//   },
//   "124": {
//       "2022-02-23": openingAndClosingTimeAt(...),
//       "2022-02-24": openingAndClosingTimeAt(...),
//   },
// }
// In order to avoid calculating multiple openingAndClosingTimeAt for the same
// shop and the same date
// NOTE: It is only accessed through getOpeningTimesByShopAndDate
const openingTimesByShopAndDate = {};
// Getter associated with openingTimesByShopAndDate
export const getOpeningTimesByShopAndDate = (shift, date) => {
  const { shopId, shopOpeningTime, shopClosingTime } = shift.attributes;

  // If there is no entry in openingTimesByShopAndDate for shop -> add it
  if (!openingTimesByShopAndDate[shopId]) {
    openingTimesByShopAndDate[shopId] = {};
  }

  // If there is no entry for specific date -> add it
  const dateKey = date.format('YYYY-MM-DD');
  if (!openingTimesByShopAndDate[shopId][dateKey]) {
    openingTimesByShopAndDate[shopId][dateKey] = openingAndClosingTimeAt(
      shopOpeningTime,
      shopClosingTime,
      date.format(),
    );
  }

  return openingTimesByShopAndDate[shopId][dateKey];
};

export const getValidShiftTimes = ({ startsAt, endsAt }, shop, date) => {
  const skStartsAt = skDate(startsAt).utc();
  const skEndsAt = skDate(endsAt).utc();
  const skCurrentDate = skDate(date).utc(true);
  const shopTimes = openingAndClosingTimeAt(
    shop.attributes.openingTime,
    shop.attributes.closingTime,
    skCurrentDate.format(),
  );

  const shouldAddDayOnStartsAt = skStartsAt.hours() < shopTimes.openingTime.hours() &&
    skStartsAt.hours() < shopTimes.closingTime.hours();
  const shouldAddDayOnEndsAt = skEndsAt.isAfter(skStartsAt, 'day');

  // WARNING - SIDE EFFECT WITH momentjs add method alters the object state
  // therefore, it affects further use of the same object
  //   => here, if shouldAddDayOnStartsAt AND shouldAddDayOnEndsAt are true, 2 days are added to endsAt
  // if shouldAddDayOnEndsAt is false, we take the date from the startsAt to compute endsAt
  const validStartsAtDate = shouldAddDayOnStartsAt ? skCurrentDate.add(1, 'd').format('YYYY-MM-DD') : date;
  const validEndsAtDate = shouldAddDayOnEndsAt ? skCurrentDate.add(1, 'd').format('YYYY-MM-DD') : validStartsAtDate;

  startsAt = validStartsAtDate + startsAt.slice(10);
  endsAt = validEndsAtDate + endsAt.slice(10);

  return { startsAt, endsAt };
};

export const nextWorkShiftTimes = (lastWorkShift, shop, date) => {
  const { openingTime, closingTime } = openingAndClosingTimeAt(
    shop.attributes.openingTime,
    shop.attributes.closingTime,
    skDate(date).utc().format(),
  );

  let lastShiftEndsAt;
  let startsAt;
  let endsAt;
  if (lastWorkShift) {
    lastShiftEndsAt = skDate(lastWorkShift.attributes.endsAt).utc();
  }
  // fallback to default shift if shift's endsAt is after closing time or before opening time
  if (
    lastWorkShift &&
      lastShiftEndsAt.isBefore(closingTime) &&
      lastShiftEndsAt.isSameOrAfter(openingTime)
  ) {
    // if last shift ends before opening
    startsAt = lastShiftEndsAt.format();
    // If previous shift ends more than 4 hours before shop closing time
    // -> set endsAt to startsAt + 4h
    // otherwise, endsAt is set to shop closing time
    endsAt = skDate.min(
      lastShiftEndsAt.add(4, 'h'),
      closingTime,
    ).format();
  } else {
    const shopAmplitude = skDate.duration(closingTime.diff(openingTime));
    startsAt = openingTime.format();
    endsAt =
      skDate(startsAt)
        .add(shopAmplitude.asHours() / 2, 'h')
        .utc()
        .format();
  }

  return { startsAt, endsAt };
};

export const dateToWeekDayLetters = date => {
  const dayLetters = skDate(date).format('dd');
  return dayLetters.charAt(0).toUpperCase() + dayLetters.slice(1);
};

// FIX dirty data coming from v2 or templates
// some absenceShifts are missing absenceCalculation
// We try to recompute it
const computeAbsenceCalculation = shift => {
  if (shift.attributes.absenceCalculation) {
    return shift.attributes.absenceCalculation;
  }
  if (shift.attributes.dayAbsence) {
    return ABSENCE_TYPE_DAY;
  }
  const hoursWorth = parseInt(shift.attributes.hoursWorth, 10);
  if (!hoursWorth) {
    return ABSENCE_TYPE_HOURS;
  }
  // manual rule, let's assume that more than 5 hours is a full day
  if (hoursWorth > 5) {
    return ABSENCE_TYPE_DAY;
  }
  return ABSENCE_TYPE_HALF_DAY;
};

// Two following functions calculate the default hours worth for an absence
// They are used in the sanitize shift process for drag & drop
const getBaseDayHoursValue = (posteId, globalConfig) => {
  // TODO: This will be corrected on ticket DEV-14145
  // Do not take this code as reference on how to deal with absences
  // since it was developed that way to handle a hot-fix
  const paidLeaveAbsenceId = globalConfig.absences.find(absence => (
    (absence.attributes.absenceKey ===
      globalConfig.config.absence_data.paid_leave_absence_key) ||
      (absence.attributes.absenceKey ===
        globalConfig.config.absence_data.paid_leave_absence_key_es)
  )).id;

  let absenceDataHoursValue = 0;
  if (globalConfig.isShopOnPaidVacationCalculationTypeOpeningDay &&
    posteId === paidLeaveAbsenceId) {
    absenceDataHoursValue = globalConfig.config.absence_data.opening_day_hours_value;
  } else if (globalConfig.isShopOnPaidVacationCalculationTypeCalendarDay &&
    posteId === paidLeaveAbsenceId) {
    absenceDataHoursValue = globalConfig.config.absence_data.calendar_day_hours_value;
  } else {
    absenceDataHoursValue = globalConfig.config.absence_data.working_day_hours_value;
  }

  return absenceDataHoursValue;
};

const userWithoutContractHours = user => user.attributes?.onExtra || user.attributes?.onDayRate;

const getDefaultAbsenceDurationInSeconds = (shift, newUser, globalConfig) => {
  const baseDayHoursValue = getBaseDayHoursValue(shift.relationships.poste.id, globalConfig);

  const newUserContractHours = !newUser || userWithoutContractHours(newUser) ?
    globalConfig.currentShop.attributes.legalWeeklyHours :
    newUser.attributes?.currentContractHours;

  const absenceDurationInSeconds = (newUserContractHours / baseDayHoursValue) * 3600;

  if (shift.attributes.absenceCalculation === ABSENCE_TYPE_HALF_DAY) {
    return absenceDurationInSeconds / 2;
  }

  return parseFloat(absenceDurationInSeconds.toFixed(2));
};

export const sanitizeShift = (
  shift,
  verifyAbsenceDurationInSeconds = false,
  { newUser, oldUser } = { newUser: null, oldUser: null },
  globalConfig = null,
) => {
  const isAbsence = !!shift.relationships.poste.attributes.absenceKey;

  // sanitizing absence-shift attributes
  shift.attributes.absenceCalculation = isAbsence ? computeAbsenceCalculation(shift) : '';
  shift.attributes.dayAbsence =
    shift.attributes.absenceCalculation === ABSENCE_TYPE_DAY;

  const absenceDurationInSeconds = shift.attributes.absenceDurationInSeconds;
  shift.attributes.absenceDurationInSeconds =
    isAbsence && absenceDurationInSeconds ? absenceDurationInSeconds : 0;

  // sanitizing work-shift attributes
  shift.attributes.pauseTime = isAbsence || !shift.attributes.pauseTime ?
    0 : shift.attributes.pauseTime;
  shift.attributes.nbMeal = isAbsence || !shift.attributes.nbMeal ?
    0 : shift.attributes.nbMeal;
  shift.attributes.delay =
    isAbsence || !shift.attributes.delay ? 0 : shift.attributes.delay;

  // Remove front-specific attributes
  delete shift.attributes.startsAtForDisplay;
  delete shift.attributes.isOutOfShopHours;

  const isAbsenceCalculation = shift.attributes.absenceCalculation === ABSENCE_TYPE_DAY ||
    shift.attributes.absenceCalculation === ABSENCE_TYPE_HALF_DAY;

  // for unassigned shift, we need to recalculate hours worth value with new user
  const isUnassignedShift = oldUser?.id === null;

  // We need to recalculate hours worth value for day and half_day absences if the original value
  // wasn't changed -> if it matches the default value for the original shift user
  const isSameAbsenceDurationAsOriginal = !!oldUser?.id &&
    shift.attributes.absenceDurationInSeconds ===
      getDefaultAbsenceDurationInSeconds(shift, oldUser, globalConfig);

  if (!!newUser?.id &&
      isAbsenceCalculation &&
      verifyAbsenceDurationInSeconds &&
      (isSameAbsenceDurationAsOriginal || isUnassignedShift)) {
    shift.attributes.absenceDurationInSeconds = getDefaultAbsenceDurationInSeconds(
      shift,
      newUser,
      globalConfig,
    );
  }
};

// This method is to be used only for 24h shops!
export const updateShiftEndsAtFor24hShop = shift => {
  const startsAt = skDate(shift.attributes.startsAt).utc();
  const endsAt = skDate(shift.attributes.endsAt).utc();

  const duration = skDate.duration(endsAt.diff(startsAt));
  // on 24h shop if endsAt is before startsAt, need to add a day
  if (endsAt.isSameOrBefore(startsAt)) {
    shift.attributes.endsAt = endsAt.add(1, 'd').utc().format();
  } else if (duration.asSeconds() > 86400) {
    // edge case: if user set an endsAt before startsAt a day was added.
    // Then if he changes again the startsAt BEFORE endsAt, the day needs to be removed
    shift.attributes.endsAt = endsAt.add(-1, 'd').utc().format();
  }
};

export const isContractStartedForUserAtDate = (user, date) => {
  if (!user.attributes.onExtra) return true;

  const userContractStartDate = user.attributes.hiringDate ?
    skDate(user.attributes.hiringDate).utc().format('YYYY-MM-DD') :
    null;

  return !userContractStartDate || skDate(date).utc(true).isSameOrAfter(userContractStartDate);
};

// This method return an Array containing all working hours of the one shop pass through parameters
// formatted as a string ['2022-08-11T10:20:33Z', ...]
export const workingHours = (currentShop, currentDate) => {
  const { openingTime: shopOpeningTime, closingTime: shopClosingTime } = currentShop.attributes;
  const { openingTime, closingTime } = openingAndClosingTimeAt(
    shopOpeningTime,
    shopClosingTime,
    skDate.utc(currentDate).format(),
  );

  const openedHours = skDate.duration(closingTime.diff(openingTime)).asHours();
  return Array(openedHours + 1).fill().map(
    () => {
      const formattedHour = openingTime.format();
      openingTime.add(1, 'hour');
      return formattedHour;
    },
  );
};

// Note about isBetween boundaries:
// [) => lower boundary is included and upper boundary is excluded
export const isShiftDateInRange = (date, rangeStart, rangeEnd) => (
  date.isBetween(rangeStart, rangeEnd, undefined, '[)')
);

export const applyHourToDate = (date, hours) => {
  const hour = hours.split(':')[0];
  const minute = hours.split(':')[1];

  return skDate(date).set({
    hour: parseInt(hour, 10),
    minute: parseInt(minute, 10),
  });
};

export const computeShiftsExpandedRange = (
  selectedRange,
  activeAlertsList,
  maxDayStraightNb,
) => {
  const shiftsRange = { ...selectedRange };
  let outOfWeekStartsAt = skDate(shiftsRange.starts_at);
  let outOfWeekEndsAt = skDate(shiftsRange.ends_at);

  // Extending the scope is only applicable for front shifts alerts
  if (activeAlertsList.includes('daily_rest')) {
    outOfWeekStartsAt = skDate(shiftsRange.starts_at).subtract(1, 'days');
    outOfWeekEndsAt = skDate(shiftsRange.ends_at).add(1, 'days');
  }

  if (activeAlertsList.includes('six_days_straight')) {
    outOfWeekStartsAt = skDate(shiftsRange.starts_at).subtract(maxDayStraightNb, 'days');
    outOfWeekEndsAt = skDate(shiftsRange.ends_at).add(maxDayStraightNb, 'days');
  }

  shiftsRange.starts_at = outOfWeekStartsAt.format('YYYY-MM-DD');
  shiftsRange.ends_at = outOfWeekEndsAt.format('YYYY-MM-DD');

  return shiftsRange;
};

export const formatVisibleDays = (weeklyOptions, visibleDaysInfo, openingInfo, generalInfo) => {
  /**
   * Format visible days to add some useful information
   * @param {Array} weeklyOptions
   * @param {Object} visibleDaysInfo
   * @param {Object} openingInfo
   * @param {Object} generalInfo
   */
  const formattedVisibleDays = [];
  const weeklyOptionsMerge = weeklyOptions.reduce(
    (acc, { validatedDays, intermediateLockedDays, permanentLockedDays }) => {
      acc.validatedDays.push(...validatedDays);
      acc.intermediateLockedDays.push(...intermediateLockedDays);
      acc.permanentLockedDays.push(...permanentLockedDays);
      return acc;
    },
    { validatedDays: [], intermediateLockedDays: [], permanentLockedDays: [] },
  );

  const { validatedDays, intermediateLockedDays, permanentLockedDays } = weeklyOptionsMerge;

  const {
    events,
    holidays,
    initialDate,
  } = generalInfo;
  const { hour, minute } = openingInfo;
  for (let idx = 0; idx < visibleDaysInfo.days; idx += 1) {
    const isVisible = visibleDaysInfo.visibleDays[idx % 7];
    if (isVisible) {
      const currentDate = initialDate.clone().utc(true)
        .add(idx, 'day')
        .set({ hour, minute });
      const formatedDate = currentDate.format('YYYY-MM-DD');
      const event = events.find(item => item.attributes.date === formatedDate);
      const holiday = holidays.find(item => item.date === formatedDate);

      formattedVisibleDays.push({
        event,
        holiday,
        date: formatedDate,
        weekDay: currentDate.locale('en').format('dddd').toLowerCase(), // used to filter availabilities
        validated: validatedDays[idx],
        intermediateLocked: intermediateLockedDays[idx],
        permanentLocked: permanentLockedDays[idx],
        isLocked: validatedDays[idx] || intermediateLockedDays[idx] || permanentLockedDays[idx],
        isLastVisibleDay: idx === visibleDaysInfo.days - 1,
      });
    }
  }
  return formattedVisibleDays;
};

// Returns shifts in period
export const filterShiftsForPeriod = (shifts, startDate, endDate) => {
  if (startDate === null || endDate === null) {
    return shifts;
  }

  // Upper boundary is set to day following endDate at opening time
  // This allows to include out of hours shifts which are between endDate at closing time
  // and the following day at opening time -> Product specification
  endDate.add(1, 'day');

  // -> remove shifts which are before startDate and after endDate
  return shifts.filter(shift => {
    const startBoundary = getOpeningTimesByShopAndDate(
      shift,
      startDate,
    ).openingTime;
    const endBoundary = getOpeningTimesByShopAndDate(
      shift,
      endDate,
    ).openingTime;

    return isShiftDateInRange(shift.attributes.startsAtForDisplay, startBoundary, endBoundary);
  });
};

// Set startsAtForDisplay attribute on each shift
// This attribute is used in later computations to determine on which day the shift is displayed
export const setShiftsStartsAtForDisplay = shifts => {
  shifts.forEach(shift => {
    if (shift.attributes.startsAtForDisplay) return;

    // Applies badging specific logic to compute startsAtForDisplay
    const { previsionalStart, startsAt } = shift.attributes;
    // For shift start: Prioritize previsionalStart to handle badged shifts correctly
    shift.attributes.startsAtForDisplay = skDate(previsionalStart || startsAt).utc();
  });
};

export const roundMinutesToQuarterHour = minutes => {
  const nbQuarters = Math.round(minutes / 15);

  return nbQuarters * 15;
};

export const intToTwoDigitFormat = number => {
  // We need to hardcode the locale to 'fr-FR' to get the correct format
  const formatter = new Intl.NumberFormat('fr-FR', { minimumIntegerDigits: 2 });

  return formatter.format(number);
};

export const formatDurationToHoursAndMinutes = timeInMinutes => {
  const absTimeInMinutes = Math.abs(timeInMinutes);

  if (absTimeInMinutes < 60) {
    // If absTimeInMinutes is less than 60, duration.format('hh:mm') returns only 'mm'
    return { hours: '00', minutes: intToTwoDigitFormat(absTimeInMinutes) };
  }

  const duration = skDate.duration(absTimeInMinutes, 'm');
  const [hours, minutes] = duration.format('hh:mm').split(':');

  return { hours, minutes };
};

export const getOverlappingShifts = (collection, shiftToCheck) => {
  const shiftToCheckStartsAt = skDate(shiftToCheck.attributes.startsAt).utc();
  const shiftToCheckEndsAt = skDate(shiftToCheck.attributes.endsAt).utc();

  return collection.filter(shift => {
    const shiftStartsAt = skDate(shift.attributes.startsAt).utc();
    const shiftEndsAt = skDate(shift.attributes.endsAt).utc();

    const haveSameStart = shiftStartsAt.isSame(shiftToCheckStartsAt);
    const haveSameEnd = shiftEndsAt.isSame(shiftToCheckEndsAt);

    const shiftStartIsInside = shiftToCheckStartsAt.isBetween(
      shiftStartsAt,
      shiftEndsAt,
    );
    const shiftEndIsInside = shiftToCheckEndsAt.isBetween(
      shiftStartsAt,
      shiftEndsAt,
    );

    return (
      shiftStartIsInside ||
      shiftEndIsInside ||
      haveSameStart ||
      haveSameEnd
    );
  });
};

export const computeNumberOfUsersToFetch = ({ height, rowHeight = 48 }) => {
  const MIN_USERS_TO_FETCH = 15;
  const MAX_USERS_TO_FETCH = 50; // defined in paginable.rb
  const USERS_TO_FETCH_SCALING_FACTOR = 1.5;
  const ASSUMED_ROW_HEIGHT_IN_PX = rowHeight;

  const numberOfUsersToFetch = Math.ceil(
    (height / ASSUMED_ROW_HEIGHT_IN_PX) * USERS_TO_FETCH_SCALING_FACTOR,
  );
  const minUsers = Math.max(numberOfUsersToFetch, MIN_USERS_TO_FETCH);
  return Math.min(minUsers, MAX_USERS_TO_FETCH);
};
