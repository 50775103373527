import skDate from '@skello-utils/dates';

const HALF_DAY_HOURS = 12;

class BadgingAnomalyDetector {
  constructor(matchedBadging) {
    this.matchedBadging = matchedBadging;
  }

  /**
   * Anomaly section
   * @returns {string|null} anomaly reason
   */
  getAnomalyReason() {
    // Rule 1: Missed Badging (Shifts without associated punches)
    if (!this.matchedBadging.badgingId && !this.matchedBadging.turnedAsAbsence) {
      return 'shifts_without_badgings';
    }

    // Rule 2: Unscheduled Badging (Punches without associated shifts)
    if (!this.matchedBadging.shift?.relationships.previsionalPoste?.id) {
      return 'badgings_without_shifts';
    }

    // Rule 3: Employee Not Scheduled (Shifts scheduled as absences where the employee punched in)
    if (this.matchedBadging.previsionalPosteIsAbsence) {
      return 'badgings_as_planned_absence';
    }

    // Rule 4: Employee Absent (Scheduled shifts where the employee was absent)
    if (this.matchedBadging.turnedAsAbsence) {
      return 'shifts_turned_absence';
    }

    // Rule 5, 6: Conflict with Default Start Times (Late/Early arrivals based on settings)
    const anomalyStartTimeConflicted = this.getAnomalyStartTimeConflicted();
    if (anomalyStartTimeConflicted) {
      return anomalyStartTimeConflicted;
    }

    // Rule 7, 8: Conflict with Default End Times (Late/Early departures based on settings)
    const anomalyEndTimeConflicted = this.getAnomalyEndTimeConflicted();
    if (anomalyEndTimeConflicted) {
      return anomalyEndTimeConflicted;
    }

    return null;
  }

  /**
   * Check anomaly start time conflicted between predicted "shift" start time vs validated "shift" start time)
   * use case predicted is the same as validated but the badge is different:
   * if scheduled = 08:00 and badged at 07:45 then validated = 08:00 and the option earlyArrivalTakesPlannedDate is true => return null
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:00 and the option lateArrivalTakesPlannedDate is true => return null
   * if scheduled = 08:00 and badged at 07:45 then validated = 08:00 and the option earlyArrivalTakesPlannedDate is false => return deviances_early_arrival
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:00 and the option lateArrivalTakesPlannedDate is false => return deviances_late_arrival
   * use case early deviance:
   * if scheduled = 08:00 and badged at 07:45 then validated = 07:45 and the option earlyArrivalTakesPlannedDate is true => return deviances_early_arrival
   * if scheduled = 08:00 and badged at 07:45 then validated = 07:45 and the option earlyArrivalTakesPlannedDate is false => return null
   * use case late deviance:
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:15 and the option lateArrivalTakesPlannedDate is true => return deviances_late_arrival
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:15 and the option lateArrivalTakesPlannedDate is false => return null
   * @returns {string|null} anomaly reason
  */
  getAnomalyStartTimeConflicted() {
    const predictedParsedDate = skDate.utc(this.matchedBadging.predictedStartsAt)
      .seconds(0).milliseconds(0);

    const validatedParsedDate = this.getSelectedStartAtDateUtc(
      predictedParsedDate, this.matchedBadging.selectedStartsAt,
    );

    const badgingIn = skDate.utc(this.matchedBadging.badgingStartsAt).seconds(0).milliseconds(0);

    if (!validatedParsedDate) return null;

    if (validatedParsedDate.isSame(predictedParsedDate)) {
      if (validatedParsedDate.isSame(badgingIn)) {
        return null;
      }

      if (!this.matchedBadging.settings.earlyArrivalTakesPlannedDate &&
        validatedParsedDate.isAfter(badgingIn)) {
        return 'deviances_early_arrival';
      }

      if (!this.matchedBadging.settings.lateArrivalTakesPlannedDate &&
        validatedParsedDate.isBefore(badgingIn)) {
        return 'deviances_late_arrival';
      }
    }

    if (validatedParsedDate.isBefore(predictedParsedDate)) {
      if (this.matchedBadging.settings.earlyArrivalTakesPlannedDate) {
        return validatedParsedDate.isSame(badgingIn) ? 'deviances_early_arrival' : null;
      }

      return validatedParsedDate.isSame(badgingIn) ? null : 'deviances_early_arrival';
    }

    // isAfter
    if (this.matchedBadging.settings.lateArrivalTakesPlannedDate) {
      return validatedParsedDate.isSame(badgingIn) ? 'deviances_late_arrival' : null;
    }

    return validatedParsedDate.isSame(badgingIn) ? null : 'deviances_late_arrival';
  }

  /**
   *
   * @param {skDate} predictedParsedDate - predicted date
   * @param {string} selectedStartsAt - choice start time hh:mm
   * @returns {skDate|null} selected start date utc
   */
  getSelectedStartAtDateUtc(predictedParsedDate, selectedStartsAt) {
    const selectedDate = skDate.utc(`${predictedParsedDate.format('YYYY-MM-DD')}T${selectedStartsAt}`);

    const startAt = this.handleDayDifferences(predictedParsedDate, selectedDate);
    if (!startAt.isValid()) return null;

    return startAt;
  }

  /**
   * Check anomaly end time conflicted between predicted "shift" end time vs validated "shift" end time)
   * use case predicted is the same as validated but the badge is different:
   * if scheduled = 08:00 and badged at 07:45 then validated = 08:00 and the option earlyDepartureCountedAsEarly is true => return deviances_early_departure
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:00 and the option lateDepartureCountedAsLate is true => return deviances_late_departure
   * if scheduled = 08:00 and badged at 07:45 then validated = 08:00 and the option earlyDepartureCountedAsEarly is false => return null
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:00 and the option lateDepartureCountedAsLate is false => return null
   * use case early deviance:
   * if scheduled = 08:00 and badged at 07:45 then validated = 07:45 and the option earlyDepartureCountedAsEarly is true => return null
   * if scheduled = 08:00 and badged at 07:45 then validated = 07:45 and the option earlyDepartureCountedAsEarly is false => return deviances_early_departure
   * use case late deviance:
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:15 and the option lateDepartureCountedAsLate is true => return null
   * if scheduled = 08:00 and badged at 08:15 then validated = 08:15 and the option lateDepartureCountedAsLate is false => return deviances_early_departure
   * @returns {string|null} anomaly reason
  */
  getAnomalyEndTimeConflicted() {
    const predictedParsedDate = skDate.utc(this.matchedBadging.predictedEndsAt)
      .seconds(0).milliseconds(0);

    const validatedParsedDate = this.getSelectedEndAtDateUtc(
      predictedParsedDate, this.matchedBadging.selectedEndsAt,
    );

    const badgingOut = skDate.utc(this.matchedBadging.badgingEndsAt).seconds(0).milliseconds(0);

    if (!validatedParsedDate) return null;

    if (validatedParsedDate.isSame(predictedParsedDate)) {
      if (validatedParsedDate.isSame(badgingOut)) {
        return null;
      }

      if (this.matchedBadging.settings.earlyDepartureCountedAsEarly &&
        validatedParsedDate.isBefore(badgingOut)) {
        return 'deviances_early_departure';
      }

      if (this.matchedBadging.settings.lateDepartureCountedAsLate &&
        validatedParsedDate.isAfter(badgingOut)) {
        return 'deviances_late_departure';
      }

      return null;
    }

    if (validatedParsedDate.isBefore(predictedParsedDate)) {
      return this.matchedBadging.settings.earlyDepartureCountedAsEarly ? null : 'deviances_early_departure';
    }

    // isAfter
    return this.matchedBadging.settings.lateDepartureCountedAsLate ? null : 'deviances_late_departure';
  }

  /**
   *
   * @param {skDate} predictedParsedDate - predicted date
   * @param {string} selectedEndsAt - choice end time hh:mm
   * @returns {skDate|null} selected end date utc
   */
  getSelectedEndAtDateUtc(predictedParsedDate, selectedEndsAt) {
    const selectedDate = skDate.utc(`${predictedParsedDate.format('YYYY-MM-DD')}T${selectedEndsAt}`);

    const endAt = this.handleDayDifferences(predictedParsedDate, selectedDate);
    if (!endAt.isValid()) return null;

    return endAt;
  }

  /**
   *
   * @param {skDate} originalDate - provisional date
   * @param {skDate} choiceDate -provisional date with choice time
   * @returns
   */
  handleDayDifferences(originalDate, choiceDate) {
    const hoursDifference = originalDate.diff(choiceDate, 'hours', true);

    // If the difference is more than 12 hours, move to the next day
    if (hoursDifference > HALF_DAY_HOURS) {
      choiceDate.add(1, 'days');
      // If the difference is less than -12 hours, move to the previous day
    } else if (hoursDifference < -HALF_DAY_HOURS) {
      choiceDate.subtract(1, 'days');
    }

    return choiceDate;
  }
}

export default BadgingAnomalyDetector;
