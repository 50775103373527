import { httpClient } from '@skello-utils/clients';

export const ENDPOINT_NAMESPACE = '/v3/api/plannings/shifts';

export const getShiftsParams = shifts => (shifts.map(shift => ({
  id: shift.id,
  starts_at: shift.attributes.startsAt,
  ends_at: shift.attributes.endsAt,
  note: shift.attributes.note,
  poste_id: shift.relationships.poste.id,
  user_id: shift.attributes.userId,
  shop_id: shift.attributes.shopId,
  nb_meal: shift.attributes.nbMeal,
  pause_time: shift.attributes.pauseTime,
  day_absence: shift.attributes.dayAbsence,
  previsional_start: shift.attributes.previsionalStart,
  previsional_end: shift.attributes.previsionalEnd,
  previsional_saved: shift.attributes.previsionalSaved,
  previsional_poste_id: shift.attributes.previsionalPosteId,
  absence_calculation: shift.attributes.absenceCalculation,
  hours_worth: shift.attributes.hoursWorth,
  absence_duration_in_seconds: shift.attributes.absenceDurationInSeconds,
  delay: shift.attributes.delay,
  show_start_time: shift.attributes.showStartTime,
  show_end_time: shift.attributes.showEndTime,
  show_duration: shift.attributes.showDuration,
  provenance: shift.attributes.provenance,
})));

export const createShiftApiRequest = params => {
  const createParams = {
    starts_at: params.periodStartsAt,
    ends_at: params.periodEndsAt,
    shop_id: params.shopId,
    perform_later: !!params.performLater,
    shifts: getShiftsParams(params.shifts),
  };
  return httpClient.post(`${ENDPOINT_NAMESPACE}`, createParams);
};

export const upsertTasksApiRequest = ({ shiftId, tasks }) => httpClient.post(`/v3/api/shifts/${shiftId}/tasks`, { tasks });
