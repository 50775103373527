const initialState = {
  isAnyModalOpen: false,
  isAnySidePanelOpen: false,
};

const mutations = {
  setIsAnyModalOpen(state, isOpen) {
    state.isAnyModalOpen = isOpen;
  },
  setIsAnySidePanelOpen(state, isOpen) {
    state.isAnySidePanelOpen = isOpen;
  },
};

const getters = {
  isAnyOverlayOpen: state => state.isAnyModalOpen || state.isAnySidePanelOpen,
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  getters,
};
