/* eslint-disable func-names */
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

moment.prototype.isMonday = function () { return this.isoWeekday() === 1; };

moment.prototype.isSunday = function () { return this.isoWeekday() === 7; };

moment.prototype.getLastSunday = function () {
  if (this.isValid() && !this.isSunday()) {
    this.subtract(this.isoWeekday(), 'day');
  }

  return this;
};

moment.prototype.isToday = function () {
  return this.startOf('day').isSame(moment().startOf('day'));
};

moment.prototype.dayIndex = function () {
  // -1 on day index as moment returns 1 for monday (which is index 0 in array)
  return this.isoWeekday() - 1;
};

moment.prototype.isBetweenInclusive = function (start, end) {
  return this.isBetween(start, end) || this.isSame(start) || this.isSame(end);
};

export default moment;
