import Vue from 'vue';

import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

const ENDPOINT_NAMESPACE = '/v3/api/reports';

const initialState = {
  saasReportData: {},
  saasReportInfos: {},
  saasReportDataLoading: false,
  exportOptionsLoading: false,
  clusterNodes: [],
  parentNode: {},
  clusterNodesLoading: false,
  updatePeriodLockLoading: false,
  updateWeekLockLoading: false,
  primeActionLoading: false,
  commentActionLoading: false,
  error: null,
};

const mutations = {
  fetchSaasReportDataSuccess(state, payload) {
    // Split HrInfoBuilder employees_infos so that we have saasReportData containing employees
    // & saasReportInfos containing only extra data & permissions
    /* eslint-disable camelcase */
    const { saas_report_infos, ...data } = payload;
    state.saasReportInfos = saas_report_infos;
    /* eslint-enable camelcase */

    state.saasReportData = data;
  },

  updateWeekLockData(state, { isLocked, startDate }) {
    if (state.saasReportInfos.weeks_datas) {
      const startSkDate = skDate(startDate);
      state.saasReportInfos.weeks_datas.forEach(weekDatas => {
        // If period is being locked and period start date does not cover
        // a week's monday -> dont update the week data lock value
        if (isLocked && startSkDate.isAfter(skDate(weekDatas.monday))) return;

        weekDatas.check_week_locks = isLocked;
      });
    }
  },

  updateWeekLockValue(state, { weekIndex, lockValue }) {
    state.saasReportInfos.weeks_datas[weekIndex].check_week_locks = lockValue;
  },

  saasReportDataRequestPending(state) {
    state.saasReportDataLoading = true;
  },
  saasReportDataRequestComplete(state) {
    state.saasReportDataLoading = false;
  },

  updateExportOptionsPending(state) {
    state.exportOptionsLoading = true;
  },
  updateExportOptionsComplete(state) {
    state.exportOptionsLoading = false;
  },

  fetchClusterNodesSuccess(state, payload) {
    state.clusterNodes = payload;
  },

  fetchParentNodeSuccess(state, payload) {
    state.parentNode = payload;
  },

  fetchClusterNodesPending(state) {
    state.clusterNodesLoading = true;
  },
  fetchClusterNodesComplete(state) {
    state.clusterNodesLoading = false;
  },

  updatePeriodLockPending(state) {
    state.updatePeriodLockLoading = true;
  },
  updatePeriodLockComplete(state) {
    state.updatePeriodLockLoading = false;
  },

  updateWeekLockPending(state) {
    state.updateWeekLockLoading = true;
  },
  updateWeekLockComplete(state) {
    state.updateWeekLockLoading = false;
  },

  primeActionPending(state) {
    state.primeActionLoading = true;
  },
  primeActionComplete(state) {
    state.primeActionLoading = false;
  },

  commentActionPending(state) {
    state.commentActionLoading = true;
  },
  commentActionComplete(state) {
    state.commentActionLoading = false;
  },

  updatePrimeAmount(state, { employeeId, contractId, prime }) {
    const saasReportData = { ...state.saasReportData };
    saasReportData[employeeId].contracts_infos[contractId].primes = prime;
    Vue.set(state, 'saasReportData', saasReportData);
  },

  editComment(state, { employeeId, contractId, comment, commentId }) {
    const saasReportData = { ...state.saasReportData };
    saasReportData[employeeId].contracts_infos[contractId].report_comment = {
      comment,
      id: commentId,
    };
    Vue.set(state, 'saasReportData', saasReportData);
  },

  catchError(state, payload) {
    state.error = payload;
  },
};

const actions = {
  fetchSaasReportData({ commit }, params) {
    commit('saasReportDataRequestPending');

    const fetchParams = {
      shop_id: params.shopId,
      start_date: params.startDate,
      end_date: params.endDate,
    };

    return httpClient
      .get(`${ENDPOINT_NAMESPACE}/saas_report`, { params: fetchParams })
      .then(response => {
        commit('fetchSaasReportDataSuccess', response.data);
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('saasReportDataRequestComplete');
      });
  },
  startReportExport({ commit }, params) {
    const data = {
      shop_id: params.shopId,
      start_date: params.startDate,
      end_date: params.endDate,
      users_checked: params.usersChecked,
      stream_id: params.streamId,
    };

    return httpClient
      .post(`${ENDPOINT_NAMESPACE}/excel_report`, data)
      .catch(error => {
        commit('catchError', error);
        throw error;
      });
  },
  // Handles integration globale & custom
  startIntegrationExport({ commit }, params) {
    const integrationType = params.type;
    const data = {
      shop_id: params.shopId,
      start_date: params.startDate,
      end_date: params.endDate,
      users_checked: params.usersChecked,
      stream_id: params.streamId,
      key: params.key,
    };

    return httpClient
      .post(`/v3/api/reports/export_${integrationType}_integration`, data)
      .catch(error => {
        commit('catchError', error);
        throw error;
      });
  },
  // Sends disabled columns in export to V1 controller in this format:
  // { shop: {
  //   "first_sheet_disabled_columns": ["disabledColumnName", ...],
  //   "third_sheet_disabled_columns": ["disabledColumnName", ...],
  //   "fourth_sheet_disabled_columns": ["disabledColumnName", ...],
  //   "legacy_second_sheet_disabled_columns": ["disabledColumnName", ...],
  //   "second_sheet_disabled_columns": ["disabledColumnName", ...]
  // }}
  updateExportOptions({ commit, rootState }, params) {
    commit('updateExportOptionsPending');

    const patchParams = {
      shop: params,
    };

    const shopId = rootState.currentShop.currentShop.id;

    return httpClient
      .patch(`/shops/${shopId}/export_options`, patchParams)
      .finally(() => {
        commit('updateExportOptionsComplete');
      });
  },
  fetchClusterNodes({ state, dispatch, commit, rootState }, params) {
    commit('fetchClusterNodesPending');

    const fetchParams = {
      cluster_node_id: rootState.navContext.navContext.clusterNodeId,
      root_child: false,
      parent_id: rootState.navContext.navContext.clusterNodeId,
      skip_pagination: true,
      intermediate_node: true,
      shop_level: true,
      include_cluster_nodes_infos: true,
      report_nodes: true,
    };

    return httpClient
      .get('/v3/api/cluster_nodes', { params: fetchParams })
      .then(response => {
        commit('fetchClusterNodesSuccess', response.data.data);
        commit('fetchParentNodeSuccess', response.data.meta.parent.data);

        // When selecting a shop node we need to skip the selectClusterNode or selectOrganisation
        if (rootState.currentShop.currentShop.id !== 'all') return;

        if (state.parentNode.attributes.parentId) {
          dispatch(
            'navContext/selectClusterNode',
            {
              clusterNode: state.parentNode,
              childrenAreShopNodes: state.clusterNodes.some(node => (
                node.attributes.ancestry === `${state.parentNode.attributes.ancestry}/${node.attributes.parentId}`
              )),
            },
            { root: true },
          );
        } else {
          dispatch(
            'navContext/selectOrganisation',
            rootState.currentOrganisation.currentOrganisation,
            { root: true },
          );
        }
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('updateExportOptionsComplete');
        commit('fetchClusterNodesComplete');
      });
  },
  updatePeriodLock({ commit }, params) {
    // If isWeekLock is true -> only one week lock is being toggled
    // Otherwise, it's the whole period lock
    const lockType = params.isWeekLock ? 'Week' : 'Period';
    delete params.isWeekLock;
    commit(`update${lockType}LockPending`);

    return httpClient
      .post('/v3/api/reports/toggle_permanent_locked_period', params)
      .catch(() => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit(`update${lockType}LockComplete`);
      });
  },
  addPrime({ commit }, params) {
    commit('primeActionPending');

    return httpClient
      .post('/primes/', params)
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('primeActionComplete');
      });
  },
  deletePrime({ commit }, params) {
    commit('primeActionPending');

    return httpClient
      .delete('/primes/bulk_delete/', { params })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('primeActionComplete');
      });
  },
  createComment({ commit }, params) {
    commit('commentActionPending');

    return httpClient
      .post('/report_comments/', params)
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('commentActionComplete');
      });
  },
  updateComment({ commit }, params) {
    commit('commentActionPending');

    const commentId = params.commentId;
    delete params.commentId;

    return httpClient
      .put(`/report_comments/${commentId}`, params)
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('commentActionComplete');
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
