import cloneDeep from 'lodash/cloneDeep';
import Vue from 'vue';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-client';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import { filterObjectFields } from '@skello-utils/form';
import store from '@app-js/shared/store/index';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { checkFeatureFlag } from '@skello-utils/feature-flags';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import { FEATURES } from '@app-js/shared/constants/features.js';

// Fields to exclude from sticky bar & window confirm
const SHOP_FIELDS_TO_EXCLUDE = [
  'isAnnualizationV2Active',
  'countersInitializationDoneAt',
  'dayBadgingActivated',
  'mealCompensationActivated',
  'holidaysCounterConfigured',
  'mealCompensationDone',
  'modulation',
  'modulationMajoration',
  'modulationStatus',
  'plcInitializationDoneAt',
  'plcInitialized',
  'plcStatus',
  'pauseCompensationStartsAt',
  'holidaysCounterActivationYear',
];

const isCanaryReleaseActive = (devFlag, shopId) => {
  const { canaryReleaseType, canaryReleaseIds } = devFlag;

  // If array empty, active for everyone
  if (!canaryReleaseIds || canaryReleaseIds.length === 0) return true;

  return canaryReleaseIds.includes((() => {
    switch (canaryReleaseType) {
      case 'shop':
        return shopId.toString();
      case 'organisation':
        return store.state.currentOrganisation.currentOrganisation.id.toString();
      case 'user':
        return store.state.currentUser.currentUser.id.toString();
      default:
        console.error(`Canary release type unknown: '${canaryReleaseType}'`);
        return undefined;
    }
  })());
};

const changeShopConvention = (state, convention) => {
  state.currentShop.relationships.convention.attributes.name = convention.name;
  state.currentShop.relationships.convention.attributes.legibleName = convention.legibleName;
  state.currentShop.relationships.convention.attributes.baseSector = convention.baseSector;
};

const formatShopHoursChangedEvent = state => {
  const oldOpeningTime = state.originalCurrentShopData.attributes.openingTime;
  const oldClosingTime = state.originalCurrentShopData.attributes.closingTime;
  const newOpeningTime = state.currentShop.attributes.openingTime;
  const newClosingTime = state.currentShop.attributes.closingTime;

  if (oldOpeningTime === newOpeningTime && oldClosingTime === newClosingTime) return;

  // eslint-disable-next-line consistent-return
  return {
    previousHours: [oldOpeningTime, oldClosingTime],
    newHours: [newOpeningTime, newClosingTime],
  };
};

const initialState = {
  currentShop: {
    id: 'all',
    type: 'shop',
    attributes: {},
    relationships: null,
  },
  originalCurrentShopData: {
    id: 'all',
    type: 'shop',
    attributes: {},
    relationships: null,
  },
  originalConventionName: '',
  error: false,
  loading: false,
  loadingUpdate: false,
  isPlcFieldExcluded: false,
  currentUserShops: {},
};

const baseRulesParams = state => {
  const shop = state.currentShop.attributes;
  const params = {
    modulation: shop.modulation,
    opening_time: shop.openingTime,
    closing_time: shop.closingTime,
    modulation_majoration: shop.modulationMajoration,
    plc_start_month: shop.plcStartMonth,
    paid_vacation_calculation_type: shop.paidVacationCalculationType,
    paid_vacation_acquisition_number: shop.paidVacationAcquisitionNumber,
    max_hours_without_pause: shop.maxHoursWithoutPause,
    modulation_status: shop.modulationStatus,
    plc_status: shop.plcStatus,
    pause_compensation_starts_at: shop.pauseCompensationStartsAt,
    meal_compensation_activated: shop.mealCompensationActivated,
    legal_weekly_hours: shop.legalWeeklyHours,
    holidays_counter_activation_year: shop.holidaysCounterActivationYear,
    hourly_wage_rate: shop.hourlyWageRate,
  };

  return params;
};

const billingInfoParams = state => {
  const shop = state.currentShop.attributes;
  const params = {
    name: shop.name,
    pay_identification_number: shop.payIdentificationNumber,
    denomination_sociale: shop.denominationSociale,
    address: shop.address,
    zipcode: shop.zipcode,
    city: shop.city,
    country: shop.country,
    country_region: shop.countryRegion,
    timezone: shop.timezone,
    siret: shop.siret,
    tva_number: shop.tvaNumber,
  };

  return params;
};

const shopExtendedInfoParams = state => {
  const shop = state.currentShop.attributes;

  return {
    naf_code: shop.nafCode,
    sst_code: shop.sstCode,
    urssaf_code: shop.urssafCode,
  };
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  setShopAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.currentShop.attributes[attribute] = payload[attribute];
    });
  },

  setShopConvention(state, convention) {
    changeShopConvention(state, convention);
  },

  squashOriginalCurrentShopData(state) {
    state.originalCurrentShopData = cloneDeep(state.currentShop);
  },

  squashCurrentShop(state) {
    state.currentShop = cloneDeep(state.originalCurrentShopData);
  },

  resetError(state) {
    state.error = false;
  },

  resetShopOpeningHours(state) {
    state.currentShop.attributes.openingTime = state.originalCurrentShopData.attributes.openingTime;
    state.currentShop.attributes.closingTime = state.originalCurrentShopData.attributes.closingTime;
  },

  resetLegalWeeklyHours(state) {
    state.currentShop.attributes.legalWeeklyHours =
      state.originalCurrentShopData.attributes.legalWeeklyHours;
  },

  resetConvention(state) {
    changeShopConvention(state, state.originalCurrentShopData.relationships.convention.attributes);
  },

  currentShopSuccess(state, payload) {
    state.currentShop = payload.data;
    if (state.currentShop.id === 'all') {
      return;
    }

    if (payload.included) {
      const convention = associationMatcherItem(state.currentShop, payload.included, {
        key: 'convention',
        type: 'convention',
      });
      const clusterNode = associationMatcherItem(state.currentShop, payload.included, {
        key: 'clusterNode',
        type: 'clusterNode',
      });
      Object.assign(state.currentShop.relationships, { convention, clusterNode });

      if (state.currentShop.relationships.convention.attributes) {
        state.originalConventionName = state.currentShop.relationships.convention.attributes.name;
      }
    }

    state.originalCurrentShopData = cloneDeep(state.currentShop);
  },

  currentShopError(state, error) {
    state.error = error;
  },

  updateOnboardingStatus(state, payload) {
    state.currentShop.attributes.onboarding_status = {
      ...state.currentShop.attributes.onboarding_status,
      ...payload,
    };
  },
  setCurrentUserShops(state, payload) {
    state.currentUserShops = payload.reduce((result, shop) => {
      result[shop.id] = { id: shop.id, ...shop.attributes };
      return result;
    }, {});
  },
};

const actions = {
  updateCurrentShop({ commit }, { shopId }) {
    commit('performingRequest');
    if (shopId !== 'all') {
      const params = {
        include_shops_where_employee: store.getters['currentLicense/isEmployee'],
      };

      return httpClient
        .get(`/v3/api/shops/${shopId}/show_extended`, { params })
        .then(response => {
          commit('currentShopSuccess', response.data);

          return response;
        })
        .catch(error => {
          commit('currentShopError', error.response.data);
          throw error;
        })
        .finally(() => {
          commit('requestComplete');
        });
    }

    // Unselect current shop
    return new Promise((resolve, reject) => {
      commit('currentShopSuccess', {
        data: {
          id: 'all',
          type: 'shop',
          attributes: {},
          relationships: {},
        },
      });
      commit('requestComplete');
      resolve();
    });
  },
  updateShop({ state, commit }, { shopId, overrideParams }) {
    const defaultParams = {
      shop: baseRulesParams(state),
      shop_info: billingInfoParams(state),
      shop_extended_info: shopExtendedInfoParams(state),
      convention: { name: state.currentShop.relationships.convention.attributes.name },
      onboarding_status: state.currentShop.attributes.onboarding_status,
    };

    const params = overrideParams || defaultParams;

    commit('performingRequest', 'loadingUpdate');
    return httpClient
      .patch(`/v3/api/shops/${shopId}`, params)
      .then(response => {
        try {
          Vue.prototype.$svcEvents.create(
            EVENT_SUBTYPE_ACTION.SHOP_HOURS_CHANGED,
            formatShopHoursChangedEvent(state),
          );
        } catch (error) {
          console.error(error);
        }

        commit('currentShopSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('currentShopError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'loadingUpdate');
      });
  },
  fetchFeaturePrices({ state, commit }, params) {
    const featurePriceParams = {
      shop_ids: params.shopIds,
      feature_name: params.featureName,
    };

    return httpClient
      .get('/v3/api/upsells/feature_price', { params: featurePriceParams })
      .then(response => response)
      .catch(error => {
        throw error;
      });
  },
  fetchShops({ commit }, allOrgaShops = false) {
    commit('performingRequest');

    return httpClient
      .get('/v3/api/shops', { params: { include_shops_where_employee: true, skip_pagination: true, all_orga_shops: allOrgaShops } })
      .then(response => {
        commit('setCurrentUserShops', response.data.data);
      })
      .catch(error => {
        throw error;
      }).finally(() => {
        commit('requestComplete');
      });
  },
};

const getters = {
  name: state => state.currentShop.attributes.name,
  isLoaded: state => state.currentShop.id && state.loading === false,
  isMealCompensationDone: state => state.currentShop.attributes.mealCompensationDone &&
    store.getters['features/isFeatureEnabled'](FEATURES.FEATURE_MEALS, state.currentShop.id, () => true),
  modulationActivated: state => state.currentShop.attributes.modulation,
  sector: state => state.currentShop.relationships?.convention?.attributes?.sector || 'general',
  isHospitalitySector: (state, selfGetters) => selfGetters.sector === 'hospitality',
  defaultHourlyWageRate: () => 43.0,
  unsavedChangesToCurrentShop(state, selfGetters) {
    if (selfGetters.checkFeatureFlag('FEATURE_N1_N_PTO_TRACKER') && !state.isPlcFieldExcluded) {
      SHOP_FIELDS_TO_EXCLUDE.push('plcStartMonth');
      state.isPlcFieldExcluded = true;
    }
    const currentLocalShop =
      filterObjectFields(state.currentShop.attributes, SHOP_FIELDS_TO_EXCLUDE);
    const originalCurrentShop =
      filterObjectFields(state.originalCurrentShopData.attributes, SHOP_FIELDS_TO_EXCLUDE);

    return (
      JSON.stringify(currentLocalShop) !== JSON.stringify(originalCurrentShop) ||
      state.originalConventionName !== state.currentShop.relationships.convention.attributes.name
    );
  },
  changesMadeToShopOpeningHours({ currentShop, originalCurrentShopData }) {
    return currentShop.attributes.openingTime !==
      originalCurrentShopData.attributes.openingTime ||
      currentShop.attributes.closingTime !==
      originalCurrentShopData.attributes.closingTime;
  },
  changesMadeToLegalWeeklyHours({ currentShop, originalCurrentShopData }) {
    return currentShop.attributes.legalWeeklyHours !==
      originalCurrentShopData.attributes.legalWeeklyHours;
  },
  switchingToNonHealthConvention({ currentShop, originalCurrentShopData }) {
    return originalCurrentShopData.relationships.convention.attributes.baseSector === 'health' &&
      currentShop.relationships.convention.attributes.baseSector !== 'health';
  },
  showStickyBar(state, selfGetters) {
    return selfGetters.unsavedChangesToCurrentShop &&
      store.state.currentLicense.currentLicense.attributes.canEditShopRulesAndAbsences;
  },
  isVariableContractHoursAvailable(state, selfGetters) {
    const isSvcFeatureFlagActivated =
      JSON.parse(document.querySelector('[name=FEATURE_SVC_FEATURE_FLAG]').content);
    if (isSvcFeatureFlagActivated) {
      return selfGetters.checkFeatureFlag('FEATURE_VARIABLE_CONTRACT_HOURS_ENABLED') &&
      store.getters['features/isFeatureEnabled'](FEATURES.FEATURE_EMPLOYEE_TEAMS_ROTATIONS,
        state.currentShop.id,
        () => true,
      );
    }
    const baseSector =
      state.currentShop.relationships?.convention?.attributes?.baseSector || 'general';
    return (baseSector === 'health' || state.currentShop.id === 'all') &&
    store.getters['features/isFeatureEnabled'](FEATURES.FEATURE_EMPLOYEE_TEAMS_ROTATIONS,
      state.currentShop.id,
      () => store.getters['currentOrganisation/checkPackOfferFlag']('variable_contract_hours_enabled'),
    );
  },
  isRollingPostMvpAvailable(_state, selfGetters) {
    return selfGetters.isVariableContractHoursAvailable &&
      selfGetters.isDevFlagEnabled('FEATURE_VARIABLE_CONTRACT_HOURS_POST_MVP_ENABLED');
  },
  isShopOnPaidVacationCalculationTypeOpeningDay(state) {
    const { config } = store.state.config;
    const openingDay = config.absence_data.opening_day_hours_key;

    return config.paid_vacation_calculation_types[openingDay] ===
      state.currentShop.attributes.paidVacationCalculationType;
  },
  isShopOnPaidVacationCalculationTypeCalendarDay(state) {
    const { config } = store.state.config;
    const calendarDay = config.absence_data.calendar_day_hours_key;

    return config.paid_vacation_calculation_types[calendarDay] ===
      state.currentShop.attributes.paidVacationCalculationType;
  },
  isShopLinkedToPosPartner(state, selfGetters) {
    const { lightspeedShopId, tillerShopId, zeltyShopId } = state.currentShop.attributes;

    if (lightspeedShopId || tillerShopId || zeltyShopId) return true;

    const { id: shopId } = store.state.currentShop.currentShop;

    const { ladditionMatchingShopsList } = store.state.partnersTools;

    if (ladditionMatchingShopsList[shopId] !== undefined) return true;

    const { integrations } = store.state.partnersTools;

    const isShopIntegrated = Object.values(integrations)
      .some(integration => integration.registeredIntegrations
        .some(registeredIntegration => registeredIntegration.shopId === shopId));

    if (isShopIntegrated) return true;

    return false;
  },
  is24hShop: state => (
    state.currentShop.attributes.openingTime === state.currentShop.attributes.closingTime
  ),
  currentShopOpeningAndClosingTime: state => {
    const currentDate = store.getters['planningsState/currentDate'];

    return openingAndClosingTimeAt(
      state.currentShop.attributes.openingTime,
      state.currentShop.attributes.closingTime,
      skDate.utc(currentDate).format(),
    );
  },
  isAnnualizedWorkingTimeAvailable: (state, selfGetters) => (
    { shop = state.currentShop } = {},
  ) => (
    store.getters['currentOrganisation/checkPackOfferFlag']('annualized_working_time_v2_enabled') &&
      selfGetters.checkFeatureFlag('FEATURE_ANNUALIZED_WORKING_TIME_V2_ENABLED', shop)
  ),
  isAnnualizationContractHoursChangeEnabled: (_state, selfGetters) => (
    selfGetters.isDevFlagEnabled('FEATUREDEV_ANNUALIZATION_CONTRACT_HOURS_CHANGE')
  ),
  isRttCounterAvailable: (_state, selfGetters) => (
    store.getters['currentOrganisation/checkPackOfferFlag']('rtt_counter_enabled') &&
    selfGetters.checkFeatureFlag('FEATURE_RTT_TRACKER')
  ),
  checkFeatureFlag: state => (flagName, shop = state.currentShop) => checkFeatureFlag({
    flagName,
    shop,
    convention: shop.relationships?.convention,
  }),
  isDevFlagEnabled: state => (flagName, shopId = state.currentShop.id) => {
    // if (!JSON.parse(document.querySelector('[name=FEATURE_SVC_FEATURE_FLAG]').content)) {
    //   return (JSON.parse(document.querySelector(`[name=${flagName}]`).content) === true);
    // }
    const devFlags = store.state.config.featureFlags.feature_flags_dev;

    const devFlag = devFlags.find(currentDevFlag => currentDevFlag.name === flagName);

    if (!devFlag || !devFlag.active) {
      return false;
    }

    if (!devFlag.canaryReleaseType) {
      return true;
    }

    return isCanaryReleaseActive(devFlag, shopId);
  },
  isClockRulesVisible: (state, selfGetters, _rootState, rootGetters) => (
    store.getters['currentLicense/canEditPunchClockSettings'] &&
      rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_TIMECLOCK, state.currentShop.id, () => state.currentShop.attributes.hasPunchClock)
  ),
  isFeatureEnabledOnShop(state, selfGetters) {
    const shop = state.currentShop;

    const isFeatureEnabled = (featureName, shopId) => store.getters['features/isFeatureEnabled'](featureName, shopId, () => {
      const featuresStates = store.getters['selectedEmployee/principalShop']?.attributes?.featuresStates;

      return featuresStates &&
        featuresStates[featureName] !== undefined &&
        featuresStates[featureName].activated;
    });

    if (shop.id === 'all') {
      return featureName => {
        const payrollFeatures = ['multicontract', 'multidocument'];

        if (payrollFeatures.includes(featureName)) {
          const userPrimaryShopId = store.getters['selectedEmployee/primaryShopNode']?.attributes.shopId;

          return selfGetters.isDevFlagEnabled('FEATUREDEV_PAYROLL_OPTION_ENABLED') && isFeatureEnabled(featureName, userPrimaryShopId);
        }

        return false;
      };
    }

    return featureName => selfGetters.isDevFlagEnabled('FEATUREDEV_PAYROLL_OPTION_ENABLED') && isFeatureEnabled(featureName, shop.id);
  },
  hasMealRule(state) {
    return state.currentShop.attributes.mealCompensationDone &&
      state.currentShop.attributes.isMealRuleBenefitInKind;
  },
  isShopHoursCounterActivated: state => (shopId = state.currentShop.id) => (
    Object.values(state.currentUserShops)
      .find(currentUserShop => Number(currentUserShop.id) === shopId)?.modulation
  ),
  shouldShowAdminOnboarding(state, selfGetters, rootState, rootGetters) {
    return rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_PLANNING, state.currentShop.id, () => true) &&
      rootState.currentUser.currentUser.id.length > 0 && (
      // # user related
      (!rootState.currentUser.currentUser.attributes.isOnboardingEmployeeFilled) ||
      // # shop related
      (
        selfGetters.isCurrentShopSelected &&
        state.currentShop.attributes.onboardingStatus && (
          (
            !state.currentShop.attributes.onboardingStatus.positions_filled &&
            state.currentShop.attributes.postesCount === 0
          ) ||
          (
            !state.currentShop.attributes.onboardingStatus.employees_filled &&
            !state.currentShop.attributes.hasPlanningUsers
          )
        )
      )
    );
  },
  isCurrentShopSelected: state => state.currentShop.id !== undefined && state.currentShop.id !== 'all',
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
