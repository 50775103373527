import fr from './profile.fr.yml';
import en from './profile.en.yml';
import es from './profile.es.yml';
import it from './profile.it.yml';
import de from './profile.de.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...it,
  ...de,
};
