export const MEAL_RULES_DEFAULT = {
  hospitality: {
    calculation_method: 'benefit_in_kind',
    owed_meals_calculation_method: 'days_worked',
  },

  retail: {
    calculation_method: 'meal_voucher',
    owed_meals_calculation_method: 'days_worked',
  },

  health: {
    calculation_method: 'meal_voucher',
    owed_meals_calculation_method: 'days_worked',
  },

  industry_and_services: {
    calculation_method: 'meal_voucher',
    owed_meals_calculation_method: 'days_worked',
  },

  general: {
    calculation_method: 'meal_voucher',
    owed_meals_calculation_method: 'days_worked',
  },
};
