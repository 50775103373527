import { fullName } from '../../../utils';
import { formatEvent } from '../../../format_event';

const formatShopPosteUpdateNameContext = (store, { id, oldName, newName }) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    poste_id: id,
    poste_name: [oldName, newName],
  },
});

const formatShopPosteUpdateColorContext = (store, { id, name, oldColor, newColor }) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    poste_id: id,
    poste_name: name,
    poste_color: [oldColor, newColor],
  },
});
const formatShopPosteUpdatePauseTimeContext = (
  store,
  { id, name, oldPauseTime, newPauseTime }) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    poste_id: id,
    poste_name: name,
    poste_pause_time: [oldPauseTime, newPauseTime],
  },
});

export const formatShopPosteUpdateName =
  async (store, data) => [formatShopPosteUpdateNameContext(store, data)];

export const formatShopPosteUpdateColor =
  async (store, data) => [formatShopPosteUpdateColorContext(store, data)];

export const formatShopPosteUpdatePauseTime =
  async (store, data) => [formatShopPosteUpdatePauseTimeContext(store, data)];
