import pick from 'lodash/pick';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';

// Paid leaves are computed from june to may.
// Default query must be made on previous year if current date is before june (backend necessity)
const defaultDate = () => skDate().format('DD/MM/YYYY');

const initialState = {
  years: [],
  plcStartMonth: 'june',
  error: null,
  plcLoading: false,
  periodSummariesLoading: false,
  periodSummaries: {},
};

const mutations = {
  performingPlcRequest(state) {
    state.plcLoading = true;
  },

  plcRequestComplete(state) {
    state.plcLoading = false;
  },

  performingPeriodSummariesRequest(state) {
    state.periodSummariesLoading = true;
  },

  periodSummariesRequestComplete(state) {
    state.periodSummariesLoading = false;
  },

  employeePaidLeavesCounterSuccess(state, payload) {
    state.years = payload.structure;
    state.plcStartMonth = payload.shop.plc_start_month;
  },

  employeePaidLeavesCounterError(state, error) {
    state.error = error;
  },

  employeePeriodSummariesSuccess(state, payload) {
    state.periodSummaries = payload;
  },

  employeePeriodSummariesError(state, error) {
    state.error = error;
  },

  resetState(state) {
    Object.assign(state, initialState);
  },
};

const actions = {
  fetchEmployeePeriodSummaries({ state, commit }, params) {
    commit('performingPeriodSummariesRequest');

    const sanitizedParams = pick(params, ['user_id', 'date']);
    if (!sanitizedParams.date) sanitizedParams.date = defaultDate();

    return httpClient
      .get('/v3/api/paid_leaves_counters/plc_periods_summary', { params: sanitizedParams })
      .then(response => {
        commit('employeePeriodSummariesSuccess', response.data);
      })
      .catch(error => {
        commit('employeePeriodSummariesError', error);
      }).finally(() => {
        commit('periodSummariesRequestComplete');
      });
  },

  fetchEmployeePaidLeavesCounter({ state, commit }, params) {
    if (params.silentLoading === false) commit('performingPlcRequest');

    const sanitizedParams = pick(params, ['user_id', 'date']);
    if (!sanitizedParams.date) sanitizedParams.date = defaultDate();

    return new Promise((resolve, reject) => {
      httpClient
        .get('/paid_leaves_counters', { params: sanitizedParams })
        .then(response => {
          commit('employeePaidLeavesCounterSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('employeePaidLeavesCounterError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('plcRequestComplete');
        });
    });
  },

  updateEmployeePaidLeavesCounter({ state, commit }, params) {
    const sanitizedParams = {
      user_id: params.user_id,
      displayed_date: params.date,
      paid_leaves_counter: {
        manual_changes: params.manual_changes,
      },
    };

    return new Promise((resolve, reject) => {
      if (!params.silentLoading) commit('performingPlcRequest');

      httpClient
        .patch('/paid_leaves_counters', sanitizedParams)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit('employeePaidLeavesCounterError', error);
          reject(error);
        })
        .finally(() => {
          commit('plcRequestComplete');
        });
    });
  },

  initEmployeePaidLeavesCounter({ state, commit }, params) {
    // Backoffice needs number as string
    params.user_initial_plc = String(params.user_initial_plc);

    const sanitizedParams =
        pick(params, ['shop_id',
          'start_date',
          'plc_sign',
          'user_initial_plc']);

    return new Promise((resolve, reject) => {
      httpClient
        .patch(`/v3/api/users/${params.user_id}/initial_paid_leaves_counters`, sanitizedParams)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          commit('employeePaidLeavesCounterError', error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  // export state as new object to ease state reset
  state: { ...initialState },
  mutations,
  actions,
};
