import COMMON from './common';
import FR from './fr.js';
import ES from './es.js';
import DE from './de.js';
import GB from './gb.js';
import BE from './be.js';
import CH from './ch.js';

export default {
  COMMON,
  FR,
  ES,
  DE,
  GB,
  BE,
  CH,
};
