import fr from './plannings.fr.yml';
import en from './plannings.en.yml';
import es from './plannings.es.yml';
import de from './plannings.de.yml';
import it from './plannings.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
