import { httpClient } from '@skello-utils/clients';
import _ from 'lodash';
import orderBy from 'lodash/orderBy';

const initialState = {
  alerts: [],
  error: null,
  loading: false,
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  fetchAlertsSuccess(state, payload) {
    state.alerts = payload.data;
  },

  updateAlertSuccess(state, { payload }) {
    state.alert = payload.data;
  },

  catchAlertsError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchAlerts({ commit }, shopId) {
    commit('performingRequest');

    return httpClient
      .get(`/v3/api/shops/${shopId}/alerts`)
      .then(response => {
        commit('fetchAlertsSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchAlertsError', error.response);
        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },

  updateAlert({ commit }, { shopId, alert }) {
    const params = {
      alert: {
        blocking: alert.attributes.blocking,
        active: alert.attributes.active,
      },
    };

    return httpClient
      .patch(`/v3/api/shops/${shopId}/alerts/${alert.id}`, params)
      .then(response => {
        commit('updateAlertSuccess', { payload: response.data });
        return response;
      })
      .catch(({ error }) => {
        commit('catchAlertError', error);
        throw error;
      });
  },
};

const getters = {
  // blockable first, then sort by title
  sortedAlerts: state => orderBy(state.alerts, ['attributes.blockable', 'attributes.title'], ['desc', 'asc']),
  sortedEditableAlerts: state => _(state.alerts)
    .filter(alert => alert.attributes.editable)
    .orderBy(['attributes.blockable', 'attributes.title'], ['desc', 'asc'])
    .value(),
  sortedNonEditableAlerts: state => _(state.alerts)
    .filter(alert => !alert.attributes.editable)
    .orderBy(['attributes.blockable', 'attributes.title'], ['desc', 'asc'])
    .value(),
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
