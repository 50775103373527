import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatShopHoursChangedContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    date: new Date().toISOString(),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    previous_hours: data.previousHours,
    new_hours: data.newHours,
  },
});

export const formatShopHoursChanged =
  async (store, data) => [formatShopHoursChangedContext(store, data)];
