import { httpClient } from '@skello-utils/clients';
import { associationMatcherItem } from '@skello-utils/association_matchers';

const initialState = {
  employees: [],
  error: null,
  fetchLoading: false,
  updateLoading: false,
  // data for annualization
  annualizedUserIds: [],
  userAnnualizationConfigs: {},
};

const mutations = {
  performingRequest(state, key) {
    state[key] = true;
  },
  requestComplete(state, key) {
    state[key] = false;
  },
  fetchEmployeesSuccess(state, payload) {
    payload.data.forEach(employee => {
      const contract = associationMatcherItem(employee, payload.included, {
        key: 'contract',
        type: 'contract',
      });

      Object.assign(employee.relationships, { contract });
    });

    state.employees = payload.data;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setAnnualizedUserIds(state, userIds) {
    state.annualizedUserIds = userIds;
  },
  setUserAnnualizationConfigs(state, config) {
    state.userAnnualizationConfigs = config;
  },
};

const actions = {
  fetchEmployees({ commit }, { shopId }) {
    commit('performingRequest', 'fetchLoading');

    return httpClient
      .get(`/v3/api/users/shop_employees?shop_id=${shopId}`)
      .then(response => {
        commit('fetchEmployeesSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('setError', error.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'fetchLoading');
      });
  },
};

const gettersList = {
  annualizableEmployees: state => (
    state.employees.filter(({ attributes }) => attributes.annualizable)
  ),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersList,
};
