import { fullName } from '../../../utils';
import { formatEvent } from '../../../format_event';

const formatShopTeamUpdateNameContext = (store, { id, oldName, newName }) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    team_id: id,
    team_name: [oldName, newName],
  },
});

const formatShopTeamUpdateEmployeesContext = (store, { id, name, oldEmployees, newEmployees }) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    team_id: id,
    team_name: name,
    employees: [oldEmployees, newEmployees],
  },
});

export const formatShopTeamUpdateName =
  async (store, data) => [formatShopTeamUpdateNameContext(store, data)];

export const formatShopTeamUpdateEmployees =
  async (store, data) => [formatShopTeamUpdateEmployeesContext(store, data)];
