import difference from 'lodash/difference';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import skDate from '@skello-utils/dates';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import { httpClient } from '@skello-utils/clients';

const initialState = {
  textDocumentTemplates: [],
  selectedTextDocumentTemplate: null,
  selectedTextDocument: null,
  selectedEsignatureDocument: null,
  selectedVariablesValues: null,
  error: null,
  loading: false,
  loadingFetch: false,
  loadingNewTextDocument: false,
  loadingTextDocumentVariables: false,
  loadingCreate: false,
  loadingSendEmail: false,
};

const matchReferenceCreator = (object, included) => {
  const creator = associationMatcherItem(object, included, { key: 'creator', type: 'user' });
  Object.assign(object.relationships, { creator });
  return object;
};

const BODY_VARIABLES_DATE_KEYS = [
  'EMPLOYEE_BIRTHDAY',
  'CONTRACT_START_DATE',
  'CONTRACT_END_DATE',
  'CONTRACT_TRIAL_END_DATE',
  'TODAY_DATE',
];
const formatVariablesValues = bodyVariablesValues => {
  BODY_VARIABLES_DATE_KEYS.forEach(key => {
    const value = bodyVariablesValues[key];
    if (value) {
      // There is no datetime available in bodyVariablesValues
      // utc() is mandatory for 'CONTRACT_START_DATE' as it can change the day
      // due to utc, when it is saved without it and hour is available through
      // CONTRACT_START_HOUR
      bodyVariablesValues[key] = skDate.utc(value).format('L');
    }
  });

  return bodyVariablesValues;
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  fetchTextDocumentTemplatesSuccess(state, payload) {
    const textDocumentTemplatesWithUsers = payload.data;
    textDocumentTemplatesWithUsers.forEach(tdt => matchReferenceCreator(tdt, payload.included));
    state.textDocumentTemplates = sortBy(textDocumentTemplatesWithUsers, 'attributes.updatedAt').reverse();
  },

  fetchTextDocumentTemplateVariablesValuesSuccess(state, payload) {
    state.selectedVariablesValues = formatVariablesValues(payload.data);
  },

  createTextDocumentTemplateSuccess(state, payload) {
    const textDocumentTemplate = matchReferenceCreator(payload.data, payload.included);
    state.textDocumentTemplates.splice(0, 0, textDocumentTemplate);
    state.selectedTextDocumentTemplate = textDocumentTemplate;
  },

  updateTextDocumentTemplateSuccess(state, { payload, textDocumentTemplateId }) {
    const tdtWithCreator = matchReferenceCreator(payload.data, payload.included);
    const updatedIndex = state.textDocumentTemplates.findIndex(
      tdt => tdt.id === textDocumentTemplateId,
    );
    state.textDocumentTemplates.splice(updatedIndex, 1, tdtWithCreator);
  },

  deleteTextDocumentTemplatesSuccess(state, textDocumentTemplateId) {
    const deletedIndex = state.textDocumentTemplates.findIndex(
      tdt => tdt.id === textDocumentTemplateId,
    );
    state.textDocumentTemplates.splice(deletedIndex, 1);
  },

  newTextDocumentTemplate(state) {
    state.selectedTextDocumentTemplate = {
      attributes: {
        title: null,
        body: null,
      },
    };
  },

  selectTextDocumentTemplate(state, textDocumentTemplateId) {
    const selectedTextDocumentTemplate = state.textDocumentTemplates.find(
      tdt => tdt.id === textDocumentTemplateId,
    );
    state.selectedTextDocumentTemplate = selectedTextDocumentTemplate;
  },

  selectTextDocument(state, textDocument) {
    state.selectedTextDocument = textDocument;
  },

  createTextDocumentSuccess(state, { folder, payload }) {
    const textDocument = payload.data;

    const user = associationMatcherItem(textDocument, payload.included, { key: 'user', type: 'user' });
    const document = associationMatcherItem(textDocument, payload.included, { key: 'document', type: 'document' });
    Object.assign(document.relationships, { user });
    Object.assign(textDocument.relationships, { user, document });
    textDocument.attributes.employeeId = user.id;
    textDocument.attributes.folder = folder;

    state.selectedTextDocument = textDocument;
    state.selectedEsignatureDocument = document;
  },

  catchTextDocumentTemplatesError(state, error) {
    state.error = error;
  },

  selectEsignatureDocument(state, esignatureDocument) {
    state.selectedEsignatureDocument = esignatureDocument;
  },
};

const actions = {
  fetchTextDocumentTemplates({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingFetch');

      httpClient
        .get('/v3/api/text_document_templates', { params })
        .then(response => {
          commit('fetchTextDocumentTemplatesSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchTextDocumentTemplatesError', error);
          reject(error);
        })
        .finally(() => { commit('requestComplete', 'loadingFetch'); });
    });
  },

  fetchTextDocumentTemplateVariablesValues({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingTextDocumentVariables');

      httpClient
        .get(
          `/v3/api/text_document_templates/${params.text_document_template_id}/variables_values`,
          { params },
        )
        .then(response => {
          commit('fetchTextDocumentTemplateVariablesValuesSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchTextDocumentTemplatesError', error);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete', 'loadingTextDocumentVariables');
        });
    });
  },

  createTextDocumentTemplate({ commit }, params) {
    commit('performingRequest', 'loadingCreate');

    return httpClient
      .post('/v3/api/text_document_templates', {
        text_document_template: params,
      })
      .then(response => {
        commit('createTextDocumentTemplateSuccess', response.data, params.folder);
        return response;
      })
      .catch(error => {
        commit('catchTextDocumentTemplatesError', error);
        throw error;
      })
      .finally(() => { commit('requestComplete', 'loadingCreate'); });
  },

  updateTextDocumentTemplate({ commit }, params) {
    return httpClient
      .patch(`/v3/api/text_document_templates/${params.id}`, {
        text_document_template: params,
      })
      .then(response => {
        commit('updateTextDocumentTemplateSuccess', {
          payload: response.data,
          textDocumentTemplateId: params.id,
        });
      })
      .catch(error => {
        commit('catchTextDocumentTemplatesError', error);
        throw error;
      });
  },

  deleteTextDocumentTemplate({ commit }, textDocumentTemplateId) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(
          `/v3/api/text_document_templates/${textDocumentTemplateId}`,
        )
        .then(response => {
          commit('deleteTextDocumentTemplatesSuccess', textDocumentTemplateId);
          resolve(response);
        })
        .catch(error => {
          commit('catchTextDocumentTemplatesError', error);
          reject(error);
        });
    });
  },

  newTextDocument(
    {
      rootGetters, dispatch, state, commit,
    },
    { textDocumentTemplateId, employeeId, folder = '' },
  ) {
    commit('performingRequest', 'loadingNewTextDocument');
    commit('selectTextDocumentTemplate', textDocumentTemplateId);
    commit('selectTextDocument', {
      attributes: {
        title: `${state.selectedTextDocumentTemplate.attributes.title} - ${
          rootGetters['selectedEmployee/fullName']
        }`,
        body: state.selectedTextDocumentTemplate.attributes.body,
        textDocumentTemplateId,
        employeeId,
        employeeFullName: `${ rootGetters['selectedEmployee/fullName'] }`,
        folder,
      },
    });
    commit('requestComplete', 'loadingNewTextDocument');
  },

  createTextDocument({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingCreate');

      httpClient
        .post(`/v3/api/users/${params.text_document.employee_id}/text_documents`, params)
        .then(response => {
          commit('createTextDocumentSuccess', {
            folder: params.text_document.folder,
            payload: response.data,
          });
          resolve(response);
        })
        .catch(error => {
          commit('catchTextDocumentTemplatesError', error);
          reject(error);
        })
        .finally(() => { commit('requestComplete', 'loadingCreate'); });
    });
  },

  selectEsignatureDocument({ commit }, esignatureDocument) {
    commit('selectEsignatureDocument', esignatureDocument);
  },
};

const getters = {
  creators: state => uniqBy(state.textDocumentTemplates
    .map(tdt => tdt.relationships.creator)
    .filter(creator => !!creator), // to clean null values because creator is an optional field
  'id'),
  hasTextDocumentTemplates: state => state.textDocumentTemplates.length !== 0,
  textDocumentTemplateEnabled: state => (
    state.textDocumentTemplates.filter(tdt => tdt.attributes.enabled)
  ),
  hasTextDocumentTemplatesEnabled: state => (
    state.textDocumentTemplates.some(tdt => tdt.attributes.enabled)
  ),
  missingBodyVariables: state => tdt => difference(
    tdt.attributes.bodyVariablesKeys,
    Object.keys(pickBy(tdt.attributes.bodyVariablesValues, identity)),
  ),
  isMissingBodyVariables: (state, _getters) => (
    _getters.textDocumentTemplateEnabled.some(tdt => _getters.missingBodyVariables(tdt).length > 0)
  ),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
