import cloneDeep from 'lodash/cloneDeep';
import store from '@app-js/shared/store/index';
import { httpClient } from '@skello-utils/clients';
import { associationMatcherCollection } from '@skello-utils/association_matchers';

const initialState = {
  convention: {
    id: null,
    type: 'convention',
    attributes: null,
    relationships: null,
  },
  originalConventionData: {
    id: null,
    type: 'convention',
    attributes: null,
    relationships: null,
  },
  error: null,
  loadingFetch: false,
  loadingUpdate: false,
};

const formatParams = ({ convention, recalculationDate }) => {
  const { attributes, relationships } = convention;
  const {
    holidayMaj, firstMayMaj, sundayMaj, cutLength,
  } = attributes;
  const formattedParams = {
    convention: {
      holiday_maj: holidayMaj || 0,
      first_may_maj: firstMayMaj || 0,
      sunday_maj: sundayMaj || 0,
      recalculation_date: recalculationDate,
      over_hours_maj_slices: {},
      complementary_hours_maj_slices: {},
      night_hours_maj_slices: {},
    },
  };

  if (typeof cutLength !== 'undefined') {
    formattedParams.convention.cut_length = cutLength;
  }

  if (relationships) {
    const {
      overHoursMajSlices,
      complementaryHoursMajSlices,
      nightHoursMajSlices,
    } = relationships;

    if (overHoursMajSlices) {
      formattedParams.convention.over_hours_maj_slices = overHoursMajSlices.map(slice => {
        const {
          startHour, endHour, majoration, position,
        } = slice.attributes;
        return {
          start_hour: startHour,
          end_hour: endHour,
          majoration: majoration || 0,
          position,
        };
      });
    }
    if (complementaryHoursMajSlices) {
      formattedParams.convention.complementary_hours_maj_slices =
        complementaryHoursMajSlices.map(slice => {
          const { trigger, majoration, position } = slice.attributes;
          return {
            majoration: majoration || 0,
            trigger,
            position,
          };
        });
    }
    if (nightHoursMajSlices) {
      formattedParams.convention.night_hours_maj_slices = nightHoursMajSlices.map(slice => {
        const {
          startHour, endHour, majoration, position,
        } = slice.attributes;
        return {
          start_hour: startHour,
          end_hour: endHour,
          majoration: majoration || 0,
          position,
        };
      });
    }
  }

  return formattedParams;
};

const matchMajorationSlicesToConvention = (convention, included) => {
  if (included) {
    const overHoursMajSlices = associationMatcherCollection(convention, included, {
      key: 'overHoursMajSlices',
      type: 'overHoursMajSlice',
    });
    const complementaryHoursMajSlices = associationMatcherCollection(convention, included, {
      key: 'complementaryHoursMajSlices',
      type: 'complementaryHoursMajSlice',
    });
    const nightHoursMajSlices = associationMatcherCollection(convention, included, {
      key: 'nightHoursMajSlices',
      type: 'nightHoursMajSlice',
    });
    Object.assign(convention.relationships, {
      overHoursMajSlices,
      complementaryHoursMajSlices,
      nightHoursMajSlices,
    });
  }
  return convention;
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  squashOriginalConventionData(state) {
    state.originalConventionData = cloneDeep(state.convention);
  },

  fetchConventionSuccess(state, payload) {
    state.convention = matchMajorationSlicesToConvention(payload.data, payload.included);
    state.originalConventionData = cloneDeep(state.convention);
  },

  updateConventionSuccess(state, payload) {
    state.convention = matchMajorationSlicesToConvention(payload.data, payload.included);
    state.originalConventionData = cloneDeep(state.convention);
  },

  catchReportRulesError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchConvention({ commit }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingFetch');

      const shopId = store.state.currentShop.currentShop.id;

      httpClient
        .get(`/v3/api/shops/${shopId}/convention`)
        .then(response => {
          commit('fetchConventionSuccess', response.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('catchReportRulesError', response.data);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete', 'loadingFetch');
        });
    });
  },

  updateReportRules({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingUpdate');

      const shopId = store.state.currentShop.currentShop.id;
      httpClient
        .patch(`/v3/api/shops/${shopId}/convention/update_report_rules`, formatParams(params))
        .then(response => {
          commit('updateConventionSuccess', response.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('catchReportRulesError', response.data);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete', 'loadingUpdate');
        });
    });
  },
};

const getters = {
  unsavedChangesToConvention(state) {
    return JSON.stringify(state.convention) !== JSON.stringify(state.originalConventionData);
  },
  needRecalculation(state) {
    if (!store.state.currentShop.currentShop.attributes.modulationMajoration) {
      return false;
    }
    if (!state.convention.relationships) {
      return false;
    }

    return JSON.stringify(state.convention.relationships.overHoursMajSlices) !==
            JSON.stringify(state.originalConventionData.relationships.overHoursMajSlices) ||
            JSON.stringify(state.convention.relationships.complementaryHoursMajSlices) !==
            JSON.stringify(state.originalConventionData.relationships.complementaryHoursMajSlices);
  },

  // eslint-disable-next-line no-shadow
  showStickyBar(state, getters) {
    return getters.unsavedChangesToConvention &&
      store.state.currentLicense.currentLicense.attributes.canEditLegalAlertsConfig;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
