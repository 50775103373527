import Vue from 'vue';
import { captureException } from '@sentry/vue';
import {
  associationMatcherItem,
  associationMatcherCollection,
} from '@skello-utils/association_matchers';
import { httpClient } from '@skello-utils/clients';
import skDate from '@skello-utils/dates';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-client';
import { generateTeamUpdateEvent } from '@skello-utils/events/helpers/team_formatting_helper';

const initialState = {
  teams: [],
  teamSchedules: [],
  teamEmployees: [],
  error: null,
  teamsLoading: false,
  loadingCreate: false,
  loading: false,
  teamScheduleLoading: false,
  teamEmployeesLoading: false,
  fetchTeamScheduleLoading: true,
};

const matchUsersToTeam = (team, included) => {
  const users = associationMatcherCollection(team, included, { key: 'users', type: 'user' });
  Object.assign(team.relationships, { users });
  return team;
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  fetchTeamsSuccess(state, payload) {
    state.teams = payload.data;
    state.teams.forEach(team => matchUsersToTeam(team, payload.included));
    state.teamSchedules = payload.included.filter(included => included.type === 'teamSchedule');
  },

  createTeamSuccess(state, payload) {
    const team = matchUsersToTeam(payload.data, payload.included);
    const createdIndex = state.teams.findIndex(t => t.id === null);
    state.teams.splice(createdIndex, 1, team);
  },

  updateTeamSuccess(state, { payload, teamId }) {
    const team = matchUsersToTeam(payload.data, payload.included);
    const updatedIndex = state.teams.findIndex(t => t.id === teamId.toString());
    state.teams.splice(updatedIndex, 1, team);
  },

  deleteTeamSuccess(state, teamId) {
    const deletedIndex = state.teams.findIndex(team => team.id === teamId);
    state.teams.splice(deletedIndex, 1);
  },

  createTeamScheduleSuccess(state, { data }) {
    const teamIndex = state.teams.findIndex(team => team.id === data.attributes.teamId.toString());
    state.teamSchedules.push(data);
    state.teams[teamIndex].relationships.teamSchedules = {
      data: [data],
    };
  },

  updateTeamScheduleSuccess(state, { oldTeamScheduleId, teamId, data }) {
    const teamScheduleIndex = state.teamSchedules.findIndex(
      teamSchedule => teamSchedule.id === oldTeamScheduleId,
    );
    // response data is null if no team_memberships is passed
    // therefore team_schedules is destroyed, so we remove it
    if (data) {
      state.teamSchedules.splice(teamScheduleIndex, 1, data);
    } else {
      state.teamSchedules.splice(teamScheduleIndex, 1);
    }
    const teamIndex = state.teams.findIndex(team => team.id === teamId);
    state.teams[teamIndex].relationships.teamSchedules = {
      data: data ? [data] : [],
    };
  },

  setEmployeesToTeam(state, { teamId, employees }) {
    const teamIndex = state.teams.findIndex(team => team.id === teamId);
    state.teams[teamIndex].relationships.users = employees;
  },

  deleteTeamScheduleSuccess(state, teamScheduleId) {
    const teamScheduleIndex = state.teamSchedules.findIndex(
      teamSchedule => teamSchedule.id === teamScheduleId,
    );
    state.teamSchedules.splice(teamScheduleIndex, 1);
    const teamIndex =
      state.teams.findIndex(team => team.relationships.teamSchedules &&
        team.relationships.teamSchedules.data
          .some(data => data.id === teamScheduleId.toString()));
    state.teams[teamIndex].relationships.teamSchedules.data = [];
  },

  fetchTeamsEmployeesSuccess(state, { isVariableContractHoursAvailable, payload }) {
    state.teamEmployees = payload.data;
    if (isVariableContractHoursAvailable) {
      // highest license is used in CyclicAmendmentsHoursSection of VariableContractHoursModal
      // to display the appropriate text color, according to license type
      state.teamEmployees.forEach(employee => {
        const highestLicense = associationMatcherItem(
          employee,
          payload.included,
          { key: 'highestLicense', type: 'license' },
        );
        employee.attributes.licenseType = highestLicense.attributes.originalType;
      });
    }
  },

  fetchTeamsSchedulesSuccess(state, payload) {
    state.teamSchedules.push(...payload.data);
  },

  catchTeamsError(state, payload) {
    state.error = payload;
  },
};

const actions = {
  fetchTeams({ commit }, { shopId, isVariableContractHoursAvailable }) {
    commit('performingRequest', 'teamsLoading');

    return httpClient
      .get(`/v3/api/teams?shop_id=${shopId}&with_team_schedules=${isVariableContractHoursAvailable}`)
      .then(response => {
        commit('fetchTeamsSuccess', response.data);
      })
      .catch(error => {
        commit('catchTeamsError', error.data);
      })
      .finally(() => {
        commit('requestComplete', 'teamsLoading');
      });
  },

  createTeam({ commit }, team) {
    commit('performingRequest', 'loadingCreate');

    return httpClient
      .post('/v3/api/teams', { team })
      .then(response => {
        commit('createTeamSuccess', response.data);

        try {
          Vue.prototype.$svcEvents.create(
            EVENT_SUBTYPE_ACTION.SHOP_TEAM_CREATE,
            { id: response.data.data.id, name: response.data.data.attributes.name },
          );
        } catch (error) {
          captureException(error);
        }
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'loadingCreate');
      });
  },

  updateTeam({ commit }, params) {
    // Event is generated before the initialState is modified by the commit
    const event = generateTeamUpdateEvent(
      structuredClone(initialState.teams.find(t => t.id === params.id)),
      params.team,
    );

    commit('performingRequest');
    return httpClient
      .patch(`/v3/api/teams/${params.id}`, { team: params.team })
      .then(response => {
        commit('updateTeamSuccess', { payload: response.data, teamId: Number(params.id) });

        try {
          Vue.prototype.$svcEvents.create(
            event.subtype,
            event.payload,
          );
        } catch (error) {
          captureException(error);
        }
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  deleteTeam({ commit }, { id, attributes }) {
    commit('performingRequest');

    return httpClient
      .delete(`/v3/api/teams/${id}`)
      .then(() => {
        commit('deleteTeamSuccess', id);

        try {
          Vue.prototype.$svcEvents.create(
            EVENT_SUBTYPE_ACTION.SHOP_TEAM_DELETE,
            { id, name: attributes.name },
          );
        } catch (error) {
          captureException(error);
        }
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  createTeamSchedule({ commit }, params) {
    commit('performingRequest', 'teamScheduleLoading');

    return httpClient
      .post('/v3/api/team_schedules', params)
      .then(response => {
        commit('createTeamScheduleSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'teamScheduleLoading');
      });
  },

  updateTeamSchedule({ commit }, params) {
    commit('performingRequest', 'teamScheduleLoading');

    const id = params.team_schedule.id;
    const teamId = params.team_schedule.team_id;

    return httpClient
      .patch(`/v3/api/team_schedules/${id}`, params)
      .then(response => {
        commit('updateTeamScheduleSuccess', {
          oldTeamScheduleId: id,
          teamId,
          data: response.data.data,
        });
        return response;
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'teamScheduleLoading');
      });
  },

  deleteTeamSchedule({ commit }, params) {
    commit('performingRequest', 'teamScheduleLoading');

    const teamScheduleId = params.id;
    delete params.id;

    return httpClient
      .delete(`/v3/api/team_schedules/${teamScheduleId}`, { params })
      .then(() => {
        commit('deleteTeamScheduleSuccess', teamScheduleId);
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'teamScheduleLoading');
      });
  },

  disableTeamSchedule({ commit }, params) {
    commit('performingRequest', 'teamScheduleLoading');
    const teamScheduleId = params.id;

    return httpClient
      .patch(`/v3/api/team_schedules/${teamScheduleId}/disable`, params)
      .then(response => {
        commit('updateTeamScheduleSuccess', {
          data: response.data.data,
          oldTeamScheduleId: teamScheduleId,
          teamId: params.team_id,
        });
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'teamScheduleLoading');
      });
  },

  fetchCyclicAmendments({ commit }, teamScheduleId) {
    commit('performingRequest', 'teamScheduleLoading');

    return httpClient
      .get(`/v3/api/amendments?team_schedule_id=${teamScheduleId}`)
      .then(response => response.data)
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'teamScheduleLoading');
      });
  },

  updateRollingTeamEmployees({ commit }, params) {
    commit('performingRequest', 'teamScheduleLoading');

    const id = params.team_schedule.id;

    return httpClient
      .patch(`/v3/api/team_schedules/${id}/update_team_employees`, params)
      .then(response => response)
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'teamScheduleLoading');
      });
  },

  fetchTeamsEmployees({ commit }, { shopId, isVariableContractHoursAvailable }) {
    commit('performingRequest', 'teamEmployeesLoading');

    let url = `/v3/api/users/shop_teammates?shop_id=${shopId}`;
    if (isVariableContractHoursAvailable) {
      url = `${url}&with_highest_license=true&with_contract_info=true`;
    }

    httpClient
      .get(url)
      .then(response => {
        commit('fetchTeamsEmployeesSuccess', {
          isVariableContractHoursAvailable,
          payload: response.data,
        });
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'teamEmployeesLoading');
      });
  },

  async fetchTeamSchedules({ commit }, { shopId, teamIds }) {
    commit('performingRequest', 'fetchTeamScheduleLoading');

    if (teamIds.length <= 0) {
      commit('requestComplete', 'fetchTeamScheduleLoading');
      return;
    }

    await httpClient
      .get(`/v3/api/team_schedules?shop_id=${shopId}&team_ids=${teamIds}`)
      .then(response => {
        commit('fetchTeamsSchedulesSuccess', response.data);
      })
      .catch(error => {
        commit('catchTeamsError', error);
        throw error;
      }).finally(() => {
        commit('requestComplete', 'fetchTeamScheduleLoading');
      });
  },
};

const getters = {
  allTeamNames: state => state.teams.map(team => team.attributes.name),
  isNameAlreadyUsed: (_state, getter) => teamName => getter.allTeamNames.includes(teamName),
  teamSchedulesForTeam: state => teamId => state.teamSchedules
    .filter(teamSchedule => teamSchedule.attributes.teamId === parseInt(teamId, 10))
    .sort((a, b) => skDate.utc(a.attributes.startDate) - skDate.utc(b.attributes.startDate)),
  lastTeamScheduleForTeam: (_state, getter) => teamId => {
    const teamSchedules = getter.teamSchedulesForTeam(teamId);
    if (teamSchedules.length === 0) return null;

    return teamSchedules[teamSchedules.length - 1];
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
