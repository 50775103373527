export const formatEvent = store => {
  const organisationId = store.currentOrganisation?.id.toString() ??
    store.currentShop.relationships?.clusterNode.attributes.organisationId.toString();
  const impersonatedBy = store.config?.segment_data?.true_user?.data?.id;

  return {
    userId: store.currentUser.id,
    impersonatedBy,
    shopId: store.currentShop.id === 'all' ? undefined : store.currentShop.id,
    organisationId,
    clusterNodeId: store.currentUser.attributes.rootNodeId.toString(),
    licenseId: store.currentLicense.id,
  };
};
