export const MANDATORY_ATTRIBUTES_FOR_DPAE = {
  shop: [
    'siret',
    'nafCode',
    'urssafCode',
    'sstCode',
    'denominationSociale',
    'zipcode',
    'city',
    'address',
  ],
  employee: ['firstName', 'lastName', 'birthday', 'birthPlace', 'birthDepartment'],
  contract: ['startDate', 'contractTypeId'],
};
