import Vue from 'vue';
import { captureException } from '@sentry/vue';
import { httpClient } from '@skello-utils/clients';
import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-client';
import { generatePosteUpdateEvent } from '@skello-utils/events/helpers/poste_formatting_helper';
import { localizeDefaultPoste } from '@app-js/shared/utils/default_poste_helper';

const initialState = {
  shopPostes: [],
  postesSuggestions: [],
  error: null,
  loadingFetch: false,
  loadingCreate: false,
  loadingBulkCreate: false,
  loading: false,
};

const mutations = {
  performingRequest(state, key) {
    if (key) {
      state[key] = true;
    } else {
      state.loading = true;
    }
  },

  requestComplete(state, key) {
    if (key) {
      state[key] = false;
    } else {
      state.loading = false;
    }
  },

  fetchShopPostesSuccess(state, payload) {
    state.shopPostes = payload.data;
    state.shopPostes.forEach(poste => localizeDefaultPoste(poste));
  },

  fetchPostesSuggestionsSuccess(state, payload) {
    state.postesSuggestions = payload;
  },

  createShopPosteSuccess(state, payload) {
    state.shopPostes.splice(state.shopPostes.length, 0, payload.data);
  },

  updateShopPosteSuccess(state, { payload, shopPosteId }) {
    const updatedIndex = state.shopPostes.findIndex(
      shopPoste => shopPoste.id === shopPosteId,
    );

    state.shopPostes.splice(updatedIndex, 1, payload.data);
  },

  deleteShopPosteSuccess(state, shopPosteId) {
    const deletedIndex = state.shopPostes.findIndex(
      shopPoste => shopPoste.id === shopPosteId.toString(),
    );

    state.shopPostes.splice(deletedIndex, 1);
  },

  catchShopPostesError(state, payload) {
    state.error = payload;
  },
};

const actions = {
  fetchShopPostes({ commit }, { shopId }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingFetch');

      httpClient
        .get(`/v3/api/shops/${shopId}/postes`)
        .then(response => {
          commit('fetchShopPostesSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchShopPostesError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete', 'loadingFetch');
        });
    });
  },

  createShopPoste({ commit }, { shopId, shopPoste }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingCreate');

      const params = {
        poste: {
          name: shopPoste.name,
          pause_time: shopPoste.pause_time * 60,
          color: shopPoste.color,
          percentage_pause_time: shopPoste.percentage_pause_time,
        },
      };

      httpClient
        .post(`/v3/api/shops/${shopId}/postes`, params)
        .then(response => {
          commit('createShopPosteSuccess', response.data);
          resolve(response);

          try {
            Vue.prototype.$svcEvents.create(EVENT_SUBTYPE_ACTION.SHOP_POSTE_CREATE, {
              id: response.data.data.id, name: response.data.data.attributes.name,
            });
          } catch (error) {
            captureException(error);
          }
        })
        .catch(error => {
          commit('catchShopPostesError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete', 'loadingCreate');
        });
    });
  },

  bulkCreateShopPoste({ commit }, { shopId, shopPostes }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest', 'loadingBulkCreate');

      return httpClient
        .post(`/v3/api/shops/${shopId}/postes/_bulk_create`, { postes: shopPostes })
        .then(response => {
          resolve(response);

          try {
            response.data.data.forEach(poste => {
              commit('createShopPosteSuccess', poste);
              Vue.prototype.$svcEvents.create(EVENT_SUBTYPE_ACTION.SHOP_POSTE_CREATE, {
                id: poste.id, name: poste.attributes.name,
              });
            });
          } catch (error) {
            captureException(error);
          }

          return response;
        })
        .catch(error => {
          commit('catchShopPostesError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete', 'loadingBulkCreate');
        });
    });
  },

  updateShopPoste({ commit }, { shopId, shopPoste }) {
    return new Promise((resolve, reject) => {
    // Event is generated before the initialState is modified by the commit
      const event = generatePosteUpdateEvent(
        structuredClone(initialState.shopPostes.find(p => p.id === shopPoste.id)),
        shopPoste.shopPoste,
      );
      commit('performingRequest');

      httpClient
        .patch(`/v3/api/shops/${shopId}/postes/${shopPoste.id}`, {
          poste: shopPoste.shopPoste,
        })
        .then(response => {
          commit('updateShopPosteSuccess', {
            payload: response.data,
            shopPosteId: shopPoste.id,
          });
          resolve(response);
          try {
            Vue.prototype.$svcEvents.create(
              event.subtype,
              event.payload,
            );
          } catch (error) {
            captureException(error);
          }
        })
        .catch(error => {
          commit('catchShopPostesError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },

  deleteShopPoste({ commit }, { shopId, shopPoste }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest');

      httpClient
        .delete(`/v3/api/shops/${shopId}/postes/${shopPoste.id}`)
        .then(() => {
          commit('deleteShopPosteSuccess', shopPoste.id);
          resolve();

          try {
            Vue.prototype.$svcEvents.create(EVENT_SUBTYPE_ACTION.SHOP_POSTE_DELETE, {
              id: shopPoste.id, name: shopPoste.attributes.name,
            });
          } catch (error) {
            captureException(error);
          }
        })
        .catch(error => {
          commit('catchShopPostesError', error.response.data);
          reject(error);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },

  fetchPostesSuggestions({ commit }, { shopId }) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v3/api/shops/${shopId}/postes/postes_suggestions`)
        .then(response => {
          commit('fetchPostesSuggestionsSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchShopPostesError', error.response.data);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
