import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

export const arrayToSelectOptions = (array, callback) => {
  if (!array || array.length === 0) return [];
  return array.map(value => ({
    id: value,
    text: typeof callback === 'function' ? callback(value) : value,
  }));
};

// eslint-disable-next-line arrow-body-style
export const filterObjectFields = (objectFields, fieldsToExclude) => {
  return pick(
    objectFields,
    differenceWith(Object.keys(objectFields), fieldsToExclude, isEqual),
  );
};
