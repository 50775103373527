import { merge } from '@skello-utils/object';

export const splitList = (list, size) => {
  const result = [];
  for (let i = 0; i < list.length; i += size) {
    result.push(list.slice(i, i + size));
  }
  return result;
};

export const ACTION_COUNT_TYPES = {
  deleteSchedule: 'deleteSchedule',
  closePopover: 'closePopover',
  displayDeleteSurvey: 'displayDeleteSurvey',
};

export const getBrainSettings = (newSettings = {}) => {
  const brainSettings = {
    enabled: true,
    actionCount: {
      [ACTION_COUNT_TYPES.deleteSchedule]: 0,
      [ACTION_COUNT_TYPES.closePopover]: 0,
      [ACTION_COUNT_TYPES.displayDeleteSurvey]: 0,
    },
  };

  return merge(brainSettings, newSettings);
};

export const getBrainSettingsFromLocalStorage = () => {
  const brainSettings = localStorage.getItem('brainSettings');

  if (brainSettings) {
    return merge(getBrainSettings(), JSON.parse(brainSettings));
  }

  return getBrainSettings();
};

export const saveBrainSettingsToLocalStorage = brainSettings => {
  localStorage.setItem('brainSettings', JSON.stringify(brainSettings));
};

export const retriablePromise = (originalPromise, onFail) => {
  const retry = () => retriablePromise(originalPromise, onFail);
  return originalPromise().catch(err => onFail(retry, err));
};
