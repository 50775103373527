import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import * as Sentry from '@sentry/vue';
import {
  httpClient,
  punchClient,
} from '@skello-utils/clients';
import store from '@app-js/shared/store/index';
import {
  associationMatcherItem,
  associationMatcherCollection,
} from '@skello-utils/association_matchers';
import skDate from '@skello-utils/dates';
import {
  groupShifts, isShiftInBadgingRange,
} from '@app-js/badgings/shared/utils';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import {
  MatchedBadging,
  DayShiftBadgingMatcher,
} from '@app-js/badgings/shared/class';
import { upsertTasksApiRequest } from '@app-js/shared/store/modules/plannings/api/shift';
import { FEATURES } from '@app-js/shared/constants/features';
import { localizeDefaultPoste } from '@app-js/shared/utils/default_poste_helper';

const BADGINGS_ENDPOINT = '/v3/api/badgings';
const UPDATE_SHIFT_ENDPOINT = '/v3/api/plannings/shifts';

export const BADGING_BUFFER_BEFORE_OPENING = 30;

const initialState = {
  matchedBadgings: [],
  matchedBadgingsUserIds: [],
  originalDayAbsences: [],
  originalMatchedBadgings: [],
  originalMatchedBadgingsUserIds: [],
  dayAbsences: [],
  dayAbsencesUserIds: [],
  teams: [],
  selectedTeamIds: [],
  currentDate: undefined,
  error: null,
  matchedBadgingsLoading: false,
  absencesLoading: false,
  lastUserShopPoste: null,
  lastUserShopPosteLoading: false,
  dayLocked: false,
  weeklyOptionsLocks: {},
  weeklyOptionsLoading: false,
  shifts: [],
  shiftsLoading: false,
  badgings: [],
  badgingsLoading: false,
  badgingsHistory: [],
  badgingsHistoryLoading: false,
  users: [],
  originalUsers: [],
  usersLoading: true,
  settings: null,
  settingsLoading: false,
  placeholderMatchedBadgings: {},
};

const getOriginalShiftsParams = shifts => (shifts.map(shift => ({
  id: shift.id,
  starts_at: shift.attributes.startsAt,
  ends_at: shift.attributes.endsAt,
  note: shift.attributes.note,
  poste_id: shift.relationships.poste.id,
  user_id: shift.attributes.userId,
  shop_id: shift.attributes.shopId,
  nb_meal: shift.attributes.nbMeal,
  pause_time: shift.attributes.pauseTime,
  day_absence: shift.attributes.dayAbsence,
  previsional_start: shift.attributes.previsionalStart,
  previsional_end: shift.attributes.previsionalEnd,
  previsional_saved: shift.attributes.previsionalSaved,
  previsional_poste_id: shift.attributes.previsionalPosteId,
  absence_calculation: shift.attributes.absenceCalculation,
  hours_worth: shift.attributes.hoursWorth,
  delay: shift.attributes.delay,
  show_start_time: shift.attributes.showStartTime,
  show_end_time: shift.attributes.showEndTime,
  show_duration: shift.attributes.showDuration,
  provenance: shift.attributes.provenance,
})));

const getShiftParams = shift => ({
  id: shift.id,
  starts_at: shift.attributes.previsionalSaved ?
    shift.attributes.previsionalStart :
    shift.attributes.startsAt,
  ends_at: shift.attributes.previsionalSaved ?
    shift.attributes.previsionalEnd :
    shift.attributes.endsAt,
  poste_id: shift.attributes.previsionalSaved ?
    shift.attributes.previsionalPosteId :
    shift.relationships?.poste?.id,
  user_id: shift.attributes.userId,
  shop_id: shift.attributes.shopId,
  nb_meal: 0,
  pause_time: 0,
  previsional_start: null,
  previsional_end: null,
  previsional_saved: false,
  previsional_poste_id: null,
  absence_calculation: '',
});

const matchPosteToShift = (shift, included) => {
  const poste = associationMatcherItem(shift, included, { key: 'poste', type: 'poste' });
  const previsionalPoste = associationMatcherItem(shift, included, { key: 'previsionalPoste', type: 'poste' });

  Object.assign(shift.relationships, { poste, previsionalPoste });

  return shift;
};

const isTurnedAsAbsence = matchedBadging => (
  matchedBadging.isAbsence && matchedBadging.turnedAsAbsence
);

const hasAbsenceBeenBadged = matchedBadging => matchedBadging.isAbsence && // Is currently an absence
  (matchedBadging.badgingStartsAt || matchedBadging.badgingEndsAt) && // Badges linked to the row
  (
    !matchedBadging.validated || // It's not validated yet (isAbsence proves it was an absence)
    matchedBadging.previsionalPosteName // Or it IS validated, but used to be a poste -> changed to absence
  );

const matchUsersToTeam = (team, included) => {
  const users = associationMatcherCollection(team, included, { key: 'users', type: 'user' });

  Object.assign(team.relationships, { users });

  return team;
};

const plannedAbsenceName = matchedBadging => {
  if (matchedBadging.isAbsence && !matchedBadging.turnedAsAbsence) {
    return matchedBadging.validated && matchedBadging.previsionalPosteIsAbsence ?
      matchedBadging.previsionalPosteName :
      matchedBadging.absencePosteName;
  }

  return '';
};

const badgingBreaksDuration = matchedBadging => {
  let breakDuration = 0;

  matchedBadging.badgingBreaks.forEach(breakTimes => {
    const startBreakTime = skDate(breakTimes[0]);
    const endBreakTime = skDate(breakTimes[1]);

    breakDuration += endBreakTime.diff(startBreakTime);
  });

  return (breakDuration / 60000).toFixed();
};

const skipPrevisional = matchedBadging => {
  const hasNoShift = !(matchedBadging.predictedStartsAt || matchedBadging.predictedEndsAt);
  const showHideShift = matchedBadging.validated ? !matchedBadging.previsionalPosteName : false;

  return (hasNoShift || showHideShift) && !hasAbsenceBeenBadged(matchedBadging);
};

const groupedChoiceStart = (shift, matchedBadging, shiftIndex) => {
  if (shiftIndex === 0) {
    return matchedBadging.selectedStartsAt;
  }

  let startsAt = shift.attributes.startsAt;

  if (shiftIndex !== 0 && shift.attributes.previsionalSaved) {
    startsAt = shift.attributes.previsionalStart;
  }

  return skDate(startsAt).isValid() ? skDate(startsAt).utc().format('HH:mm') : '';
};

const groupedChoiceEnd = (shift, matchedBadging, shiftIndex, nbShifts) => {
  // Case of shift is last from grouped
  if (nbShifts === shiftIndex + 1) {
    return matchedBadging.selectedEndsAt;
  }

  if (nbShifts !== shiftIndex + 1 && shift.attributes.previsionalSaved) {
    return skDate(shift.attributes.previsionalEnd).isValid() ? skDate(shift.attributes.previsionalEnd).utc().format('HH:mm') : '';
  }

  return skDate(shift.attributes.endsAt).isValid() ? skDate(shift.attributes.endsAt).utc().format('HH:mm') : '';
};

const groupedShiftStart = (shift, shiftIndex) => {
  let { startsAt } = shift.attributes;

  if (shiftIndex !== 0 && shift.attributes.previsionalSaved) {
    startsAt = shift.attributes.previsionalStart;
  }

  return skDate(startsAt).isValid() ? skDate(startsAt).utc().format('HH:mm') : '';
};

const getPosteId = shift => (
  shift?.relationships?.previsionalPoste?.attributes?.absenceKey ?
    shift?.relationships?.poste?.id : shift?.relationships?.previsionalPoste?.id
);

const getGroupedShiftPosteId = shift => (
  shift?.relationships.previsionalPoste?.id || shift?.relationships.poste.id
);

const getAbsenceCalculation = matchedBadging => (
  !!matchedBadging.turnedAsAbsence ||
  (!!matchedBadging.absencePosteId && matchedBadging.previsionalPosteIsAbsence) ?
    matchedBadging.absenceCalculation || 'hours' : ''
);

const groupedChoiceMeal = (shift, matchedBadging, shiftIndex, shifts) => {
  const originalMeals = sumBy(shifts, s => s.attributes.nbMeal);

  if (matchedBadging.meal === originalMeals) {
    return shift.attributes.nbMeal;
  }

  // Case of shift is last from grouped
  if (shifts.length === shiftIndex + 1) {
    return matchedBadging.meal;
  }

  return 0;
};

/* Return the maximum pause duration that fits in the shift.
** @param {number} remainingBreak - the remaining break duration in minutes
** @param {object} shift - the shift object
** @param {string} choiceStart - the chosen start time
** @param {string} choiceEnd - the chosen end time
** @return {number} - the maximum pause duration that fits in the shift
**
** NOTES:
** We cannot use the `duration` field because it can change between successive updates,
** as it represent the effective duration.
** For the same reason we cannot use startsAt and endsAt to calculate the shift "planned" duration.
** In the shift's initial state previsionalStart and previsionalEnd are null.
** Hence we have to recompute the shift duration manually based on different start and end references
** as the planned one are not stored in the same fields each time an update occurs
** This way we ensure a predictible result.
*/
export const groupedChoicePause = (remainingBreak, shift, choiceStart, choiceEnd) => {
  if (remainingBreak === 0) return 0;

  const { startsAt, previsionalStart, endsAt, previsionalEnd } = shift.attributes;
  const referencialStart = previsionalStart ?? startsAt;
  const referencialEnd = previsionalEnd ?? endsAt;

  const shiftPlannedDurationInMinutes =
    skDate(referencialEnd).diff(skDate(referencialStart), 'minutes');

  const choiceStartDate = skDate(choiceStart, 'hh:mm');
  const choiceEndDate = skDate(choiceEnd, 'hh:mm');

  /* NOTE: choiceStart and choiceEnd are provided as 'hh:mm' ex: '23:59'
  ** some situations like choiceStart == '23:00' and choiceEnd == '01:00' can occur and be valid.
  ** ex: starting the shift at 23:00 on a monday and ending it at 01:00 on a tuesday.
  ** Here choiceEndDate.diff(choiceStartDate, 'minutes') would return a negative value of -1320 minutes.
  ** to avoid that we have to shift the choiceEndDate to the next day.
  */
  if (choiceStartDate >= choiceEndDate) {
    choiceEndDate.add(1, 'day');
  }

  const choiceDurationInMinutes = choiceEndDate.diff(choiceStartDate, 'minutes');

  const shiftDurationInMinutes =
    Math.min(shiftPlannedDurationInMinutes, choiceDurationInMinutes);

  return Math.min(remainingBreak, shiftDurationInMinutes);
};

// Here we create a two dimensional array, seperate shifts per day,
// we want to know if the day was save as grouped
const groupShiftsPerDay = shifts => Array.from(Object.values(groupBy(shifts,
  shift => skDate(shift.attributes.startsAt).isoWeekday(),
)));

const shouldGroupShifts = (date, badgingHistories, dayClocksInOut) => {
  if (store.getters['currentShop/isHospitalitySector']) return false;

  /* FIXME: this seems to provoke a bug in the rendering of the employee view
  ** making it dandomly false or true and hence changing the disdplay between page reload.
  ** const shiftDate = skDate(date).format('YYYY-MM-DD');
  ** const dayBadgingHistory = orderBy(badgingHistories.filter(
  **   badgingHistory => badgingHistory.date === shiftDate,
  ** ), ['validated_at'], ['asc']);

  ** return isEmpty(dayBadgingHistory) ?
  **   dayClocksInOut :
  **   dayBadgingHistory[0].saved_as_grouped;
  */
  return dayClocksInOut;
};

const uniqByUuidAndLastUpdated = badgings => {
  // Here we want to filter badgings with same uuid and only keep the last updated from db
  // Will not be useful when svc punch fully in production
  const sortedBadgings = badgings.sort((badgingA, badgingB) => (
    skDate(badgingB.updated_at) - skDate(badgingA.updated_at)
  ));

  return uniqBy(sortedBadgings, 'in_uuid');
};

const updateShifts = params => {
  const updateParams = {
    starts_at: params.periodStartsAt,
    ends_at: params.periodEndsAt,
    shop_id: params.shopId,
    shifts: getOriginalShiftsParams(params.shifts),
  };

  return httpClient
    .patch(UPDATE_SHIFT_ENDPOINT, updateParams);
};

const mutations = {
  setPlaceholderMatchedBadgings(state, payload) {
    state.placeholderMatchedBadgings = payload;
  },
  performingMatchedBadgingsRequest(state) {
    state.matchedBadgingsLoading = true;
  },

  performingLastUserShopPosteRequest(state) {
    state.lastUserShopPosteLoading = true;
  },

  performingAbsencesRequest(state) {
    state.absencesLoading = true;
  },

  performingShiftsRequest(state) {
    state.shiftsLoading = true;
  },

  performingUsersRequest(state) {
    state.users = [];
    state.usersLoading = true;
  },

  performingBadgingsHistoryRequest(state) {
    state.badgingsHistoryLoading = true;
  },

  performingBadgingsRequest(state) {
    state.badgingsLoading = true;
  },

  performingDayLockedOnPlanningRequest(state) {
    state.weeklyOptionsLoading = true;
  },

  performingSettingsRequest(state) {
    state.settingsLoading = true;
  },

  matchedBadgingsRequestComplete(state) {
    state.matchedBadgingsLoading = false;
  },

  absencesRequestComplete(state) {
    state.absencesLoading = false;
  },

  lastUserShopPosteRequestComplete(state) {
    state.lastUserShopPosteLoading = false;
  },

  shiftsRequestComplete(state) {
    state.shiftsLoading = false;
  },

  badgingsHistoryRequestComplete(state) {
    state.badgingsHistoryLoading = false;
  },

  fetchBadgingsRequestComplete(state) {
    state.badgingsLoading = false;
  },

  usersRequestComplete(state) {
    state.usersLoading = false;
  },

  fetchWeeklyOptionsRequestComplete(state) {
    state.weeklyOptionsLoading = false;
  },

  fetchSettingsRequestComplete(state) {
    state.settingsLoading = false;
  },

  setQueryUsers(state, filteredUsers) {
    state.users = filteredUsers;
  },

  fetchLastUserShopPosteSuccess(state, payload) {
    if (payload.data) {
      state.lastUserShopPoste = payload.data;
    } else {
      state.lastUserShopPoste = null;
    }
  },

  fetchTeamsSuccess(state, payload) {
    const { currentLicense, currentUser, route } = store.state;

    if (!currentLicense.currentLicense.attributes.canReadTeammates) {
      state.teams = payload.data;
    } else {
      const currentUserTeamIds =
       currentUser.currentUser.relationships.teams.data.map(team => team.id);

      state.teams = payload.data.filter(team => currentUserTeamIds.includes(team.id));
    }

    const urlTeamIds = Array(route.query.team_ids).flat();

    state.teams.forEach(team => {
      matchUsersToTeam(team, payload.included);

      // handle selected teams in filters
      if (urlTeamIds.includes(team.id) && !state.selectedTeamIds.includes(team.id)) {
        state.selectedTeamIds.push(team.id);
      } else if (!urlTeamIds.includes(team.id)) {
        const indexToDelete = state.selectedTeamIds.indexOf(team.id);

        if (indexToDelete === -1) return;

        state.selectedTeamIds.splice(indexToDelete, 1);
      }
    });
  },

  fetchShiftsSuccess(state, payload) {
    const { currentShop } = store.state.currentShop;

    state.shifts =
      payload
        .data
        .filter(shift => String(shift.attributes.shopId) === currentShop.id)
        .sort((a, b) => a.attributes.startsAt - b.attributes.startsAt);

    state.shifts.forEach(shift => {
      matchPosteToShift(shift, payload.included);

      const user = state.users.find(u => String(u.id) === String(shift.attributes.userId));

      Object.assign(shift.relationships, { user });

      localizeDefaultPoste(shift.relationships.poste);
    });

    state.dayAbsences = state.shifts.filter(shift => shift.attributes.absenceCalculation !== '');
    state.dayAbsences = orderBy(state.dayAbsences, ['attributes.startsAt', 'relationships.user.attributes.firstName'], ['asc', 'asc']);
    state.originalDayAbsences = cloneDeep(state.dayAbsences);
  },

  updateShiftNoteInMatchedBadgings(state, payload) {
    const shift = payload.data[0];

    state.matchedBadgings.forEach(matchedBadging => {
      if (matchedBadging.shift?.id === shift.id) {
        matchedBadging.shift.attributes.note = shift.attributes.note;
        return;
      }
      if (matchedBadging.shiftId?.includes(`${shift.id}`)) {
        const shiftIndexToUpdate =
          matchedBadging.shift?.shifts?.findIndex(singleShift => (singleShift.id === shift.id));
        matchedBadging.shift.shifts[shiftIndexToUpdate].attributes.note = shift.attributes.note;
      }
    });
  },

  updateShiftTasksInMatchedBadgings(state, payload) {
    state.matchedBadgings.forEach(matchedBadging => {
      if (matchedBadging.shift?.id === payload.shiftId) {
        matchedBadging.shift.attributes.tasks = payload.data.tasks;
        return;
      }

      if (matchedBadging.shiftId?.includes(`${payload.shiftId}`)) {
        const shiftIndexToUpdate = matchedBadging.shift.shifts.findIndex(
          singleShift => (singleShift.id === payload.shiftId),
        );
        matchedBadging.shift.shifts[shiftIndexToUpdate].attributes.tasks = payload.data.tasks;
      }
    });
  },

  fetchUsersSuccess(state, payload) {
    state.users = payload.data;
    state.originalUsers = payload.data;
  },

  fetchWeeklyOptionsSuccess(state, payload) {
    const { validatedDays, intermediateLockedDays, permanentLockedDays } = payload.data.attributes;
    state.weeklyOptionsLocks = { validatedDays, intermediateLockedDays, permanentLockedDays };
  },

  fetchSettingsSuccess(state, payload) {
    state.settings = payload;
  },

  fetchBadgingsSuccess(state, payload) {
    state.badgings = uniqByUuidAndLastUpdated(payload);

    state.badgings = state.badgings.sort((badgingA, badgingB) => (
      skDate(badgingA.in) - skDate(badgingB.in)
    ));
  },

  fetchBadgingsHistorySuccess(state, payload) {
    state.badgingsHistory = payload.sort((badgingHistoryA, badgingHistoryB) => (
      skDate(badgingHistoryA.validated_at) - skDate(badgingHistoryB.validated_at)
    ));
  },

  toggleTeamFilter(state, team) {
    const index = state.selectedTeamIds.indexOf(team.id);

    if (index === -1) {
      state.selectedTeamIds.push(team.id);
    } else {
      state.selectedTeamIds.splice(index, 1);
    }
  },

  eraseFilters(state) {
    state.selectedTeamIds = [];
  },

  catchTeamsError(state, payload) {
    state.error = payload;
  },

  fetchMatchedBadgingsError(state, error) {
    state.error = error;
  },

  fetchLastUserShopPosteError(state, error) {
    state.error = error;
  },

  fetchShiftsError(state, error) {
    state.error = error;
  },

  fetchUsersError(state, error) {
    state.error = error;
  },

  fetchBadgingsError(state, error) {
    state.error = error;
  },

  fetchBadgingsHistoryError(state, error) {
    state.error = error;
  },

  fetchWeeklyOptionsError(state, error) {
    state.error = error;
  },

  fetchSettingsError(state, error) {
    state.error = error;
  },

  updateCurrentDate(state, { date }) {
    state.currentDate = date;
  },

  updateMatchedBadgingByKey(state, { key, ...payload }) {
    state.matchedBadgings = state.matchedBadgings.map(obj => (obj.key === key ?
      { ...obj, ...payload } : obj),
    );
  },

  filterAbsencesByTeam(state) {
    if (state.selectedTeamIds.length !== 0) {
      const selectedUserIds = state.teams
        .filter(team => state.selectedTeamIds.includes(team.id))
        .flatMap(team => team.relationships.users)
        .map(user => user.id);

      state.dayAbsences = state.dayAbsences.filter(
        shift => selectedUserIds.includes(String(shift.attributes.userId)),
      );
    } else {
      state.dayAbsences = state.originalDayAbsences;
    }
  },

  filterMatchedBadgingsByTeam(state) {
    if (state.selectedTeamIds.length !== 0) {
      const selectedUserIds = state.teams
        .filter(team => state.selectedTeamIds.includes(team.id))
        .flatMap(team => team.relationships.users)
        .map(user => user.id);

      state.matchedBadgings = state.originalMatchedBadgings.filter(
        matchedBadging => selectedUserIds.includes(String(matchedBadging.userId)),
      );
    } else {
      state.matchedBadgings = state.originalMatchedBadgings;
    }
  },

  sortmatchedBadgings(state, { columnName, direction }) {
    state.matchedBadgings = orderBy(state.matchedBadgings, [columnName], [direction]);

    if (columnName === 'predictedStartsAt') {
      state.matchedBadgings = sortBy(state.matchedBadgings, matchedBadging => {
        const atEndOfList = direction === 'asc' ? 0 : 1;
        const atTopOfList = direction === 'desc' ? 0 : 1;

        const hasNoShift = !matchedBadging.predictedStartsAt && !matchedBadging.predictedEndsAt;

        return (matchedBadging.isAbsence && !matchedBadging.turnedAsAbsence) || hasNoShift ?
          atTopOfList : atEndOfList;
      });
    }
  },

  sortAbsences(state, { columnName, direction }) {
    if (columnName === 'userName') {
      state.dayAbsences = orderBy(state.dayAbsences, ['relationships.user.attributes.firstName'], [direction]);
    } else {
      state.dayAbsences = orderBy(state.dayAbsences, ['relationships.poste.attributes.name'], [direction]);
    }
  },

  squashMatchedBadging(state) {
    state.matchedBadgings = cloneDeep(state.originalMatchedBadgings);
    state.dayLocked = true;
  },

  updateDayLocked(state, newValue) {
    state.dayLocked = newValue;
  },

  initDayBadgingSuccess(state, isEmployeesPage) {
    const {
      users,
      shifts,
      badgings,
      badgingsHistory,
      matchedBadgings,
      settings,
      currentDate,
    } = state;

    const { currentShop } = store.state.currentShop;
    const { punchClockSettings } = store.state.punchClock;
    const { currentUser } = store.state.currentUser;
    const dayClocksInOut = punchClockSettings.dayClocksInOut;

    const shouldGroupDayShifts = shouldGroupShifts(
      currentDate,
      badgingsHistory,
      dayClocksInOut,
    );

    new DayShiftBadgingMatcher(users, shifts, badgings, shouldGroupDayShifts).run();

    const shiftsWithBadgingsIds = [];

    matchedBadgings.splice(0, matchedBadgings.length);

    badgings.forEach(badging => {
      const foundUser = users.find(user => parseInt(user.id, 10) === badging.user_id);

      /* Collect of badgings without shifts */
      if (badging.shift) {
        shiftsWithBadgingsIds.push(badging.shift?.id.split('#'));
      }
      /* Case : badgings with one or more shift associated */
      if (foundUser) {
        matchedBadgings.push(new MatchedBadging(
          badging,
          badging.shift,
          badgingsHistory,
          foundUser,
          currentUser,
          currentShop,
          settings,
          isEmployeesPage,
          currentDate,
        ));
      }
    });

    /* Case : shifts without badgings */
    const shiftsWithoutBadgings =
      sortBy(shifts.filter(
        shift => !shiftsWithBadgingsIds.flat().includes(String(shift.id)),
      ), 'attributes.startsAt');

    let shiftsCollection = [];

    if (isEmployeesPage) {
      const shiftsWithoutBadgingsPerDay = groupShiftsPerDay(shiftsWithoutBadgings);

      shiftsWithoutBadgingsPerDay.forEach(shiftsForDay => {
        const shouldGroupShiftsPerDay = shouldGroupShifts(
          shiftsForDay[0].attributes.startsAt,
          badgingsHistory,
          dayClocksInOut,
        );

        if (shouldGroupShiftsPerDay) {
          const shiftsForDayToGroup = sortBy(shiftsForDay.filter(
            s => (s.attributes.absenceCalculation === '' || s.attributes.previsionalSaved),
          ), 'attributes.startsAt');

          shiftsCollection.push(groupShifts(shiftsForDayToGroup));
        } else {
          shiftsCollection.push(shiftsForDay);
        }

        shiftsCollection = shiftsCollection.flat();
      });
    } else if (shouldGroupDayShifts) {
      const shiftsToGroup = shiftsWithoutBadgings.filter(s => (s.attributes.absenceCalculation === '' || s.attributes.previsionalSaved));
      shiftsCollection = groupShifts(shiftsToGroup);
    } else {
      shiftsCollection = shiftsWithoutBadgings;
    }

    shiftsCollection.forEach(shiftWithoutBadging => {
      const foundUser = users.find(
        user => parseInt(user.id, 10) === shiftWithoutBadging.attributes.userId);

      if (foundUser) {
        matchedBadgings.push(new MatchedBadging(
          null,
          shiftWithoutBadging,
          badgingsHistory,
          foundUser,
          currentUser,
          currentShop,
          settings,
          isEmployeesPage,
          currentDate,
        ));
      }
    });

    const sunday = skDate(state.currentDate)
      .endOf('isoWeek')
      .startOf('day')
      .utc(true)
      .format();

    const endOfWeek = openingAndClosingTimeAt(
      currentShop.attributes.openingTime,
      currentShop.attributes.closingTime,
      sunday,
    ).closingTime;

    /* Sort on starting hour (shift or badge) then group by user */
    state.matchedBadgings = state.matchedBadgings
      .sort((mb1, mb2) => (
        mb1.shift && mb2.shift ?
          skDate(mb1.shift.attributes.startsAt) - skDate(mb2.shift.attributes.startsAt) :
          skDate(mb1.badgingStartsAt) - skDate(mb2.badgingEndsAt)
      ))
      .sort((a, b) => {
        if (a.user.attributes.userOrder === 0 && b.user.attributes.userOrder === 0) {
          if (a.user.attributes.firstName < b.user.attributes.firstName) return -1;
          if (a.user.attributes.firstName > b.user.attributes.firstName) return 1;
        }

        return a.user.attributes.userOrder - b.user.attributes.userOrder;
      })
      .filter(matchedBadging => {
        const isPlanningFeatureEnabled = store.getters['features/isFeatureEnabled'](FEATURES.FEATURE_PLANNING,
          currentShop.id, () => true);

        if (matchedBadging.selectedEndsAt || matchedBadging.selectedStartsAt ||
          (!isPlanningFeatureEnabled && matchedBadging.absencePosteId)) return true;

        const referenceDate = matchedBadging.badgingStartsAt ?
          matchedBadging.badgingStartsAt : matchedBadging.shift.attributes.startsAt;

        // Select only badges from current week
        const isCurrentWeekBadging =
          skDate(referenceDate).isSameOrBefore(endOfWeek);

        return (
          !matchedBadging.isAbsence ||
          isTurnedAsAbsence(matchedBadging) ||
          hasAbsenceBeenBadged(matchedBadging)
        ) && isCurrentWeekBadging;
      })
      .map(matchedBadging => {
        if (!matchedBadging.badging && matchedBadging.absencePosteId &&
          matchedBadging.anomalyReason !== 'shifts_turned_absence'
        ) {
          matchedBadging.badging = {
            id: `placeholder-${matchedBadging.user.id}`,
            closed_by_backend: false,
            ignored: false,
            in: '',
            locations: null,
            out: '',
            user_id: matchedBadging.user.id,
            shop_id: currentShop.id,
          };
          matchedBadging.turnedAsAbsence = true;
          matchedBadging.validated = true;
        }
        return matchedBadging;
      });

    state.originalMatchedBadgings = cloneDeep(state.matchedBadgings);

    state.originalMatchedBadgingsUserIds =
      // eslint-disable-next-line no-use-before-define
      uniq(getters.matchedBadgingsPerDay(state, undefined, store.state)()
        .map(matchedBadging => matchedBadging.userId));
    state.dayAbsencesUserIds =
      // eslint-disable-next-line no-use-before-define
      uniq(getters.dayAbsencesPerDay(state, undefined, store.state)()
        .map(shift => shift.attributes.userId));
  },
  updatePlaceholderBadgingByKey(state, { key, ...payload }) {
    const currentDate = state.currentDate;
    state.placeholderMatchedBadgings[currentDate] = state.placeholderMatchedBadgings[currentDate]
      .map(obj => ((obj.key === key) ? { ...obj, ...payload } : obj));
  },
};

const actions = {
  fetchLastUserShopPoste({ commit }, { shopId, userId }) {
    commit('performingLastUserShopPosteRequest');

    const params = { user_id: userId };

    return httpClient
      .get(`/v3/api/shops/${shopId}/postes/last_user_shop_poste`, { params })
      .then(response => {
        commit('fetchLastUserShopPosteSuccess', response.data);
      })
      .catch(error => {
        commit('fetchLastUserShopPosteError', error);
        throw error;
      })
      .finally(() => {
        commit('lastUserShopPosteRequestComplete');
      });
  },

  fetchTeams({ commit }, { shopId }) {
    return httpClient
      .get(`/v3/api/teams?shop_id=${shopId}`)
      .then(response => {
        commit('fetchTeamsSuccess', response.data);
      })
      .catch(error => {
        commit('catchTeamsError', error.data);
        throw error;
      });
  },

  updateMatchedBadging({ commit, dispatch, getters }, { componentParams }) {
    const teamIdsParams = componentParams.team_ids ? `?team_ids=${componentParams.team_ids}` : '';
    const params = {
      date: componentParams.start_date,
      shop_id: componentParams.shop_id,
      user_id: componentParams.user_id,
      team_ids: componentParams.team_ids,
      matched_badgings: [],
    };

    params.matched_badgings =
      componentParams.rows
        .filter(row => !row.isGrouped)
        .map(matchedBadging => ({
          id: matchedBadging.badgingId || '',
          ignored: matchedBadging.ignored,
          shift_id: parseInt(matchedBadging.shiftId, 10) || '',
          turned_absence: matchedBadging.turnedAsAbsence,
          skip_previsional: skipPrevisional(matchedBadging),
          poste_id: parseInt(matchedBadging.posteId || getPosteId(matchedBadging?.shift), 10),
          absence_id: matchedBadging.absencePosteId,
          choice_start: matchedBadging.selectedStartsAt || skDate(matchedBadging.startsAt).utc().format('HH:mm'),
          choice_end: matchedBadging.selectedEndsAt || skDate(matchedBadging.endsAt).utc().format('HH:mm'),
          choice_pause: matchedBadging.selectedBreak,
          nb_meal: matchedBadging.meal,
          user_id: parseInt(matchedBadging.userId, 10),
          user_name: matchedBadging.firstName + matchedBadging.lastName,
          shift_start: skDate(matchedBadging.predictedStartsAt).isValid() ? skDate(matchedBadging.predictedStartsAt).utc().format('HH:mm') : '',
          shift_end: skDate(matchedBadging.predictedEndsAt).isValid() ? skDate(matchedBadging.predictedEndsAt).utc().format('HH:mm') : '',
          shift_pause: matchedBadging.predictedBreak / 60,
          planned_as_absence: plannedAbsenceName(matchedBadging),
          day_absence: matchedBadging.isAbsence,
          absence_calculation: getAbsenceCalculation(matchedBadging),
          badging_start: skDate(matchedBadging.badgingStartsAt).isValid() ? skDate(matchedBadging.badgingStartsAt).utc().format('HH:mm') : '',
          badging_end: skDate(matchedBadging.badgingEndsAt).isValid() ? skDate(matchedBadging.badgingEndsAt).utc().format('HH:mm') : '',
          badging_pause: badgingBreaksDuration(matchedBadging),
        }));

    const shiftsToLetAsOriginal = [];

    componentParams.rows
      .filter(row => row.isGrouped)
      .forEach(matchedBadging => {
        const { shifts } = matchedBadging.shift;

        shifts.forEach(shift => {
          if (!matchedBadging.ignored && !isShiftInBadgingRange(matchedBadging, shift)) {
            shiftsToLetAsOriginal.push(shift);
          }
        });

        const shiftsToClean = shifts.filter(shift => !shiftsToLetAsOriginal.includes(shift));

        let remainingBreak = matchedBadging.selectedBreak;

        shiftsToClean
          .forEach((s, index) => {
            const choiceStart = groupedChoiceStart(s, matchedBadging, index);
            const choiceEnd = groupedChoiceEnd(s, matchedBadging, index, shiftsToClean.length);
            const choicePause = groupedChoicePause(
              remainingBreak,
              s,
              choiceStart,
              choiceEnd,
            );
            remainingBreak -= choicePause;

            params.matched_badgings.push({
              id: matchedBadging.badgingId || '',
              ignored: matchedBadging.ignored,
              shift_id: parseInt(s.id, 10) || '',
              turned_absence: matchedBadging.turnedAsAbsence,
              skip_previsional: false,
              poste_id: parseInt(getGroupedShiftPosteId(s), 10),
              absence_id: matchedBadging.absencePosteId,
              choice_start: choiceStart,
              choice_end: choiceEnd,
              choice_pause: choicePause,
              nb_meal: groupedChoiceMeal(s, matchedBadging, index, shiftsToClean),
              count_meal: groupedChoiceMeal(s, matchedBadging, index, shiftsToClean), // needed for v2 user_badgings
              user_id: parseInt(matchedBadging.userId, 10),
              user_name: matchedBadging.firstName + matchedBadging.lastName,
              shift_start: groupedShiftStart(s, index),
              shift_end: skDate(s.attributes.endsAt).isValid() ? skDate(s.attributes.endsAt).utc().format('HH:mm') : '',
              shift_pause: s.attributes.pauseTime / 60,
              planned_as_absence: plannedAbsenceName(matchedBadging),
              day_absence: matchedBadging.isAbsence,
              absence_calculation: getAbsenceCalculation(matchedBadging),
              badging_start: skDate(matchedBadging.badgingStartsAt).isValid() && matchedBadging.badgingStartsAt ? skDate(matchedBadging.badgingStartsAt).utc().format('HH:mm') : '',
              badging_end: skDate(matchedBadging.badgingEndsAt).isValid() && matchedBadging.badgingEndsAt ? skDate(matchedBadging.badgingEndsAt).utc().format('HH:mm') : '',
              badging_pause: badgingBreaksDuration(matchedBadging),
              is_grouped: true,
            });
          });

        if (remainingBreak > 0) {
          const error = new Error('The total pause of the badge is longer than the shift duration');
          Sentry.captureException(error, {
            contexts: {
              date: componentParams.start_date,
              shopId: componentParams.shop_id,
              userId: componentParams.user_id,
              teamIds: componentParams.team_ids,
            },
          });
          commit('fetchMatchedBadgingsError', error);
          throw error;
        }
      });

    if (shiftsToLetAsOriginal.length > 0) {
      shiftsToLetAsOriginal.forEach(s => {
        httpClient.patch('/v3/api/plannings/shifts', {
          shifts: [getShiftParams(s)],
          starts_at: componentParams.start_date,
          ends_at: componentParams.start_date,
          shop_id: componentParams.shop_id,
          skip_validation: ['validated_days'],
        });
      });
    }

    // Since Timeclock Standalone (positions disabled) shops cannot create/select a position in the
    // dropdown, which would assign a poste_id + clear absence_calculation, we need to force
    // poste_id to null + clear the absence, so that they are converted to a default position in
    // the backend
    if (!getters.isPositionsFeatureEnabled) {
      params.matched_badgings
        .filter(row => !row.isGrouped && !row.turned_absence)
        .forEach(matchedBadging => {
          if (!matchedBadging.turnedAsAbsence) {
            matchedBadging.poste_id = null;
            matchedBadging.absence_calculation = '';
          }
        });
    }

    return httpClient
      .patch(`${BADGINGS_ENDPOINT}/matched_badgings/bulk_update${teamIdsParams}`, params)
      .then(response => {
        const callbackParams = {
          shopId: componentParams.shop_id,
          date: componentParams.start_date,
          startDate: skDate(componentParams.start_date).startOf('isoWeek').format('YYYY-MM-DD'),
          endDate: skDate(componentParams.start_date).endOf('isoWeek').format('YYYY-MM-DD'),
        };

        dispatch('initDayBadgingData', {
          ...callbackParams,
          userId: componentParams.user_id,
          silent: true,
        });
        dispatch('validatePeriod', {
          shopId: componentParams.shop_id,
          date: componentParams.start_date,
          startDate: getters.isEmployeesView ?
            callbackParams.startDate :
            componentParams.start_date,
          endDate: getters.isEmployeesView ? callbackParams.endDate : componentParams.start_date,
        });
        commit('updateDayLocked', !getters.isEmployeesView);

        return response;
      })
      .catch(error => {
        commit('fetchMatchedBadgingsError', error);
        throw error;
      });
  },
  fetchBadgings({ commit }, { shopId, date }) {
    commit('performingBadgingsRequest');

    return httpClient
      .get(`${BADGINGS_ENDPOINT}/day_badgings?shop_id=${shopId}&date=${date}`)
      .then(response => {
        commit('fetchBadgingsSuccess', response.data);
      })
      .catch(error => {
        commit('fetchBadgingsError', error);
        throw error;
      })
      .finally(() => {
        commit('fetchBadgingsRequestComplete');
      });
  },
  fetchBadgingsHistory({ commit }, { shopId, date }) {
    commit('performingBadgingsHistoryRequest');

    return httpClient
      .get(`${BADGINGS_ENDPOINT}/day_badgings/history?shop_id=${shopId}&date=${date}`)
      .then(response => {
        commit('fetchBadgingsHistorySuccess', response.data);
      })
      .catch(error => {
        commit('fetchBadgingsHistoryError', error);
        throw error;
      })
      .finally(() => {
        commit('badgingsHistoryRequestComplete');
      });
  },
  fetchShifts({ commit }, { shopId, startDate, endDate, scope }) {
    commit('performingShiftsRequest');

    const params = new URLSearchParams({
      shop_id: shopId,
      starts_at: startDate,
      ends_at: endDate,
      subtract_start: 'true',
      subtract_end: 'true',
      scope,
    });

    return httpClient
      .get(`/v3/api/plannings/shifts?${params.toString()}`)
      .then(response => {
        commit('fetchShiftsSuccess', response.data);
        commit('filterAbsencesByTeam');
      })
      .catch(error => {
        commit('fetchShitsError', error);
        throw error;
      })
      .finally(() => {
        commit('shiftsRequestComplete');
      });
  },

  async updateShiftActivities({ commit }, { params, originalShift }) {
    const updatedShift = params.shifts[0];
    const shouldUpdateTasks = JSON.stringify(
      originalShift.attributes.tasks) !== JSON.stringify(updatedShift.attributes.tasks);
    const shouldUpdateNote = JSON.stringify(
      originalShift.attributes.note) !== JSON.stringify(updatedShift.attributes.note);

    if (shouldUpdateNote) {
      const response = await updateShifts(params);
      commit('updateShiftNoteInMatchedBadgings', response.data);
    }

    if (shouldUpdateTasks) {
      const response = await upsertTasksApiRequest({
        shiftId: updatedShift.id,
        tasks: updatedShift.attributes.tasks,
      });

      commit('updateShiftTasksInMatchedBadgings', { data: response.data, shiftId: updatedShift.id });
    }
  },

  fetchUsers({ commit }, { shopId, startDate, endDate }) {
    commit('performingUsersRequest');

    const fetchUsersParams = {
      shop_id: shopId,
      starts_at: startDate,
      ends_at: endDate,
    };

    // we're doing this in order to keep both users state split but we're using planningUsers state users
    // within app/javascript/src/v3/app/plannings/shared/components/ShiftActivities/Comments/Comment.vue
    return store.dispatch('planningsUsers/fetchPlanningUsers', { params: fetchUsersParams })
      .then(response => {
        commit('fetchUsersSuccess', response.data);
      })
      .catch(error => {
        commit('fetchUsersError', error);
        throw error;
      })
      .finally(() => {
        commit('usersRequestComplete');
      });
  },
  fetchWeeklyOptions({ commit }, { shopId, date }) {
    commit('performingDayLockedOnPlanningRequest');

    const params = { shop_id: shopId, date };
    return httpClient
      .get('/v3/api/weekly_options', { params })
      .then(response => {
        commit('fetchWeeklyOptionsSuccess', response.data);
      })
      .catch(error => {
        commit('fetchWeeklyOptionsError', error);

        throw error;
      })
      .finally(() => {
        commit('fetchWeeklyOptionsRequestComplete');
      });
  },
  fetchSettings({ commit }, { shopId }) {
    commit('performingSettingsRequest');

    return punchClient.getSetting(shopId)
      .then(response => {
        commit('fetchSettingsSuccess', response);
        return response;
      })
      .catch(error => {
        commit('fetchSettingsError', error);
        throw error;
      })
      .finally(() => {
        commit('fetchSettingsRequestComplete');
      });
  },
  initDayBadgingData({ commit, dispatch, getters }, {
    shopId,
    date,
    startDate,
    endDate,
    silent,
  }) {
    if (!silent) {
      commit('performingMatchedBadgingsRequest');
    }

    return Promise.all([
      dispatch('fetchBadgings', { shopId, date }),
      dispatch('fetchBadgingsHistory', { shopId, date }),
      dispatch('fetchSettings', { shopId }),
      dispatch('fetchShifts', {
        shopId,
        startDate,
        endDate,
        scope: 'assigned',
      }),
    ])
      .then(() => {
        commit('initDayBadgingSuccess', getters.isEmployeesView);
        if (!getters.isEmployeesView) {
          commit('filterMatchedBadgingsByTeam');
        }

        if (getters.isEmployeesView) {
          commit('updateDayLocked', getters.areAllRowsValidatedForUser);
        } else {
          commit('updateDayLocked', getters.areAllRowsValidatedForDay);
        }
      })
      .finally(() => {
        commit('setPlaceholderMatchedBadgings', getters.weeklyPlaceholders);
        commit('matchedBadgingsRequestComplete');
      });
  },
  validatePeriod({ commit }, params) {
    const validateParams = {
      shop_id: params.shopId,
      start_date: params.startDate,
      end_date: params.endDate,
      // This params indicates the weekly_option to return from backend
      current_date: params.date,
      validation_level: 'validated_days',
      validation_value: true,
    };

    return httpClient
      .post('/v3/api/weekly_options/validate_period', validateParams)
      .then(response => {
        commit('fetchWeeklyOptionsSuccess', response.data);
      });
  },
};

const getters = {
  placeholderMatchedBadgings(state, _getters) {
    return state.placeholderMatchedBadgings[state.currentDate] || [];
  },
  weeklyPlaceholders(state, localGetters, rootState) {
    if (!state.currentDate) return [];

    const { currentUser } = store.state;
    const { currentShop } = rootState.currentShop;
    const teams = state.teams || [];
    const filteredTeamsUserIds = teams
      .filter(team => state.selectedTeamIds.includes(team.id))
      .flatMap(team => team.relationships.users)
      .map(user => user.id);

    const result = {};

    for (let i = 0; i < 7; i++) {
      const date = skDate(state.currentDate).startOf('isoWeek').add(i, 'day').format('YYYY-MM-DD');
      const employeesIdsOnAbsence =
      localGetters.dayAbsencesPerDay(date).map(shift => String(shift.attributes.userId));
      const unbadgedEmployees =
      state.users
        .filter(user => !localGetters.matchedBadgingsPerDay(date)
          .some(matchedBadging => parseInt(user.id, 10) === matchedBadging.userId))
        .filter(user => !state.selectedTeamIds.length || filteredTeamsUserIds.includes(user.id))
        .filter(user => !employeesIdsOnAbsence.includes(user.id));
      const unbadgedEmployeesForDay = [...unbadgedEmployees]; // clone the array

      const dayResult = unbadgedEmployeesForDay.map(user => {
        const badging = {
          id: `placeholder-${user.id}-${date.toString()}`,
          closed_by_backend: false,
          ignored: false,
          in: '',
          locations: null,
          out: '',
          user_id: user.id,
          shop_id: currentShop.id,
        };

        const matchedBadging = new MatchedBadging(
          badging,
          null,
          state.badgingsHistory,
          user,
          currentUser,
          currentShop,
          state.settings,
          false,
          date,
        );

        return matchedBadging;
      });

      result[date] = dayResult;
    }

    return result;
  },
  isEmployeesView: (_state, _getters, rootState) => rootState.route.name === 'badgings_shifts_users' || rootState.route.name === 'badgings_absences_users',
  isDaysView: (_state, _getters, rootState) => rootState.route.name === 'badgings_shifts_days' || rootState.route.name === 'badgings_absences_days',
  isPositionsFeatureEnabled(_state, _getters, rootState, rootGetters) {
    return rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_EMPLOYEE_POSITIONS,
      rootState.currentShop.currentShop.id, () => true);
  },
  pageMatchedBadgings(state, localGetters) {
    return localGetters.isEmployeesView ?
      localGetters.matchedBadgingsPerUser() :
      localGetters.matchedBadgingsPerDay();
  },
  matchedBadgingsPerUser(state, _getters, rootState) {
    return (userId = rootState.route.params.user_id) => {
      function byUserId() {
        return matchedBadging => (
          String(matchedBadging.user.id) === String(userId)
        );
      }
      function byShiftDate(matchedBadgingA, matchedBadgingB) {
        const shiftDateA = matchedBadgingA.shift ?
          skDate(matchedBadgingA.shift.attributes.startsAt) :
          skDate(matchedBadgingA.badging.in);
        const shiftDateB = matchedBadgingB.shift ?
          skDate(matchedBadgingB.shift.attributes.startsAt) :
          skDate(matchedBadgingB.badging.in);
        return shiftDateA - shiftDateB;
      }
      const sunday = skDate(state.currentDate)
        .endOf('isoWeek')
        .startOf('day')
        .utc(true)
        .format();
      const endOfWeek = openingAndClosingTimeAt(
        rootState.currentShop.currentShop.attributes.openingTime,
        rootState.currentShop.currentShop.attributes.closingTime,
        sunday,
      ).closingTime;

      return state.matchedBadgings
        .filter(byUserId())
        .filter(matchedBadging => {
          const referenceDate =
            matchedBadging.badgingStartsAt || matchedBadging.shift.attributes.startsAt;

          // Select only badges from current week
          return skDate(referenceDate).isBefore(endOfWeek);
        })
        .sort(byShiftDate);
    };
  },
  dayAbsencesPerUser(state, _getters, rootState) {
    return state.dayAbsences.filter(
      absence => String(absence.attributes.userId) === String(rootState.route.params.user_id),
    );
  },
  matchedBadgingsPerDay(state, _getters, rootState) {
    return (date = state.currentDate) => {
    // Please, if you modify this, please consider also modify app/javascript/src/v3/app/badgings/shared/components/WeekSidebar/DayItem.vue:69
      if (!date) return [];

      const { currentShop } = rootState.currentShop;
      // If shop starts at midnight, we change currentDate to be one day before to handle
      // product spec were you can badge 30 mins before opening time

      const formattedDate = currentShop.attributes.openingTime === '00:00' ?
        skDate(date).subtract(1, 'd').utc(true).format() :
        skDate(date).utc(true).format();

      const shopOpeningTime = skDate(currentShop.attributes.openingTime, 'HH:mm').subtract(
        BADGING_BUFFER_BEFORE_OPENING, 'm',
      ).format('HH:mm');

      const shopClosingTime =
      currentShop.attributes.openingTime === currentShop.attributes.closingTime ?
        skDate(currentShop.attributes.closingTime, 'HH:mm').subtract(
          BADGING_BUFFER_BEFORE_OPENING, 'm',
        ).format('HH:mm') :
        currentShop.attributes.closingTime;

      const { openingTime, closingTime } = openingAndClosingTimeAt(
        shopOpeningTime,
        shopClosingTime,
        formattedDate,
      );

      // Using the same logic as planning to figure out on which day a matchedBadging belongs
      // See setShiftsStartsAtForDisplay in shifts.js store
      return state.matchedBadgings.filter(matchedBadging => {
        const startsAt = matchedBadging.shift ?
          skDate(matchedBadging.shift.attributes.previsionalStart ||
          matchedBadging.shift.attributes.startsAt).utc() :
          skDate(matchedBadging.badging.in).utc();

        return startsAt >= openingTime && startsAt < closingTime;
      });
    };
  },
  dayAbsencesPerDay(state, _getters, rootState) {
    return (date = state.currentDate) => {
      if (!date) return [];

      const { currentShop } = rootState.currentShop;
      const formattedDate = skDate(date).utc(true).format();
      const { openingTime, closingTime } = openingAndClosingTimeAt(
        currentShop.attributes.openingTime,
        currentShop.attributes.closingTime,
        formattedDate,
      );

      return state.dayAbsences.filter(shift => {
        const startsAt = skDate(shift.attributes.startsAt).utc();
        const previsionalStart = skDate(shift.attributes.previsionalStart).utc();
        return (startsAt >= openingTime && startsAt < closingTime) ||
        (previsionalStart >= openingTime && previsionalStart < closingTime);
      });
    };
  },
  atLeastOneGroupedBadging(state, localGetters) {
    return localGetters.pageMatchedBadgings.filter(matchedBadging => (
      matchedBadging.isGrouped
    )).length !== 0;
  },
  matchedBadgingMissingHoursField(state, localGetters) {
    return localGetters.pageMatchedBadgings.filter(mB => !mB.ignored).filter(
      matchedBadging => (!matchedBadging.selectedStartsAt ||
        !matchedBadging.selectedEndsAt) && !matchedBadging.isAbsence,
    );
  },
  matchedBadgingMissingBreakField(state, localGetters) {
    return localGetters.pageMatchedBadgings.filter(mB => !mB.ignored).filter(
      matchedBadging => !matchedBadging.selectedBreak && matchedBadging.selectedBreak !== 0,
    );
  },
  matchedBadgingBreakInvalid(state, localGetters) {
    return localGetters.pageMatchedBadgings.filter(mB => !mB.ignored).filter(
      // 720 Max pause time
      matchedBadging => matchedBadging.selectedBreak > 720,
    );
  },
  matchedBadgingBreakNegative(state, localGetters) {
    return localGetters.pageMatchedBadgings.filter(mB => !mB.ignored).filter(
      matchedBadging => matchedBadging.selectedBreak < 0,
    );
  },
  matchedBadgingBreakLongerThanShift(state, localGetters) {
    return localGetters.pageMatchedBadgings.filter(mB => !mB.ignored).filter(
      matchedBadging => {
        let shiftDuration = skDate(matchedBadging.selectedEndsAt, 'LT').diff(skDate(matchedBadging.selectedStartsAt, 'LT'), 'minutes');
        if (shiftDuration <= 0) {
          shiftDuration += 1440; // Add 24h in minutes if the shift is on two days
        }
        return matchedBadging.selectedBreak > shiftDuration;
      },
    );
  },
  matchedBadgingPosteNotSet(state, localGetters) {
    return localGetters.pageMatchedBadgings.filter(mB => !mB.ignored).filter(
      matchedBadging => {
        if (matchedBadging.turnedAsAbsence) {
          return matchedBadging.validated ?
            !matchedBadging.absencePosteName :
            !matchedBadging.turnedAbsenceName;
        }

        return !matchedBadging.posteId && !matchedBadging.isGrouped;
      },
    );
  },
  matchedBadgingRemuneratedEndBeforeStart(state, localGetters) {
    return localGetters.pageMatchedBadgings
      .filter(mB => mB.selectedStartsAt && mB.selectedEndsAt &&
        mB.selectedStartsAt !== mB.selectedEndsAt,
      )
      .find(mB => skDate(mB.selectedEndsAt, 'LT').isBefore(skDate(mB.selectedStartsAt, 'LT')));
  },
  erroredFields(state, localGetters) {
    return localGetters.matchedBadgingBreakLongerThanShift
      .concat(localGetters.matchedBadgingBreakNegative)
      .concat(localGetters.matchedBadgingBreakInvalid)
      .flat();
  },
  missingMandatoryFields(state, localGetters, rootState, rootGetters) {
    if (localGetters.isPositionsFeatureEnabled) {
      return localGetters.matchedBadgingPosteNotSet
        .concat(localGetters.matchedBadgingMissingHoursField)
        .concat(localGetters.matchedBadgingMissingBreakField)
        .flat();
    }

    return localGetters.matchedBadgingMissingHoursField
      .concat(localGetters.matchedBadgingMissingBreakField)
      .flat();
  },
  // eslint-disable-next-line no-shadow
  completedRows(state, getters) {
    const missingHoursKeys = getters.matchedBadgingMissingHoursField.map(mB => mB.key);
    const missingPosteKeys = getters.matchedBadgingPosteNotSet.map(mB => mB.key);
    const missingBreakKeys = getters.matchedBadgingMissingBreakField.map(mB => mB.key);

    const unCompletedRowsKeys =
      missingHoursKeys.concat(missingPosteKeys).concat(missingBreakKeys).flat();

    return state.matchedBadgings.filter(mB => !unCompletedRowsKeys.includes(mB.key));
  },
  areAllRowsValidatedForUser(_state, localGetters) {
    if (localGetters.isDaysView) return false;

    return localGetters.matchedBadgingsPerUser().every(mB => mB.validated);
  },
  areAllRowsValidatedForDay(_state, localGetters) {
    if (localGetters.isEmployeesView) return false;

    return localGetters.matchedBadgingsPerDay().every(mB => mB.validated);
  },
  unsavedChangesToMatchedBadging(state) {
    const matchedBadgings = sortBy(state.matchedBadgings, 'key');
    const originalMatchedBadgings = sortBy(state.originalMatchedBadgings, 'key');

    return JSON.stringify(matchedBadgings) !== JSON.stringify(originalMatchedBadgings);
  },
  currentDayLockedOnPlanning(state) {
    if (!state.weeklyOptionsLocks.intermediateLockedDays) return true;
    if (!state.weeklyOptionsLocks.permanentLockedDays) return true;

    const currentDayIndex = skDate(state.currentDate).isoWeekday() - 1;
    return state.weeklyOptionsLocks.intermediateLockedDays[currentDayIndex] ||
      state.weeklyOptionsLocks.permanentLockedDays[currentDayIndex];
  },
  currentWeekLockedOnPlanning(state) {
    if (!state.weeklyOptionsLocks.validatedDays) return true;

    return state.weeklyOptionsLocks.intermediateLockedDays.every(day => !!day) ||
      state.weeklyOptionsLocks.permanentLockedDays.every(day => !!day);
  },
  anyLockOnCurrentDay(state, localGetters) {
    if (!state.weeklyOptionsLocks.validatedDays) return true;

    const currentDayIndex = skDate(state.currentDate).day() - 1;

    return state.weeklyOptionsLocks.validatedDays[currentDayIndex] ||
      localGetters.currentDayLockedOnPlanning;
  },
};
export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
