import axios from 'axios';
import Cookies from 'js-cookie';
import {
  AuthWebClient,
  TokenWebModel,
} from '@skelloapp/skello-auth-client';
import store from '@app-js/shared/store/index';
import {
  cookieDomain, svcUsersUrl,
} from '@config/env';
import {
  BaseApiConfig,
  KEY_AUTH_ACCESS_TOKEN,
  KEY_AUTH_REFRESH_TOKEN,
  KEY_AUTH_ADMIN_REFRESH_TOKEN,
  KEY_AUTH_IMPERSONATE_USER_ID,
  KEY_SIGNING_OUT,
} from './constant_client';

class AuthClient {
  constructor() {
    this.svcUsersUrl = svcUsersUrl;

    this.authClient = new AuthWebClient({
      headers: BaseApiConfig?.headers,
      baseUrl: '',
    });

    this.useAuthMiddleware = this.svcUsersUrl && document.querySelector('[name=SVC_USERS_MIDDLEWARE]')?.content === 'true';
    this.isSvcUserFFActive = document.querySelector('[name=FEATUREDEV_SVC_USERS]')?.content === 'true';

    this.isSvcUserFFEnabled = this.useAuthMiddleware && this.isSvcUserFFActive;

    this.forceAuthMiddlewareForAllUsers =
      this.useAuthMiddleware &&
      document.querySelector('[name=FORCE_SVC_USER]')?.content === 'true';

    this.useSvcUserForQa =
      document.querySelector('[name=FEATUREDEV_SVC_USERS_QA]')?.content === 'true';

    if (this.isSvcUserFFEnabled || this.forceAuthMiddlewareForAllUsers) {
      this.svcUserAuthClient = new AuthWebClient({
        headers: BaseApiConfig?.headers,
        baseURL: this.svcUsersUrl,
      });
    }

    this.cookieDomain = cookieDomain;

    this.getAuthTokenFromStorage();
  }

  async login({ email, password, rememberMe }) {
    Cookies.remove(KEY_SIGNING_OUT);

    let data;
    if (this.useSvcUserAuthClient(email)) {
      data = await this.svcUserAuthClient.login({ email, password, rememberMe });
    } else {
      data = await this.authClient.login({ email, password, rememberMe });
    }

    return this.setAuthToken(data);
  }

  async getAuthToken() {
    this.getAuthTokenFromStorage();

    if (!this.authToken) {
      return null;
    }

    await this.refreshToken();

    if (!this.authToken) {
      throw new Error('Token is empty.');
    }

    return this.authToken;
  }

  async refreshToken() {
    if (this.authToken.refreshTokenIsExpired()) {
      await this.logOut();
    }

    if (this.authToken.tokenIsExpired()) {
      let data;

      if (this.useSvcUserAuthClient()) {
        data = await this.svcUserAuthClient.refreshToken({
          refreshToken: this.authToken.refreshToken,
        });
      } else {
        data = await this.authClient.refreshToken({
          refreshToken: this.authToken.refreshToken,
        });
      }

      this.setAuthToken(data);
    } else if (!store.getters['currentUserLicenses/isCurrentUserLicensesInitialized']) {
      // initialize the currentUserLicenses store at first launch
      store.commit('currentUserLicenses/setCurrentUserLicenses', this.authToken.user.licenses);
    }

    return this.authToken;
  }

  async forceRefreshToken() {
    let data;

    if (this.useSvcUserAuthClient()) {
      data = await this.svcUserAuthClient.refreshToken({
        refreshToken: this.authToken.refreshToken,
      });
    } else {
      data = await this.authClient.refreshToken({
        refreshToken: this.authToken.refreshToken,
      });
    }

    this.setAuthToken(data);
  }

  setAuthToken(data) {
    this.authToken = data;
    store.commit('currentUserLicenses/setCurrentUserLicenses', this.authToken.user.licenses);

    localStorage.setItem(KEY_AUTH_ACCESS_TOKEN, this.authToken.token);
    localStorage.setItem(KEY_AUTH_REFRESH_TOKEN, this.authToken.refreshToken);

    Cookies.set(
      KEY_AUTH_REFRESH_TOKEN,
      this.authToken.refreshToken,
      {
        expires: new Date(data.refreshExp * 1000),
      },
    );

    // When you impersonate, you want to keep the super_admin refresh token to come
    // back on super admin
    if (!data.tokenData.impersonating) {
      Cookies.set(
        KEY_AUTH_ADMIN_REFRESH_TOKEN,
        this.authToken.refreshToken,
        {
          domain: this.cookieDomain,
          expires: new Date(data.refreshExp * 1000),
        },
      );
    }

    return this.authToken;
  }

  deleteAuthToken() {
    localStorage.removeItem(KEY_AUTH_ACCESS_TOKEN);
    localStorage.removeItem(KEY_AUTH_REFRESH_TOKEN);

    Cookies.remove(KEY_AUTH_IMPERSONATE_USER_ID);
    Cookies.remove(KEY_AUTH_REFRESH_TOKEN);
  }

  async logOut() {
    const response = await axios.delete('/v3/users/sign_out');

    this.destroySession();
    window.location = response.data.redirect_url;
  }

  useSvcUserAuthClient(email) {
    if (this.forceAuthMiddlewareForAllUsers) {
      return true;
    }

    if (!this.isSvcUserFFEnabled || this.svcUserAuthClient === undefined) return false;

    let subEmail = email;

    if (!subEmail) {
      this.getAuthTokenFromStorage();
      subEmail = this.authToken?.tokenData?.data?.email;
    }
    // match whatever+jwt-alias10k@skello.io
    const msUsersEmails = /^.+\+jwt(-[a-z\d\-_]*)*@skello\.io$/gi;
    // match qa-team+whatever@skello.io
    const qaTeamEmails = /^qa-team(\+.*)*@skello\.io$/gi;

    return msUsersEmails.test(subEmail) || (qaTeamEmails.test(subEmail) && this.useSvcUserForQa);
  }

  destroySession() {
    this.deleteAuthToken();
    Cookies.set(KEY_SIGNING_OUT, true);
    Cookies.remove(KEY_AUTH_ADMIN_REFRESH_TOKEN, { domain: this.cookieDomain });

    store.commit('currentUserLicenses/clearCurrentUserLicenses');
  }

  async logOutImpersonate() {
    this.deleteAuthToken();
    const superAdminRefreshToken = Cookies.get(
      KEY_AUTH_ADMIN_REFRESH_TOKEN,
      {
        domain: this.cookieDomain,
      },
    );

    if (!superAdminRefreshToken) {
      this.logOut();
    } else {
      let superAdminTokens;

      if (this.useSvcUserAuthClient()) {
        superAdminTokens = await this.svcUserAuthClient.refreshToken({
          refreshToken: superAdminRefreshToken,
        });
      } else {
        superAdminTokens = await this.authClient.refreshToken({
          refreshToken: superAdminRefreshToken,
        });
      }

      this.setAuthToken(superAdminTokens);

      window.location = '/corporate/stop_impersonating';
    }
  }

  getAuthTokenFromStorage() {
    if (!this.authToken) {
      const token = localStorage.getItem(KEY_AUTH_ACCESS_TOKEN);
      const refreshToken = localStorage.getItem(KEY_AUTH_REFRESH_TOKEN);

      if (token && refreshToken) {
        this.authToken = new TokenWebModel({ token, refreshToken });
      }
    }
  }
}

export const authClient = new AuthClient();
