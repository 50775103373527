export const FEATURES = {
  FEATURE_AVAILABILITIES: 'availabilities',
  FEATURE_NEW_BUNDLE_SYSTEM: 'new_bundle_system',
  FEATURE_ALERTS: 'alerts',
  FEATURE_PLANNING: 'planning',
  FEATURE_EMPLOYEES: 'employees',
  FEATURE_EMPLOYEE_POSITIONS: 'employee_positions',
  FEATURE_TIMECLOCK: 'timeclock',
  FEATURE_REPORT: 'report',
  FEATURE_PROFILE: 'profile',
  FEATURE_ANALYTICS: 'analytics',
  FEATURE_DASHBOARD: 'dashboard',
  FEATURE_USER_REQUESTS: 'user_requests',
  FEATURE_ABSENCES: 'absences',
  FEATURE_KPIS: 'kpis',
  FEATURE_ADMIN_DOCUMENTS_TEMPLATES: 'admin_documents_templates',
  FEATURE_SHIFTS: 'shifts',
  FEATURE_NOTIFICATIONS: 'notifications',
  FEATURE_BREAKS: 'breaks',
  FEATURE_COLLECTIVE_AGREEMENT: 'collective_agreement',
  FEATURE_PAYROLL: 'payroll',
  FEATURE_MEALS: 'meals',
  FEATURE_HISTORY: 'history',
  FEATURE_TRACKERS: 'trackers',
  FEATURE_SMART_PLANNER: 'smart_planner',
  FEATURE_PARTNER_TOOLS: 'partner_tools',
  FEATURE_EMPLOYEES_CREATE_DOCUMENT: 'employees_create_document',
  FEATURE_PTO: 'pto',
  FEATURE_EMPLOYEE_TEAMS_ROTATIONS: 'employee_teams_rotations',
  FEATURE_ELECTRONIC_SIGNATURE: 'electronic_signature',
  FEATURE_FORTIFY_SILAE: 'fortify_silae',
  FEATURE_PAYSLIPS: 'payslips',
};
