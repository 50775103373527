export const ANNUALIZATION_SHIFT_FIELDS = [
  'absence_calculation',
  'duration_in_seconds',
  'pause_time',
  'starts_at',
  'ends_at',
  'poste_id',
  'user_id',
];

export const ABSENCE_POSTE_FIELDS = ['id', 'absence_key', 'absence_flags'];
