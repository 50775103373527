import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatShopAnnualizationTrackerEnabledContext = store => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
  },
});

export const formatShopAnnualizationTrackerEnabled =
  async store => [formatShopAnnualizationTrackerEnabledContext(store)];
