export default {
  PERMANENT: 'permanent',
  FIXED_TERM: 'fixed_term',
  TEMPORARY: 'temporary',
  INTERIM: 'interim',
  EXTRA: 'extra',
  REPLACEMENT: 'replacement',
  APPRENTICESHIP: 'apprenticeship',
  FLEXI_JOB: 'flexi_job',
  STUDENT: 'student',
  INTERNSHIP: 'internship',
};
