import * as userModule from '@skello-store/modules/current-user';
import * as licenseModule from '@skello-store/modules/current-license';
import * as configModule from '@skello-store/modules/config';
import * as shopModule from '@skello-store/modules/current-shop';
import * as organisationModule from '@skello-store/modules/current-organisation';
import * as planningUsersModule from '@skello-store/modules/plannings/users';

export const injectStore = () => ({
  currentUser: userModule.default.state.currentUser,
  currentLicense: licenseModule.default.state.currentLicense,
  currentShop: shopModule.default.state.currentShop,
  currentOrganisation: organisationModule.default.state.currentOrganisation,
  config: configModule.default.state.config,
  employees: planningUsersModule.default.state.users,
});
