import fr from './requests.fr.yml';
import en from './requests.en.yml';
import es from './requests.es.yml';
import de from './requests.de.yml';
import it from './requests.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
