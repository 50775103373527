import {
  formatEmployees, fullName,
} from '../../utils';
import { formatEvent } from '../../format_event';
import { formatWeekPlanningContext as formatDayPlanningContext } from './week_planning';

const formatDeleteBulkDayPlanningShiftContext = (store, data) => {
  const employees = formatEmployees(store.employees);

  return {
    ...formatEvent(store),
    context: {
      user_source_name: fullName(store.currentUser),
      user_target_ids: data.shifts.map(shift => shift.attributes.userId),
      user_target_names: data.shifts.map(shift => fullName(employees[shift.attributes.userId])),
      date: (new Date(data.starts_at)).getTime(),
      shop_name: store.currentShop.attributes.name,
      shop_id: data.shifts[0].attributes.shopId.toString(),
      shift_ids: data.shifts.map(shift => shift.id),
    },
  };
};

const formatDayPlanning =
  async (store, data) => data.map(shift => formatDayPlanningContext(store, shift));

export const createDayPlanningShift = formatDayPlanning;

export const updateDayPlanningShift = formatDayPlanning;

export const deleteDayPlanningShift = formatDayPlanning;

export const createDayPlanningAbsence = formatDayPlanning;

export const updateDayPlanningAbsence = formatDayPlanning;

export const deleteDayPlanningAbsence = formatDayPlanning;

export const deleteBulkDayPlanningShift =
  async (store, data) => [formatDeleteBulkDayPlanningShiftContext(store, data)];
