import { httpClient } from '@skello-utils/clients';

const initialState = {
  selectedCounter: 'hours_worked',
  draggedShift: null,
  usingDraggedShiftId: null,
  bulkPaidLeaveCounters: {},
};

const mutations = {
  setSelectedCounter(state, counter) {
    state.selectedCounter = counter;
  },
  setDraggingShift(state, shift) {
    state.draggedShift = shift;
  },
  setUsingDraggedShiftId(state, id) {
    state.usingDraggedShiftId = id;
  },
  setBulkPaidLeaveCounters(state, { counters, overwriteStore = true }) {
    state.bulkPaidLeaveCounters = overwriteStore ?
      counters : { ...state.bulkPaidLeaveCounters, ...counters };
  },
};

const actions = {
  fetchBulkPaidLeavesCounters({ state, commit }, { params, overwriteStore = true }) {
    const validParams = {
      shop_id: params.shopId,
      user_ids: params.userIds,
      date: params.date,
    };

    return httpClient
      .post('/v3/api/paid_leaves_counters/bulk_get_plc_by_month', validParams)
      .then(response => {
        commit('setBulkPaidLeaveCounters', { counters: response.data, overwriteStore });
      });
  },
};

const gettersList = {};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersList,
};
