import { svcPunchUrl } from '@config/env';
import { FactoryPunchWeb } from '@skelloapp/svc-punch-js';
import { authClient } from './auth_client';

class PunchClient {
  constructor() {
    const baseURL = svcPunchUrl;

    this.factory = FactoryPunchWeb.create(baseURL, {
      clientSource: 'skelloApp:web',
    });
  }

  async getSetting(shopId) {
    const { token } = await authClient.getAuthToken();

    return this.factory.setting.findOneByShopId(shopId, token);
  }

  async updateSetting(shopId, params) {
    const { token } = await authClient.getAuthToken();

    return this.factory.setting.updateByShopId(shopId, params, token);
  }

  async partialUpdateSetting(shopId, params) {
    const { token } = await authClient.getAuthToken();

    return this.factory.setting.partialUpdateByShopId(shopId, params, token);
  }

  async getUsers(shopId) {
    const { token } = await authClient.getAuthToken();

    return this.factory.users.findAllByShopId(shopId, token);
  }
}

export const punchClient = new PunchClient();

export default punchClient;
