import fr from './home_dashboard.fr.yml';
import en from './home_dashboard.en.yml';
import es from './home_dashboard.es.yml';
import de from './home_dashboard.de.yml';
import it from './home_dashboard.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
