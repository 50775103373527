import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatEmployeeDocumentDeletedContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    user_target_id: data.employee.id.toString(),
    user_target_name: fullName(data.employee),
    document_id: data.documentId,
    document_name: data.documentName,
  },
});

export const formatEmployeeDocumentDeleted =
  async (store, data) => [formatEmployeeDocumentDeletedContext(store, data)];
