import fr from './analytics_dashboard.fr.yml';
import en from './analytics_dashboard.en.yml';
import de from './analytics_dashboard.de.yml';
import es from './analytics_dashboard.es.yml';
import it from './analytics_dashboard.it.yml';

export default {
  ...fr,
  ...en,
  ...de,
  ...es,
  ...it,
};
