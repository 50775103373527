import sortBy from 'lodash/sortBy';

export const sortCollectionBy = (collection, sortKey, callback) => {
  if (callback) {
    return sortBy(collection, model => model.attributes[sortKey][callback]());
  }

  return sortBy(collection, model => model.attributes[sortKey]);
};

export const uniq = collection => [...new Set(collection.map(JSON.stringify))].map(JSON.parse);
