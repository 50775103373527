/*
/**
 * Planning Data Status Lifecycle:
 *
 * 1. NOT_LOADED: Initial state, no data has been fetched yet.
 * 2. GLOBAL_DATA_LOADED: Essential, non-batchable data has been loaded.
 * 3. FIRST_BATCH_LOADED: The first batch of data has been successfully loaded.
 * 4. LOADING_BATCHES: Subsequent batches of data are being loaded progressively.
 * 5. ALL_LOADED: All data, including all batches, has been completely loaded.
 *
 * This lifecycle represents the progressive loading strategy for planning data,
 * allowing for improved performance and user experience in data-heavy scenarios.
 */
export const PLANNING_DATA_STATUS = Object.freeze({
  NOT_LOADED: 'not_loaded',
  GLOBAL_DATA_LOADED: 'global_data_loaded',
  FIRST_BATCH_LOADED: 'first_batch_loaded',
  LOADING_BATCHES: 'loading_batches',
  ALL_LOADED: 'all_loaded',
});
