export const KEY_AUTH_ACCESS_TOKEN = 'SkelloApp_AccessToken';
export const KEY_AUTH_REFRESH_TOKEN = 'SkelloApp_RefreshToken';
export const KEY_AUTH_ADMIN_REFRESH_TOKEN = 'SkelloApp_SkelloUser_RefreshToken';
export const KEY_AUTH_IMPERSONATE_USER_ID = 'SkelloApp_ImpersonateUserId';
export const KEY_SIGNING_OUT = 'signing_out';

export const BaseApiConfig = {
  baseURL: '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Source-Client': 'skelloApp:web',
  },
};
