import { httpClient } from '@skello-utils/clients';

const initialState = {
  notificationsCount: 0,
  error: false,
  loading: false,
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  fetchNotificationsSuccess(state, payload) {
    state.notificationsCount = payload;
  },

  fetchNotificationsError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      commit('performingRequest');

      httpClient
        .get('/v3/api/notifications/count')
        .then(response => {
          commit('fetchNotificationsSuccess', response.data.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('fetchNotificationsError', response.data);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
