import fr from './organisation_settings.fr.yml';
import en from './organisation_settings.en.yml';
import es from './organisation_settings.es.yml';
import de from './organisation_settings.de.yml';
import it from './organisation_settings.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
