import fr from './shop_settings.fr.yml';
import en from './shop_settings.en.yml';
import es from './shop_settings.es.yml';
import de from './shop_settings.de.yml';
import it from './shop_settings.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
