import {
  FactoryEvent, SOURCE,
} from '@skelloapp/svc-events-client';
import { authClient } from '@skello-utils/clients/auth_client';

import { injectStore } from './store';
import { MAP_CREATE_SUBTYPES } from './constant';

class SvcEventsClient {
  constructor(svcEventsUrl) {
    if (svcEventsUrl === null) {
      throw new Error('Couln\'t initialize svcEventsClient');
    }

    this.svcEvents = FactoryEvent.create(svcEventsUrl, SOURCE.SAAS_FRONT, { retries: 0 });
  }

  async create(subtype, data) {
    const authorization = await this.getAuthorization();
    const payload = await MAP_CREATE_SUBTYPES[subtype](injectStore(), data);

    await this.svcEvents.event.create(subtype, payload, authorization);
  }

  async getEventsForShop(id, params) {
    const authorization = await this.getAuthorization();

    return this.svcEvents.event.findAllByShopId(id, { ...authorization, ...params });
  }

  async getEventsForClusterNode(id, params) {
    const authorization = await this.getAuthorization();

    return this.svcEvents.event.findAllByClusterNodeId(id, { ...authorization, ...params });
  }

  async getAuthorization() {
    const jwt = (await authClient.getAuthToken()).token;

    return { authorization: { jwt } };
  }
}

export default SvcEventsClient;
