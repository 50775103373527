import fr from './employees.fr.yml';
import en from './employees.en.yml';
import es from './employees.es.yml';
import de from './employees.de.yml';
import it from './employees.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
