import skDate from '@skello-utils/dates';

export const BRAIN_API_PROVENANCE_LABEL = 'brain_api';

export const isOverlapingShift = ({
  shiftStartsAtSkDate,
  shiftEndsAtSkDate,
  anotherShiftStartsAtSkDate,
  anotherShiftEndsAtSkDate,
}) => {
  const haveSameStart = shiftStartsAtSkDate.isSame(anotherShiftStartsAtSkDate);
  const haveSameEnd = shiftEndsAtSkDate.isSame(anotherShiftEndsAtSkDate);
  const shiftStartIsInside = shiftStartsAtSkDate.isBetween(
    anotherShiftStartsAtSkDate,
    anotherShiftEndsAtSkDate,
  );
  const shiftEndIsInside = shiftEndsAtSkDate.isBetween(
    anotherShiftStartsAtSkDate,
    anotherShiftEndsAtSkDate,
  );

  return (
    (haveSameStart && haveSameEnd) ||
    shiftStartIsInside ||
    shiftEndIsInside ||
    haveSameStart ||
    haveSameEnd
  );
};

export const brainApiDataToPlanningShift = ({
  rawShiftFromApi,
  currentShop,
  postes,
  planningsUsers,
}) => {
  const smartPlannerPredictedShifts = rawShiftFromApi
    .reduce((acc, current) => acc.concat(current.shifts), []);

  return smartPlannerPredictedShifts.reduce((planningShifts, current) => {
    const foundPoste = postes.find(p => p.id === current.poste_id);

    if (!foundPoste) {
      return planningShifts;
    }

    const shopId = parseInt(currentShop.id, 10);
    const userId = current.user_id;

    const predictedShiftFormated = {
      attributes: {
        startsAt: current.starts_at,
        endsAt: current.ends_at,
        note: null,
        nbMeal: 0,
        pauseTime: 0,
        dayAbsence: false,
        previsionalStart: null,
        previsionalEnd: null,
        previsionalSaved: false,
        previsionalPosteId: null,
        absenceCalculation: '',
        hoursWorth: 0,
        delay: 0,
        showStartTime: true,
        showEndTime: true,
        showDuration: true,
        color: foundPoste.attributes.color,
        shopName: currentShop.attributes.name,
        durationInSeconds: current.duration_in_seconds,
        inHoursCounter: true,
        shopOpeningTime: currentShop.attributes.openingTime,
        shopClosingTime: currentShop.attributes.closingTime,
        provenance: BRAIN_API_PROVENANCE_LABEL,
        userId,
        shopId,
      },
      relationships: {
        poste: foundPoste,
        previsionalPoste: null,
        shop: {
          data: {
            id: shopId,
            type: 'shop',
          },
        },
        user: {
          data: {
            id: userId,
            type: 'user',
          },
        },
      },
      id: null,
      type: 'shift',
    };

    planningShifts.push(predictedShiftFormated);

    return planningShifts;
  }, []);
};
