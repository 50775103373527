function mergeObjects(obj1, obj2) {
  for (const key in obj1) {
    if (key && obj2) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        obj1[key] = obj1[key].concat(obj2[key]);
      } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        obj1[key] = mergeObjects(obj1[key], obj2[key]);
      } else {
        // Round Kpis to 2 decimals
        obj1[key] = Math.round((obj1[key] + obj2[key] + Number.EPSILON) * 100) / 100;
      }
    }
  }

  return obj1;
}

export const concatenateObjects = objects => {
  if (!objects || objects.length === 0) {
    throw new Error('No objects to concatena');
  }

  if (objects.length < 2) {
    return objects[0];
  }

  let result = { ...objects[0] };

  for (let i = 1; i < objects.length; i++) {
    const current = objects[i];
    result = mergeObjects(result, current);
  }

  return result;
};
