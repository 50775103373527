import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import { httpClient } from '@skello-utils/clients';

const initialState = {
  competencies: [],
  originalCompetencies: [],
  automaticPlanningRules: [],
  originalAutomaticPlanningRules: [],
  isLoadingRulesFetch: true,
  isLoadingRulesUpdate: false,
  isLoadingCompetenciesUpdate: false,
  isLoadingCompetenciesFetch: true,
};

const findRule =
  ({ state, ruleId }) => state.automaticPlanningRules.find(rule => rule.id === ruleId);

const mutations = {
  performingRequest(state, key = 'loading') {
    state[key] = true;
  },

  requestComplete(state, key = 'loading') {
    state[key] = false;
  },

  fetchRulesSuccess(state, payload) {
    state.automaticPlanningRules = payload.data;
    state.originalAutomaticPlanningRules = cloneDeep(state.automaticPlanningRules);
  },

  fetchCompetenciesSuccess(state, payload) {
    const competencies = payload.data.map(({ attributes }) => ({
      userId: attributes.userId.toString(),
      posteId: attributes.posteId.toString(),
      primary: attributes.primary,
    }));
    state.competencies = competencies;
    state.originalCompetencies = cloneDeep(competencies);
  },

  ruleToggled(state, rule) {
    rule.attributes.active = !rule.attributes.active;
  },

  optionalValueToggled(state, rule) {
    rule.attributes.optionalValue = !rule.attributes.optionalValue;
  },

  ruleValueChanged(state, { ruleId, newValue }) {
    const rule = findRule({ ruleId, state });
    rule.attributes.value = newValue;
  },

  ruleOptionalValueChanged(state, { ruleId, newValue }) {
    const rule = findRule({ ruleId, state });
    rule.attributes.optionalValue = newValue;
  },

  userCompetenciesChanged(state, { competencies, user }) {
    remove(state.competencies, competency => competency.userId === user.id);

    state.competencies.push(...competencies);
  },

  resetOriginalCompetencies(state) {
    state.originalCompetencies = cloneDeep(state.competencies);
  },

  undoChangesToCompetencies(state) {
    state.competencies = cloneDeep(state.originalCompetencies);
  },
};

const actions = {
  fetchRules({ commit }, { shopId }) {
    commit('performingRequest', 'isLoadingRulesFetch');

    return httpClient
      .get(`/v3/api/shops/${shopId}/automatic_planning/rules`)
      .then(response => {
        commit('fetchRulesSuccess', response.data);
        return response;
      })
      .catch(error => { throw error; })
      .finally(() => {
        commit('requestComplete', 'isLoadingRulesFetch');
      });
  },

  toggleRule({ commit, state }, { ruleId, shopId }) {
    const automaticPlanningRule = findRule({ state, ruleId });

    commit('ruleToggled', automaticPlanningRule);

    return httpClient
      .patch(
        `/v3/api/shops/${shopId}/automatic_planning/rules/${ruleId}/toggle_active`,
        { active: automaticPlanningRule.attributes.active },
      )
      .catch(error => {
        commit('ruleToggled', automaticPlanningRule);
        throw error;
      });
  },

  toggleOptionalValue({ commit, state }, { ruleId, shopId }) {
    const automaticPlanningRule = findRule({ state, ruleId });

    commit('optionalValueToggled', automaticPlanningRule);

    return httpClient
      .patch(
        `/v3/api/shops/${shopId}/automatic_planning/rules/${ruleId}/toggle_optional_value`,
        { optional_value: automaticPlanningRule.attributes.optionalValue },
      )
      .catch(error => {
        commit('optionalValueToggled', automaticPlanningRule);
        throw error;
      });
  },

  updateRules({ commit, state }, { shopId }) {
    commit('performingRequest', 'isLoadingRulesUpdate');
    const params = { automatic_planning_rules: state.automaticPlanningRules };
    return httpClient
      .patch(`/v3/api/shops/${shopId}/automatic_planning/rules`, params)
      .then(response => {
        commit('fetchRulesSuccess', response.data);
        return response;
      })
      .catch(error => { throw error; })
      .finally(() => {
        commit('requestComplete', 'isLoadingRulesUpdate');
      });
  },

  fetchCompetencies({ commit }, { shopId, users }) {
    commit('performingRequest', 'isLoadingCompetenciesFetch');

    const params = {
      user_ids: users.map(user => user.id),
      shop_id: shopId,
    };
    return httpClient
      .get('/v3/api/automatic_planning/competencies', { params })
      .then(response => {
        commit('fetchCompetenciesSuccess', response.data);
        return response;
      })
      .catch(error => { throw error; })
      .finally(() => commit('requestComplete', 'isLoadingCompetenciesFetch'));
  },

  submitCompetencies({ state, commit }, { shopId, users }) {
    commit('performingRequest', 'isLoadingCompetenciesUpdate');

    const competenciesByUserId = {};
    users.forEach(user => {
      competenciesByUserId[user.id] =
        state.competencies.filter(({ userId }) => userId === user.id);
    });

    const params = {
      shop_id: shopId,
      users: competenciesByUserId,
    };
    return httpClient
      .patch('/v3/api/automatic_planning/competencies', params)
      .then(response => {
        commit('resetOriginalCompetencies');
        return response;
      })
      .catch(error => {
        commit('undoChangesToCompetencies');
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'isLoadingCompetenciesUpdate');
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
