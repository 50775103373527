import cloneDeep from 'lodash/cloneDeep';
import skDate from '@skello-utils/dates';
import { httpClient } from '@skello-utils/clients';
import {
  AVAILABILITY_RECURRENCE_WEEK, AVAILABILITY_RECURRENCE_NONE,
} from '@app-js/shared/constants/availability';

const availabilitiesParams = state => {
  const params = state.availabilities.reduce((availabilities, availability) => {
    const serializedAvailability = {
      id: availability.id,
      _destroy: availability.attributes._destroy,
      day_of_week: availability.attributes.dayOfWeek,
      ends_at: availability.attributes.endsAt,
      starts_at: availability.attributes.startsAt,
      status: availability.attributes.status,
      user_id: availability.attributes.userId,
      shop_id: availability.attributes.shopId,
      request_status: availability.attributes.requestStatus,
      recurrence: availability.attributes.recurrence,
    };

    availabilities.push(serializedAvailability);
    return availabilities;
  }, []);
  return params;
};

const initialState = {
  availabilities: [
    {
      id: '',
      type: 'availability',
      attributes: {},
    },
  ],
  originalData: [],
  error: null,
  loading: false,
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  fetchAvailabilitiesSuccess(state, payload) {
    state.availabilities = payload.data.map(availability => {
      availability.attributes._destroy = false;
      return availability;
    });
    state.originalData = cloneDeep(state.availabilities);
  },

  availabilitiesError(state, error) {
    state.error = error;
  },

  addAvailability(state, { shopId, userId, day }) {
    const newAvailabilty = {
      id: null,
      type: 'availability',
      attributes: {
        _destroy: false,
        dayOfWeek: day || 'monday',
        canEdit: true,
        startsAt: skDate().startOf('day').format(),
        endsAt: skDate().startOf('day').add(12, 'hours').format(),
        recurrence: day ? AVAILABILITY_RECURRENCE_WEEK : AVAILABILITY_RECURRENCE_NONE,
        requestStatus: 'pending',
        status: 'available',
        shopId,
        userId,
      },
    };

    state.availabilities.push(newAvailabilty);
  },

  setEmployeeAvailabilityAttributes(state, { availability, attributes }) {
    const index = state.availabilities.indexOf(availability);
    availability = state.availabilities[index];

    Object.assign(availability.attributes, attributes);
  },
};

const actions = {
  setAvailabilities({ commit }, payload) {
    commit('fetchAvailabilitiesSuccess', cloneDeep(payload));
  },

  fetchAvailabilities({ commit }, { shopId, employeeId }) {
    commit('performingRequest');

    return httpClient
      .get(`/v3/api/users/${employeeId}/availabilities?shop_id=${shopId}`)
      .then(response => {
        commit('fetchAvailabilitiesSuccess', response.data);
      })
      .catch(error => {
        commit('availabilitiesError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },

  updateAvailabilities({ state, commit }, params) {
    const payload = {
      shop_id: params.shopId,
      availabilities: availabilitiesParams(state),
    };

    return httpClient
      .patch(`/v3/api/users/${params.userId}/availabilities/bulk_update`, payload)
      .then(response => {
        commit('fetchAvailabilitiesSuccess', response.data);
        return response;
      })
      .catch(({ error }) => {
        commit('currentShopError', error.data);
        throw error;
      });
  },
};

const getters = {
  activeRecurrentAvailabilities: state => (
    state.availabilities.filter(
      availability => availability.attributes.recurrence === AVAILABILITY_RECURRENCE_WEEK &&
      !availability.attributes._destroy,
    )
  ),

  exceptionalAvailabilities: state => (
    state.availabilities.filter(
      availability => availability.attributes.recurrence === AVAILABILITY_RECURRENCE_NONE &&
      !availability.attributes._destroy,
    )
  ),
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
