const initialState = {
  kpisMetrics: {},
  kpisMetricsLoading: false,
};

const mutations = {
  performingFetchAllMetricsRequest(state) {
    state.kpisMetricsLoading = true;
  },

  fetchAllMetricsComplete(state) {
    state.kpisMetricsLoading = false;
  },

  fetchAllMetricsSuccess(state, payload) {
    state.kpisMetrics = payload;
  },
};

const actions = {
  setLoadingKpisMetrics({ commit }) {
    commit('performingFetchAllMetricsRequest');
  },
  setKpisMetrics({ commit }, { kpisMetrics }) {
    commit('fetchAllMetricsSuccess', kpisMetrics);
  },
  setLoadingKpisMetricsCompleted({ commit }) {
    commit('fetchAllMetricsComplete');
  },
};

const getters = {
  getMetric: state => ({ metricName, shopId }) => {
    if (!state.kpisMetrics[metricName] || !state.kpisMetrics[metricName][shopId]) return [];

    return state.kpisMetrics[metricName][shopId];
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
