// to match an item to an item
const associationMatcherItem = (item, included, { key, type }) => {
  if (!item.relationships[key]) {
    return null;
  }

  // Ensure association is not null
  if (item.relationships[key].data) {
    const associationId = item.relationships[key].data.id;
    return included.find(model => model.id === associationId && model.type === type);
  }
  return null;
};

// to match a collection to an item
const associationMatcherCollection = (item, included, { key, type }) => {
  if (!item.relationships[key]) return undefined;
  const associationIds = item.relationships[key].data.map(({ id }) => id);

  return associationIds.map(id => (
    included.find(model => model.id === id && model.type === type)
  ));
};

const matchPosteToShift = (shift, included) => {
  const poste = associationMatcherItem(shift, included, { key: 'poste', type: 'poste' });
  const previsionalPoste = associationMatcherItem(shift, included, { key: 'previsionalPoste', type: 'poste' });

  Object.assign(shift.relationships, { poste, previsionalPoste });
};

const matchUserToShift = (shift, included) => {
  const user = associationMatcherItem(shift, included, { key: 'user', type: 'user' });

  Object.assign(shift.relationships, { user });
};

export {
  associationMatcherItem, associationMatcherCollection, matchPosteToShift, matchUserToShift,
};
