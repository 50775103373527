import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatShopMealSettingsUpdatedContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    calculation_method: data.calculationMethod,
  },
});

export const formatShopMealSettingsUpdated =
  async (store, data) => [formatShopMealSettingsUpdatedContext(store, data)];
