<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 19 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6623 16.2463C17.1029 16.6857 17.4525 17.2076 17.6911 17.7823C17.9296 18.357 18.0524 18.9731 18.0524 19.5953C18.0524 20.2175 17.9296 20.8336 17.6911 21.4083C17.4525 21.983 17.1029 22.505 16.6623 22.9443L9.10653 30.5C8.15409 31.4572 6.86042 31.9968 5.51013 32.0001C4.15983 32.0035 2.86352 31.4702 1.90636 30.5178C0.9492 29.5654 0.4096 28.2717 0.406266 26.9214C0.402931 25.5711 0.936136 24.2748 1.88858 23.3176L12.8044 12.4062L16.6623 16.2463Z"
      fill="#2e26f2"
    />
    <path
      d="M5.61324 5.21094L1.38647 9.43326C0.498693 10.3217 0 11.5263 0 12.7822C0 14.0382 0.498693 15.2428 1.38647 16.1312L5.23101 19.9713L16.1424 9.06436L12.7912 12.4156L5.61324 5.21094Z"
      fill="#459FFF"
    />
    <path
      d="M16.5291 1.48587C15.5744 0.534314 14.2814 0 12.9335 0C11.5855 0 10.2925 0.534314 9.33782 1.48587L5.61328 5.21041L12.8046 12.4017L16.1558 9.0505L16.5291 8.67716C17.4807 7.72244 18.015 6.42946 18.015 5.08152C18.015 3.73357 17.4807 2.44059 16.5291 1.48587Z"
      fill="#2b66fe"
    />
  </svg>
</template>

<script>
export default {
  name: 'SkColorSLogoBase',
  props: {
    width: {
      type: String,
      default: '19',
    },
    height: {
      type: String,
      default: '32',
    },
  },
};
</script>
