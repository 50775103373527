import {
  formatEmployees, fullName,
} from '../../utils';
import { formatEvent } from '../../format_event';

export const formatWeekPlanningContext = (store, shift) => {
  const employees = formatEmployees(store.employees);

  return {
    ...formatEvent(store),
    context: {
      user_source_name: fullName(store.currentUser),
      user_target_id: shift.attributes.userId,
      user_target_name: fullName(employees[shift.attributes.userId]),
      start_date: (new Date(shift.attributes.startsAt)).getTime(),
      end_date: (new Date(shift.attributes.endsAt)).getTime(),
      meal_taken: shift.attributes.nbMeal,
      note: shift.attributes.note ?? undefined,
      position_id: shift.relationships.poste.id,
      shop_name: store.currentShop.attributes.name,
      shop_id: shift.attributes.shopId.toString(),
      shift_id: shift.id,
    },
  };
};

const formatDeleteBulkWeekPlanningShiftContext = (store, data) => {
  const employees = formatEmployees(store.employees);

  return {
    ...formatEvent(store),
    context: {
      user_source_name: fullName(store.currentUser),
      user_target_ids: data.shifts.map(shift => shift.attributes.userId),
      user_target_names: data.shifts.map(shift => fullName(employees[shift.attributes.userId])),
      start_date: (new Date(data.starts_at)).getTime(),
      end_date: (new Date(data.ends_at)).getTime(),
      shop_name: store.currentShop.attributes.name,
      shop_id: store.currentShop.attributes.id,
      shift_ids: data.shifts.map(shift => shift.id),
    },
  };
};

const formatUpdateSwapUserShifts = (store, data) => {
  const employees = formatEmployees(store.employees);

  return {
    ...formatEvent(store),
    context: {
      user_source_name: fullName(store.currentUser),
      user_target_ids: data.shifts.map(shift => shift.attributes.userId),
      user_target_names: data.shifts.map(shift => fullName(employees[shift.attributes.userId])),
      start_date: (new Date(data.starts_at)).getTime(),
      end_date: (new Date(data.ends_at)).getTime(),
      shop_name: store.currentShop.attributes.name,
      shop_id: data.shop_id.toString(),
      shift_ids: data.shifts.map(shift => shift.id),
    },
  };
};

const formatWeekPlanning =
  async (store, data) => data.map(shift => formatWeekPlanningContext(store, shift));

export const createWeekPlanningShift = formatWeekPlanning;

export const updateWeekPlanningShift = formatWeekPlanning;

export const deleteWeekPlanningShift = formatWeekPlanning;

export const createWeekPlanningAbsence = formatWeekPlanning;

export const updateWeekPlanningAbsence = formatWeekPlanning;

export const deleteWeekPlanningAbsence = formatWeekPlanning;

export const deleteBulkWeekPlanningShift =
  async (store, data) => [formatDeleteBulkWeekPlanningShiftContext(store, data)];

export const updateSwapPlanningUserShifts =
  async (store, data) => [formatUpdateSwapUserShifts(store, data)];
