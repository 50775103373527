import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatReportBulkHourTrackerResetContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_id: store.currentShop.id,
    shop_name: store.currentShop.attributes.name,
    user_target_ids: data.users.map(user => user.id),
    user_target_names: data.users.map(
      user => fullName({ attributes: { lastName: user.last_name, firstName: user.first_name } }),
    ),
    date: data.date,
  },
});

export const formatReportBulkHourTrackerReset =
  async (store, data) => [formatReportBulkHourTrackerResetContext(store, data)];
