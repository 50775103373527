import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatCompanyLicenseUpdateContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    user_target_ids: [],
    date: new Date().toISOString(),
    changes: data.changes,
    license_id: data.licenseId,
    license_name: data.licenseName,
  },
});

export const formatCompanyLicenseUpdate =
  async (store, data) => [formatCompanyLicenseUpdateContext(store, data)];
