// This mapping is needed for payroll preparation
// in order to determine which fields are missing for a user
export const MANDATORY_ATTRIBUTE_TO_TAB = {
  user_personal: ['firstName', 'lastName'],
  user_contracts: [
    'arrivalDate',
    'contractTypeId',
    'counterType',
    'hourlyWage',
    'hourlyWageWithCosts',
    'monthlySalary',
    'posteName',
    'startDate',
    'status',
    'timeBasisCategory',
  ],
  user_hr_file: [
    'address',
    'bankInfo',
    'birthDepartment',
    'birthPlace',
    'birthday',
    'city',
    'countryCodeOfBirth',
    'countryCodeOfResidence',
    'gender',
    'nationality',
    'postalCode',
    'swiftCode',
  ],
};
