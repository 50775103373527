import store from '@app-js/shared/store/index';

const ALL_SHOPS_VIEW_IMPACTED_FEATURES = [
  'FEATURE_ANNUALIZED_WORKING_TIME_V2_ENABLED',
  'FEATURE_VARIABLE_CONTRACT_HOURS_ENABLED',
];

export const checkFeatureFlag = ({ flagName, shop, convention }) => {
  const featureFlags = store.state.config.featureFlags.feature_flags;
  const featureFlagsBatch2 = [
    'FEATURE_ANNUALIZED_WORKING_TIME_ENABLED',
  ];

  const isSvcFeatureFlagActivated = JSON.parse(document.querySelector('[name=FEATURE_SVC_FEATURE_FLAG]').content);
  const isSvcFeatureFlagBatch2Activated = JSON.parse(document.querySelector('[name=FEATURE_SVC_FEATURE_FLAG_BATCH_2]').content);
  const featureFlagValue = JSON.parse(document.querySelector(`[name=${flagName}]`).content);

  if (!isSvcFeatureFlagActivated ||
     (!isSvcFeatureFlagBatch2Activated && featureFlagsBatch2.includes(flagName))) {
    return featureFlagValue;
  }

  // concerning the 'all shops view' we handle features one by one for the moment
  if (shop.id === 'all' && ALL_SHOPS_VIEW_IMPACTED_FEATURES.includes[flagName]) {
    return featureFlagValue;
  }

  let country = shop.attributes.country?.toLowerCase();
  if (store.state.config.config.dom_tom.includes(country)) {
    country = 'fr';
  }

  const featureFlagCountries = store.state.config.config.feature_flag_countries;

  if (!Object.values(featureFlagCountries).includes(country)) {
    country = featureFlagCountries.OTHER;
  }

  const featureFlag = featureFlags.find(flag => flag.name === flagName);
  if (!featureFlag || !featureFlag.sectors || !featureFlag.languages) return false;

  const sector = convention?.attributes?.sector || 'general';
  return featureFlag.languages.includes(country) && featureFlag.sectors.includes(sector);
};
