// This function is the ES6 version of the snippet provided in the
// segment analytics vue documentation:
// https://github.com/segmentio/analytics-vue#%EF%B8%8F-step-1-copy-the-snippet

export default function initializeSegment() {
  window.analytics = window.analytics || [];
  const analytics = window.analytics;

  if (!analytics.initialize) {
    if (analytics.invoked) {
      /*  eslint-disable-next-line no-unused-expressions */
      window.console && console.error && console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = t => (...args) => {
        const e = Array.prototype.slice.call(args);
        e.unshift(t);
        analytics.push(e);
        return analytics;
      };

      for (let t = 0; t < analytics.methods.length; t += 1) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = (t, e) => {
        const n = document.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src = `https://cdn.segment.com/analytics.js/v1/${ t }/analytics.min.js`;
        const a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(n, a);
        /*  eslint-disable-next-line no-underscore-dangle */
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0';
    }
  }
}
