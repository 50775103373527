import skDate from '@skello-utils/dates';
import store from '@app-js/shared/store/index';
import { svcWorkloadPlansClient } from '@skello-utils/clients';
import { openingAndClosingTimeAt } from '@app-js/plannings/shared/utils/planning_helpers';
import { workloadPlanMaximumWeeksRange } from '@config/env';

const initialState = {
  /*
  * Format: { posteId: [ { startsAt, value } ] }
  * Example format:
  * {
  *   1132: [ { startsAt: '2023-01-18T22:15', value: 5 } ],
  *   1130: [
  *     { startsAt: '2023-01-18T22:30', value: 5 },
  *     { startsAt: '2023-01-18T22:45', value: 5 },
  *   ],
  *   ...
  * };
  */
  workloadPlansByPostes: {},
  /*
  * Start and end date of the conflict period. Used in the modal.
  * Format: period: {start, end}
  */
  period: {},
  conflictsWorkloadPlans: {
    /* Format: { posteId: [workloadPlan, workloadPlan].. } */
    workloadPlansWithoutConflict: {},
    /* Format: { posteId: { date: [workloadPlan, workloadPlan..] } } */
    workloadPlansWithConflict: {},
  },
  isConflictSidePanelOpen: false,
  isWorkloadPlansLoading: false,
  error: null,
};

const mutations = {
  fetchWorkloadPlansSuccess(state, payload) {
    state.workloadPlansByPostes = payload;
  },
  setWorkloadPlanConflicts(state, payload) {
    state.conflictsWorkloadPlans.workloadPlansWithConflict = payload;
  },
  setWorkloadPlanToSave(state, payload) {
    state.conflictsWorkloadPlans.workloadPlansWithoutConflict = payload;
  },
  setPeriod(state, payload) {
    state.period = payload;
  },
  toggleConflictSidePanel(state, value) {
    state.isConflictSidePanelOpen = value;
  },
  toggleIsWorkloadPlansLoading(state) {
    state.isWorkloadPlansLoading = !state.isWorkloadPlansLoading;
  },
};

const actions = {
  fetchWorkloadPlans(
    { commit },
    { shopId, posteIds, startsAt, endsAt, closingHoursTime, openingHoursTime, saveToStore = true },
  ) {
    if (saveToStore) commit('toggleIsWorkloadPlansLoading');

    return svcWorkloadPlansClient.getWorkloadPlansByPostes(
      { shopId, posteIds, startsAt, endsAt, closingHoursTime, openingHoursTime },
    ).then(workloadPlans => {
      if (saveToStore) commit('fetchWorkloadPlansSuccess', workloadPlans);
      return workloadPlans;
    }).finally(() => {
      if (saveToStore) commit('toggleIsWorkloadPlansLoading');
    });
  },

  upsertWorkloadPlans({ commit }, data) {
    const { currentShop } = store.state.currentShop;

    return svcWorkloadPlansClient.saveWorkloadPlans(currentShop.id, data);
  },

  /*
    If there is only 1 poste on the shop, it will always be selected (already handled).
    If there are more, what we select depends on the needs input :
    - If the user adds needs for 1 poste, we'll select it.
    - If the user adds needs for more than 1 poste, we select all (no matter the old preference).
  */
  setDefaultSelectedPosteInLocalStorage(
    { commit, dispatch, state, getters },
    workloadPlansByPostes,
  ) {
    const posteIdsWithNeeds = Object.keys(workloadPlansByPostes);
    const newSelectedPosteValue = posteIdsWithNeeds.length === 1 ?
      posteIdsWithNeeds[0] : 'all';

    localStorage.setItem(
      getters.localStorageSaveSelectedPosteKey,
      newSelectedPosteValue,
    );
  },
};

const getters = {
  /*
  * Format: { startsAt: [ { posteId, value } ] }
  * Example format:
  * {
  *   '2023-01-18T22:15': [ { posteId: 1132, value: 5 } ],
  *   '2023-01-18T22:30': [
  *     { posteId: 1130, value: 1 },
  *     { posteId: 1131, value: 2 },
  *   ],
  *   ...
  * };
  */
  workloadPlansByHourQuarters: state => (
    Object.keys(state.workloadPlansByPostes).reduce((acc, posteId) => {
      state.workloadPlansByPostes[posteId].forEach(quarterValue => {
        if (acc[quarterValue.startsAt]) {
          acc[quarterValue.startsAt].push(
            { posteId, value: quarterValue.value },
          );
        } else {
          acc[quarterValue.startsAt] = [{ posteId, value: quarterValue.value }];
        }
      });

      return acc;
    }, {})
  ),

  /*
  * Returns workloadPlansByHourQuarters but filtered on the given period (start / end)
  */
  workloadPlansByHourQuartersForPeriod: (_state, selfGetters) => (periodStart, periodEnd) => {
    const skPeriodStart = skDate.utc(periodStart);
    const skPeriodEnd = skDate.utc(periodEnd);

    return Object.keys(selfGetters.workloadPlansByHourQuarters)
      .filter(key => skDate.utc(key).isBetween(skPeriodStart, skPeriodEnd, undefined, '[)'))
      .reduce((acc, key) => Object.assign(
        acc, { [key]: selfGetters.workloadPlansByHourQuarters[key] },
      ), {},
      );
  },

  /*
  * Returns workloadPlansByPostes but filtered on the given period (start / end)
  */
  workloadPlansForPeriod: state => (periodStart, periodEnd) => {
    const skPeriodStart = skDate.utc(periodStart);
    const skPeriodEnd = skDate.utc(periodEnd);

    return Object.keys(state.workloadPlansByPostes).reduce((acc, posteId) => {
      acc[posteId] = state.workloadPlansByPostes[posteId]
        ?.filter(workloadPlans => skDate.utc(workloadPlans.startsAt).isBetween(skPeriodStart, skPeriodEnd, undefined, '[)'));

      return acc;
    }, {});
  },

  /*
  * Returns list of workload plan for a given poste on a given period
  */
  workloadPlansByPostesForPeriod: (_state, selfGetters) => (
    posteId,
    periodStart,
    periodEnd,
  ) => selfGetters.workloadPlansForPeriod(periodStart, periodEnd)[posteId],

  /* Returns an integer : the number of hour quarters included in the shop's opening hours. */
  numberOfQuartersInOpeningDay: (_state, selfGetters) => {
    const { openingTime, closingTime } = selfGetters.shopOpeningAndClosingTime;
    const duration = skDate.duration(closingTime.diff(openingTime));

    return duration.asMinutes() / 15;
  },

  /*
    Returns an array of all the hour quarters included in the shop's opening hours, as strings.
    Format : ['2023-02-06T06:00:00.000Z', '2023-02-06T06:15:00.000Z', '2023-02-06T06:30:00.000Z']
  */
  arrayOfOpeningQuarters: (_state, selfGetters) => {
    const { openingTime } = selfGetters.shopOpeningAndClosingTime;

    return Array(selfGetters.numberOfQuartersInOpeningDay)
      .fill(0)
      .map((_e, i) => openingTime.clone().add(i * 15, 'minutes').toISOString());
  },
  /**
   * Array of all opening hour quarters formatted as "HH:mm"
   */
  openingHourQuarters: (_state, selfGetters) => selfGetters.arrayOfOpeningQuarters.map(
    hourQuarterWithDate => skDate.utc(hourQuarterWithDate).format('HH:mm'),
  ),
  localStorageSaveSelectedPosteKey: _state => {
    const { currentShop } = store.state.currentShop;
    const { currentUser } = store.state.currentUser;
    return `workloadPlanSelectedPosteForShop:${currentShop.id},User:${currentUser.id}`;
  },
  localStorageSaveOpenPanelKey: _state => {
    const { currentUser } = store.state.currentUser;
    return `workloadPlanPanelDisplayPreferenceForUser:${currentUser.id}`;
  },
  maximumWeeksRange: _state => parseInt(workloadPlanMaximumWeeksRange || '2', 10),
  numberOfPostesAndConflicts: state => {
    const workloadPlansWithConflict = state.conflictsWorkloadPlans.workloadPlansWithConflict;
    const numberOfPostesInConflict = Object.keys(workloadPlansWithConflict).length;
    let numberOfConflicts = 0;

    if (numberOfPostesInConflict !== 0) {
      /*
      * workloadPlansWithConflict Format: { posteId: { date: [workloadPlan, workloadPlan...] } }
      * Loop over each postIds values and sum up the number of days key.
      */
      numberOfConflicts = Object.values(workloadPlansWithConflict).reduce(
        (acc, conflictsByDay) => acc + Object.keys(conflictsByDay).length, 0,
      );
    }

    return { numberOfPostesInConflict, numberOfConflicts };
  },
  /*
  * Returns the final workload plans to save in case of conflict.
  * It merges the conflicts founded and the clean list of workloadPlans from the file uploaded.
  *
  * Returned Format: { posteId: [workloadPlan, workloadPlan].. }
  */
  workloadPlanToSaveAfterMergeConflicts: state => {
    const workloadPlans = { ...state.conflictsWorkloadPlans.workloadPlansWithoutConflict };

    Object.entries(state.conflictsWorkloadPlans.workloadPlansWithConflict)
      .forEach(([posteId, workloadPlansByDate]) => {
        Object.values(workloadPlansByDate).forEach(workloadPlansConflicts => {
          if (!workloadPlans[posteId]) workloadPlans[posteId] = [];
          workloadPlans[posteId].push(...workloadPlansConflicts);
        });
      });

    return workloadPlans;
  },

  /*
  * Returns true if on a given period, there is at least one workload plan
  *
  * Params openingTime: String, Ex: 2023-12-30T00:00:00.000Z
  * Params openingTime: String, Ex: 2011-10-05T14:48:00.000Z
  * Returns type: Boolean
  */
  hasNeedsOnPeriod: (_state, selfGetters) => (openingTime, closingTime) => {
    const workloadPlanByQuarters = selfGetters.workloadPlansByHourQuartersForPeriod(
      openingTime,
      closingTime,
    );

    return !!workloadPlanByQuarters &&
      Object.values(workloadPlanByQuarters)
        .flat()
        .filter(workloadPlans => workloadPlans.value).length > 0;
  },
  shopOpeningAndClosingTime: () => store.getters['currentShop/currentShopOpeningAndClosingTime'],
  fetchParamsByDay: (_state, selfGetters) => (day, posteIds) => {
    const dayDate = skDate.utc(day).format();

    const { openingTime, closingTime } = selfGetters.shopOpeningAndClosingTime;
    const currentShop = store.state.currentShop.currentShop;

    const formattedOpeningTime = openingTime.format('HH:mm');
    const formattedClosingTime = closingTime.format('HH:mm');

    const requestPeriod = openingAndClosingTimeAt(
      formattedOpeningTime,
      formattedClosingTime,
      dayDate,
    );

    return {
      closingHoursTime: formattedClosingTime,
      endsAt: requestPeriod.closingTime.format('YYYY-MM-DDTHH:mm:ss.000Z'),
      openingHoursTime: formattedOpeningTime,
      posteIds,
      shopId: currentShop.id,
      startsAt: requestPeriod.openingTime.format('YYYY-MM-DDTHH:mm:ss.000Z'),
    };
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
