import { EVENT_SUBTYPE_ACTION } from '@skelloapp/svc-events-client';

const generateTeamUpdateEmployeesEvent = (initialTeam, updatedTeam) => ({
  subtype: EVENT_SUBTYPE_ACTION.SHOP_TEAM_UPDATE_EMPLOYEES,
  payload: {
    id: initialTeam.id,
    name: initialTeam.attributes.name,
    oldEmployees: initialTeam.relationships.users.map(u => u.id),
    newEmployees: updatedTeam.user_ids,
  },
});

const generateTeamUpdateNameEvent = (initialTeam, updatedTeam) => ({
  subtype: EVENT_SUBTYPE_ACTION.SHOP_TEAM_UPDATE_NAME,
  payload: {
    id: initialTeam.id,
    oldName: initialTeam.attributes.name,
    newName: updatedTeam.name,
  },
});

export const generateTeamUpdateEvent = (initialTeam, updatedTeam) => {
  if (updatedTeam.user_ids !== undefined) {
    return generateTeamUpdateEmployeesEvent(initialTeam, updatedTeam);
  } if (updatedTeam.name && updatedTeam.name !== initialTeam.attributes.name) {
    return generateTeamUpdateNameEvent(initialTeam, updatedTeam);
  }

  return undefined;
};
