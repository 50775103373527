import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatShopPTOTrackerEnableContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    date: data.date,
    period: data.period,
    tracker_history: data.tracker_history,
  },
});

export const formatShopPTOTrackerEnable =
  async (store, data) => [formatShopPTOTrackerEnableContext(store, data)];
