import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatEmployeeLicenseUpdateContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    user_target_id: data.user.id.toString(),
    user_target_name: fullName(data.user),
  },
});

export const formatEmployeeLicenseUpdate =
  async (store, data) => [formatEmployeeLicenseUpdateContext(store, data)];
