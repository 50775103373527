import cloneDeep from 'lodash/cloneDeep';
import { httpClient } from '@skello-utils/clients';

const conventionParams = state => {
  const convention = state.convention.attributes;
  return {
    id: convention.id,
    hours_without_pause: convention.hoursWithoutPause,
    min_pause_time: convention.minPauseTime,
    max_daily_hours: convention.maxDailyHours,
    max_weekly_hours: convention.maxWeeklyHours,
    weekly_day_rest_num: convention.weeklyDayRestNum,
    minimum_part_time_worked: convention.minimumPartTimeWorked,
    daily_rest_min_hours: convention.dailyRestMinHours,
    part_time_percent_limit: convention.partTimePercentLimit,
    part_time_hour_limit: convention.partTimeHourLimit,
    max_day_length: convention.maxDayLength,
    part_time_max_day_length: convention.partTimeMaxDayLength,
    max_coupure_hours: convention.maxCoupureHours,
    min_shift_update_delay: convention.minShiftUpdateDelay,
    minimum_part_time_worked_weekly: convention.minimumPartTimeWorkedWeekly,
    max_day_straight_nb: convention.maxDayStraightNb,
    max_extra_quarterly_worked_days: convention.maxExtraQuarterlyWorkedDays,
    part_time_contract_hours_wo_cuts_allowed: convention.partTimeContractHoursWoCutsAllowed,
    max_cuts_nb: convention.maxCutsNb,
    max_cuts_calculation: convention.maxCutsCalculation,
    night_shifts_start_at_for_alerts: convention.nightShiftsStartAtForAlerts,
    night_shifts_end_at_for_alerts: convention.nightShiftsEndAtForAlerts,
  };
};

const initialState = {
  convention: {},
  originalConvention: {},
  error: null,
  loading: false,
  updatingConvention: false,
  unsavedChanges: false,
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  updateConventionPending(state) {
    state.updatingConvention = true;
  },

  updateConventionComplete(state) {
    state.updatingConvention = false;
  },

  fetchConventionSuccess(state, payload) {
    state.convention = payload.data;
    state.originalConvention = cloneDeep(state.convention);
    state.unsavedChanges = false;
  },

  catchConventionError(state, payload) {
    state.error = payload;
  },

  setConventionAttributes(state, payload) {
    state.unsavedChanges = true;
    Object.keys(payload).forEach(attribute => {
      state.convention.attributes[attribute] = payload[attribute];
    });
  },
};

const actions = {
  fetchConvention({ commit }, shopId) {
    return new Promise((resolve, reject) => {
      commit('performingRequest');

      httpClient
        .get(`/v3/api/shops/${shopId}/convention`)
        .then(response => {
          commit('fetchConventionSuccess', response.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('catchConventionError', response);
          reject(response);
        })
        .finally(() => {
          commit('requestComplete');
        });
    });
  },

  updateConvention({ state, commit }, shopId) {
    return new Promise((resolve, reject) => {
      commit('updateConventionPending');

      httpClient
        .patch(`/v3/api/shops/${shopId}/convention`, { convention: conventionParams(state) })
        .then(response => {
          commit('fetchConventionSuccess', response.data);
          resolve(response);
        })
        .catch(({ response }) => {
          commit('catchConventionError', response);
          reject(response);
        })
        .finally(() => {
          commit('updateConventionComplete');
        });
    });
  },
};

const getters = {
  unsavedChangesToConvention(state) {
    return JSON.stringify(state.convention) !== JSON.stringify(state.originalConvention);
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
