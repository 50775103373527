/**
 *Filter users by teams and postes
 * @param {*} filters Active filters for teams and postes
 * @param {*} users All users
 * @param {*} shifts all shifts for period
 * @returns filtered users
 */
export const filterUsers = (filters, users, shifts) => {
  let filteredUsers = [...users];
  // filter by teams
  if (filters.teams?.length > 0) {
    filteredUsers = filteredUsers.filter(user => (
      user.relationships.teams.data.some(team => filters.teams.includes(team.id))
    ));
  }
  // filter by postes
  if (filters.postes?.length > 0) {
    filteredUsers = filteredUsers.filter(user => shifts.some(shift => (
      (
        // include pending leave request -> type === 'poste'
        (shift.type === 'poste' && filters.postes.includes(`${shift.attributes.posteId}`)) ||
        filters.postes.includes(shift.relationships?.poste?.id)
      ) && shift.attributes.userId && shift.attributes.userId.toString() === user.id
    )));
  }
  // if users filters are active, return selected users
  if (filters.users?.length > 0) {
    filteredUsers = filteredUsers.filter(user => filters.users.includes(user.id));
  }

  // sort by position
  return filteredUsers.sort((a, b) => a.attributes.userOrder - b.attributes.userOrder);
};

/**
 * Filter user and team shifts for position view
 * @param {*} filters Active filters for teams, postes and users
 * @param {*} users all users
 * @param {*} shifts all shifts for period
 * @returns filtered shifts for given users and teams
 */
export const filterUserAndTeamShifts = (filters, users, shifts) => {
  // If no filters are set, return all shifts
  if (!filters.users?.length > 0 && !filters.teams?.length > 0) {
    return shifts;
  }

  // filter by users
  let filteredUsers = [...users];
  if (filters.users?.length > 0) {
    filteredUsers = filteredUsers.filter(user => filters.users.includes(user.id));
  }
  // filter users by teams
  if (filters.teams?.length > 0) {
    filteredUsers = filteredUsers.filter(user => (
      user.relationships.teams.data.some(team => filters.teams.includes(team.id))
    ));
  }

  // Filter shifts by filtered users
  const userIds = filteredUsers.map(user => Number(user.id));

  return shifts
    .filter(shift => userIds.includes(shift.attributes.userId));
};

/**
 * Filter postes by postes, teams and users
 * @param {*} filters Active filters for teams, postes and users
 * @param {*} postesAndAbsences postes and absences displayed on position view
 * @param {*} users all users
 * @param {*} shifts all shifts for period
 * @returns filtered postes
 */
export const filterPostes = (filters, postesAndAbsences, users, shifts) => {
  // if poste filters are active, return selected postes
  if (filters.postes?.length > 0) {
    return postesAndAbsences.filter(poste => filters.postes.includes(poste.id));
  }

  // If there are no filters, return all postes and absences with corresponding shifts
  if (!filters.users?.length > 0 && !filters.teams?.length > 0) {
    const shiftPosteIds = shifts.map(shift => shift.relationships.poste.id);
    return postesAndAbsences.filter(poste => {
      if (poste.attributes.absenceKey === null) return true;
      return shiftPosteIds.includes(poste.id);
    });
  }

  const userShifts = filterUserAndTeamShifts(filters, users, shifts);
  // Return postes from filtered shifts
  const filteredUserShiftPosteIds = userShifts.map(shift => shift.relationships.poste.id);

  return postesAndAbsences.filter(poste => filteredUserShiftPosteIds.includes(poste.id));
};
