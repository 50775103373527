import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatPlanningSortEmployeesContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_id: store.currentShop.id,
    shop_name: store.currentShop.attributes.name,
    employees_order: data,
  },
});

export const planningSortEmployees =
  async (store, data) => [formatPlanningSortEmployeesContext(store, data)];
