import { authClient } from '@skello-utils/clients/auth_client';
import { WorkloadPlanClient } from '@skelloapp/workload-plan-js';
import { svcWorkloadPlanUrl } from '@config/env';

class SvcWorkloadPlansClient {
  constructor() {
    this.workloadPlanClient = new WorkloadPlanClient(svcWorkloadPlanUrl);
  }

  /*
   * params : {
   *   shopId: string,
   *   posteIds: string[],
   *   startsAt: string - YYYY-MM-DD,
   *   endsAt: string - YYYY-MM-DD,
   *   closingHoursTime: string - HH:mm,
   *   openingHoursTime: string - HH:mm,
   * }
   */
  async getWorkloadPlansByPostes(params) {
    const { token } = await authClient.getAuthToken();

    return this.workloadPlanClient.getWorkloadPlansByPostes(
      { authorization: token },
      params,
    );
  }

  /*
   * params: { posteId: [ { startsAt, value } ] }
   * Example:
   * {
   *   1132: [ { startsAt: '2023-01-18T22:15', value: 5 } ],
   *   1130: [
   *     { startsAt: '2023-01-18T22:30', value: 5 },
   *     { startsAt: '2023-01-18T22:45', value: 5 },
   *   ],
   *   ...
   * }
   */
  async saveWorkloadPlans(shopId, params) {
    const { token } = await authClient.getAuthToken();

    return this.workloadPlanClient.saveWorkloadPlans(
      { authorization: token },
      shopId,
      params,
    );
  }
}

export const svcWorkloadPlansClient = new SvcWorkloadPlansClient();

export default svcWorkloadPlansClient;
