import { httpClient } from '@skello-utils/clients';
import Vue from 'vue';

const initialState = {
  error: null,
  isShopAnnualizationConfigLoading: false,
  initializationDate: null,
  periodStartDate: null,
  shopAnnualizationConfig: null,
};

const mutations = {
  performingRequest(state) {
    state.isShopAnnualizationConfigLoading = true;
  },
  requestComplete(state) {
    state.isShopAnnualizationConfigLoading = false;
  },
  requestError(state, error) {
    state.error = error;
  },
  requestSuccess(state, payload) {
    state.shopAnnualizationConfig = payload;
  },
  setInitializationDate(state, date) {
    state.initializationDate = date;
  },
  setPeriodStartDate(state, date) {
    state.periodStartDate = date;
  },
  resetAnnualizationState(state) {
    Vue.set(state, 'shopAnnualizationConfig', null);
  },
};

const actions = {
  createShopAnnualizationConfig({ commit }, params) {
    commit('performingRequest');

    return httpClient
      .post(`/v3/api/shops/${params.shop_id}/shop_annualization_config`, params)
      .then(response => {
        commit('requestSuccess', response.data.data);
        return response;
      })
      .catch(error => {
        commit('requestError', error.response);

        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
  deleteShopAnnualizationConfig({ commit }, { shopId }) {
    commit('performingRequest');

    return httpClient
      .delete(`/v3/api/shops/${shopId}/shop_annualization_config`)
      .then(() => {
        commit('requestSuccess', null);
      })
      .catch(error => {
        commit('requestError', error.response);

        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
  fetchShopAnnualizationConfig({ commit }, { shopId, date = '' }) {
    commit('performingRequest');

    const URI = date === '' ?
      `/v3/api/shops/${shopId}/shop_annualization_config` :
      `/v3/api/shops/${shopId}/shop_annualization_config?date=${date}`;

    return httpClient
      .get(URI)
      .then(response => {
        commit('requestSuccess', response.data.data);

        return response;
      })
      .catch(error => {
        commit('requestError', error.response);

        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
  updateShopAnnualizationConfig({ commit }, params) {
    commit('performingRequest');

    return httpClient
      .patch(`/v3/api/shops/${params.shop_id}/shop_annualization_config`, params)
      .then(response => {
        commit('requestSuccess', response.data.data);

        return response;
      })
      .catch(error => {
        commit('requestError', error.response);

        throw error;
      })
      .finally(() => { commit('requestComplete'); });
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
