import { httpClient } from '@skello-utils/clients';
import { localizeDefaultPoste } from '@app-js/shared/utils/default_poste_helper';

const initialState = {
  shifts: [],
  error: null,
  shiftsListRequestPending: false,
};

const mutations = {
  catchShiftsError(state, error) {
    state.error = error;
  },

  fetchShiftsSuccess(state, payload) {
    payload.data.forEach(shift => {
      shift.relationships.poste = payload.included.find(model => (
        model.id === shift.relationships.poste.data.id &&
        model.type === shift.relationships.poste.data.type
      ));
      shift.relationships.shop = payload.included.find(model => (
        model.id === shift.relationships.shop.data.id &&
        model.type === shift.relationships.shop.data.type
      ));
    });
    payload.data.forEach(shift => {
      localizeDefaultPoste(shift.relationships.poste);
    });
    state.shifts = payload.data;
  },

  performingShiftsRequest(state) {
    state.shiftsListRequestPending = true;
  },

  shiftsRequestComplete(state) {
    state.shiftsListRequestPending = false;
  },
};

const actions = {
  fetchShifts({ state, commit }, params) {
    return new Promise((resolve, reject) => {
      commit('performingShiftsRequest');

      httpClient
        .get(`/v3/api/users/${params.employee_id}/shifts`, { params })
        .then(response => {
          commit('fetchShiftsSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchShiftsError', error.response);
          reject(error);
        })
        .finally(() => { commit('shiftsRequestComplete'); });
    });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
