export function isObject(target) {
  return target !== null && typeof target === 'object';
}

/* eslint-disable */
export function merge(target) {
  const output = Object(target);
  for (let i = 1; i < arguments.length; i += 1) {
    const source = arguments[i];
    if (source !== undefined && source !== null) {
      let key;
      for (key in source) {
        if (source.hasOwnProperty(key)) {
          if (isObject(source[key])) {
            output[key] = merge(output[key], source[key]);
          } else {
            output[key] = source[key];
          }
        }
      }
    }
  }
  return output;
}
