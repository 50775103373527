import Vue from 'vue';
import Vuex from 'vuex';
import { svcEventsUrl } from '@config/env';
import SkAnalytics from '@skello-utils/analytics/sk_analytics.js';
import SvcEmployeesClient from '@skello-utils/clients/svc_employees_client.js';
import SvcEventsClient from '@skello-utils/clients/svc_events/client.js';
import * as modules from './modules/index';

Vue.use(Vuex);

// both prototype to move to app/javascript/packs/v3/index.js when saas is full SPA
// initialize these methods here allow them to be available in v2 and v3
Vue.prototype.$svcEvents = new SvcEventsClient(svcEventsUrl);
Vue.prototype.$skAnalytics = new SkAnalytics();

const cloneRoute = (to, from) => {
  const clone = { ...to };

  if (from) {
    clone.from = cloneRoute(from);
  }

  return Object.freeze(clone);
};

const initialState = {
  route: {},
  setupComplete: false,
  loading: false,
  error: false,
  navbarVisible: true,
  errorObject: {},
};

const mutations = {
  performingSetup(state) {
    state.loading = true;
    state.setupComplete = false;
  },

  setupSuccess(state) {
    state.loading = false;
    state.setupComplete = true;
    state.error = null;
  },

  setNavbarVisibility(state, navbarVisible) {
    state.navbarVisible = navbarVisible;
  },

  setupError(state, { error }) {
    state.loading = false;
    state.setupComplete = false;
    state.error = true;

    // status 500 error is currently handled in the errorPage
    if (error.response && error.response.status !== 500) {
      state.errorObject = error.response;
    } else {
      state.errorObject = { data: { status: 'Unknown' } };
    }
  },

  setupAnalytics(state) {
    const { config } = state.config;
    const { currentShop } = state.currentShop;
    const { currentOrganisation } = state.currentOrganisation;

    Vue.prototype.$skAnalytics.setAnalyticsVariables({ config, currentShop, currentOrganisation });
    Vue.prototype.$skAnalytics.load();
    Vue.prototype.$skAnalytics.sendAnalytics();
    Vue.prototype.$skAnalytics.page();
  },

  newRoute(state, route) {
    state.route = cloneRoute(route.to, route.from);
  },
};

export default new Vuex.Store({
  state: initialState,
  mutations,
  modules,
});
