import {
  formatEmployees, fullName,
} from '../../utils';
import { formatEvent } from '../../format_event';

const formatPlanningSettingContext = (store, data) => {
  const employees = formatEmployees(store.employees);

  return {
    ...formatEvent(store),
    context: {
      user_source_name: fullName(store.currentUser),
      shop_id: store.currentShop.id,
      shop_name: store.currentShop.attributes.name,
      user_target_ids: data.userIds,
      user_target_names: data.userIds.map(userId => fullName(employees[userId])),
      dates: data.dates.map(date => (new Date(date)).getTime()).join(','),
      template_type: data.templateType,
      source_template_applied: data.sourceTemplateApplied,
      conflict_resolution_choice: data.conflictResolutionChoice,
    },
  };
};

export const planningApplyTemplate =
  async (store, data) => [formatPlanningSettingContext(store, data)];
