import { punchClient } from '@skello-utils/clients';

const initialState = {
  punchClockSettings: {},
  punchClockUsers: [],
};

const mutations = {
  punchClockSettingsSuccess(state, payload) {
    state.punchClockSettings = payload;
  },
  punchClockUsersSuccess(state, payload) {
    state.punchClockUsers = payload;
  },
};

const actions = {
  async fetchPunchClockSettings({ commit }, shopId) {
    const response = await punchClient.getSetting(shopId);
    commit('punchClockSettingsSuccess', response);
    return response;
  },
  async updatePunchClockSettings({ commit }, { shopId, params }) {
    const response = await punchClient.updateSetting(shopId, params);
    commit('punchClockSettingsSuccess', response);
    return response;
  },
  async updatePartialPunchClockSettings({ commit }, { shopId, params }) {
    await punchClient.partialUpdateSetting(shopId, params);
  },
  async fetchPunchClockUsers({ commit }, shopId) {
    const response = await punchClient.getUsers(shopId);
    commit('punchClockUsersSuccess', response);
    return response;
  },
};

const getters = {};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
