import cloneDeep from 'lodash/cloneDeep';
import { associationMatcherItem } from '@skello-utils/association_matchers';
import skDate from '@skello-utils/dates';
import store from '@app-js/shared/store/index';
import { httpClient } from '@skello-utils/clients';
import { FEATURES } from '@app-js/shared/constants/features';

const FREE_TRIAL_DURATION_DAYS = 14;

const initialState = {
  currentOrganisation: {
    id: null,
    type: 'organisation',
    attributes: {
      name: '',
      rootNodeId: null,
      clusters: null,
      firstShopId: null,
      firstPunchClockShopId: null,
      packOffer: {},
    },
  },
  originalOrganisationData: {},
  error: false,
  loading: false,
  inputError: false,
  loadingUpdate: false,
  upsellRequested: false,
};

export const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  setOrganisationAttributes(state, payload) {
    Object.keys(payload).forEach(attribute => {
      state.currentOrganisation.attributes[attribute] = payload[attribute];
    });
  },

  currentOrganisationSuccess(state, payload) {
    const organisation = payload.data;

    if (organisation.relationships) {
      const owner = associationMatcherItem(
        organisation,
        payload.included, {
          key: 'owner',
          type: 'user',
        },
      );
      Object.assign(organisation.relationships, { owner });
    }

    state.currentOrganisation = organisation;
    state.originalOrganisationData = cloneDeep(state.currentOrganisation);
  },

  currentOrganisationError(state, error) {
    state.error = error;
  },

  setOrganisationInputError(state, error) {
    state.inputError = error;
  },

  squashCurrentOrganisation(state) {
    state.currentOrganisation = cloneDeep(state.originalOrganisationData);
  },

  setPrimaryContact(state, newValue) {
    state.currentOrganisation.relationships.owner = newValue;
  },

  setUpsellRequested(state) {
    state.upsellRequested = true;
  },
};

const actions = {
  fetchCurrentOrganisation({ commit }, shopId = null) {
    commit('performingRequest');

    const url = shopId ? `/v3/api/current_organisation?shop_id=${shopId}` : '/v3/api/current_organisation';

    return httpClient
      .get(url)
      .then(response => {
        commit('currentOrganisationSuccess', response.data);
        if (
          response.data.data.attributes.cancelledShop &&
          !store.getters['navContext/isReportsPage'] &&
          !store.getters['navContext/isProfilePage']
        ) {
          // See : app/controllers/v3/api/current_organisation_controller.rb
          window.location = '/v3/shops/all/reports?cancelled_shop=true';
        }
      })
      .catch(error => {
        commit('currentOrganisationError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },
  updateCurrentOrganisation({ state, commit }, organisation) {
    commit('performingRequest');

    const { owner } = organisation.relationships;

    const params = {
      organisation: {
        name: organisation.attributes.name,
        denomination_sociale: organisation.attributes.denominationSociale,
        headquarters_address: organisation.attributes.headquartersAddress,
        headquarters_city: organisation.attributes.headquartersCity,
        headquarters_postcode: organisation.attributes.headquartersPostcode,
        headquarters_region: organisation.attributes.headquartersRegion,
        headquarters_country: organisation.attributes.headquartersCountry,
        siren: organisation.attributes.siren,
        tva_number: organisation.attributes.tvaNumber,
        benefit_in_kind: organisation.attributes.benefitInKind,
        business_sector: organisation.attributes.businessSector,
        public_badgings: organisation.attributes.publicBadgings,
        owner_id: owner && owner.id,
      },
    };
    return httpClient
      .patch('/v3/api/current_organisation', params)
      .then(response => {
        commit('currentOrganisationSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('currentOrganisationError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },
  requestPackUpsell({ state, commit }, requestParams) {
    const currentOrganisation = state.currentOrganisation;
    const currentShop = store.state.currentShop.currentShop;
    const currentLicense = store.state.currentLicense.currentLicense;

    const params = {
      user: {
        organisation_id: currentOrganisation.id,
        organisation_name: currentOrganisation.attributes.name,
        current_shop_id: currentShop.id,
        current_shop_name: currentShop.attributes.name,
        user_license: currentLicense.attributes.originalType,
        button_clicked: requestParams.buttonClicked,
        upsell_type: requestParams.upsellType,
        current_page: requestParams.currentPage,
      },
    };

    return httpClient
      .post('/v3/api/upsells/request_demo', params)
      .then(response => {
        commit('setUpsellRequested');
        return response;
      })
      .catch(error => {
        throw error;
      });
  },
  requestFeaturesUpsellDownsell({ _state, _commit }, params) {
    return httpClient
      .post('/v3/api/upsells/upsell_downsell_features', params)
      .then(response => response)
      .catch(error => {
        throw error;
      });
  },
};

const getters = {
  unsavedChangesToCurrentOrganisation(state) {
    const originalOrganisationState = cloneDeep(state.originalOrganisationData);

    // Exclude these fields from comparison (not managed by sticky bar)
    originalOrganisationState.attributes.zeltyKey = state.currentOrganisation.attributes.zeltyKey;
    originalOrganisationState.attributes.tillerKey = state.currentOrganisation.attributes.tillerKey;
    originalOrganisationState.attributes.lightspeedKey =
      state.currentOrganisation.attributes.lightspeedKey;

    return JSON.stringify(state.currentOrganisation) !== JSON.stringify(originalOrganisationState);
  },
  checkPackOfferFlag(state) {
    const { packOffer } = state.currentOrganisation.attributes;

    return flag => packOffer && packOffer[flag];
  },

  isMulticontractsEnabled(state) {
    const maxContractsLimit =
      state.currentOrganisation.attributes.packOffer.max_contracts_per_user_allowed;

    return maxContractsLimit === null || maxContractsLimit > 1 || store.getters['currentShop/isFeatureEnabledOnShop']('multicontract');
  },

  maxDocumentAllowedPerUser(state) {
    if (store.getters['currentShop/isFeatureEnabledOnShop']('multidocument')) {
      return null;
    }

    return state.currentOrganisation.attributes.packOffer.max_documents_per_user_allowed;
  },

  isHrisActive: state => state.currentOrganisation.attributes.hrisActive,

  // eslint-disable-next-line no-shadow
  limitedDocumentTemplates(states, getters) {
    const maxDocumentTemplateAllowed = getters.checkPackOfferFlag('max_document_templates_allowed');
    // Warning: maxDocumentTemplateAllowed is null when infinite
    return (maxDocumentTemplateAllowed === 1) || (maxDocumentTemplateAllowed === 0);
  },

  atLeastOneShopWithPunchClock(state, _getters, rootState, rootGetters) {
    return rootGetters['features/isFeatureEnabled'](FEATURES.FEATURE_TIMECLOCK, 'all', () => !!state.currentOrganisation.attributes.firstPunchClockShopId);
  },
  freeTrialDaysLeft(state) {
    const freeTrialStartedAt = skDate.utc(state.currentOrganisation.attributes.freeTrialStartedAt);
    const daysElapsed = skDate().endOf('day').utc().diff(freeTrialStartedAt, 'days');
    return Math.max(FREE_TRIAL_DURATION_DAYS - daysElapsed, 0);
  },
  // eslint-disable-next-line no-shadow
  isFreeTrialEnabled(state, getters) {
    return store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_SELF_SERVE_RAUL') &&
      state.currentOrganisation.attributes.origin === 'self_serve' &&
      // If the selfServe flow was completed before FEATUREDEV_SELF_SERVE_RAUL activation
      // freeTrialStartedAt will be null and we should not display the free trial
      !!state.currentOrganisation.attributes.freeTrialStartedAt &&
      getters.freeTrialDaysLeft > 0;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
