import fr from './badgings.fr.yml';
import en from './badgings.en.yml';
import es from './badgings.es.yml';
import de from './badgings.de.yml';
import it from './badgings.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
