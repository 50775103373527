import {
  STORAGE_CONSENT_KEY,
  ANALYTICS_CONSENT_KEY,
} from '@app-js/shared/components/CookieDialog/constants';
import { ANALYTICS_KEYS } from '@app-js/shared/constants/analytics';
import initializeSegment from '@skello-utils/analytics/initialize_segment.js';
import i18n from '@plugins/i18n';
import Cookies from 'js-cookie';
import moment from 'moment';

class SkAnalytics {
  constructor() {
    initializeSegment();
    this.stdProperties = {};
    this.traits = {};
    this.groupTraits = {};
  }

  setAnalyticsVariables({ config, currentShop, currentOrganisation }) {
    if (config) {
      this.logImpersonate = config.segment_data.log_impersonate;
      this.intercomKey = config.segment_data.intercom_key;
      this.segmentKey = config.segment_data.segment_api_key;
    }
    this.setStdProperties(config, currentShop, currentOrganisation);
    this.setTraits(config, currentOrganisation);
    this.setGroupTraits(currentShop, currentOrganisation);
  }

  setStdProperties(config, currentShop, currentOrganisation) {
    if (config && config.segment_data.true_user.data) {
      const affiliatedShop =
        this.setTraitsAttribute(config.segment_data.affiliated_shop.data, { attributes: {} });
      // note: `account_uid` is a necessary attribute for Salesmachine
      // by default we set the attribute value with the affiliated shop
      // and if a contextual shop is present, we override the value below
      // ref: https://segment.com/docs/connections/destinations/catalog/salesmachine/
      this.stdProperties.shop_id = this.setTraitsAttribute(affiliatedShop.id);
      this.stdProperties.account_uid = this.setTraitsAttribute(affiliatedShop.id);

      this.stdProperties.user_id = config.segment_data.true_user.data.id;
    }

    if (currentShop) {
      this.stdProperties.shop_id = currentShop.id;

      if (currentShop.id !== 'all') {
        this.stdProperties.account_uid = this.stdProperties.shop_id;
      }
    }

    if (currentOrganisation) {
      this.stdProperties.organisation_id = currentOrganisation.id;
    }
  }

  setTraits(config, currentOrganisation) {
    // if the attributes is undefined, segment does not sent the key
    // so we need to force null to be sent

    if (config && config.segment_data.true_user.data) {
      const trueUser = config.segment_data.true_user.data;
      const affiliatedShop =
        this.setTraitsAttribute(config.segment_data.affiliated_shop.data, { attributes: {} });
      const affiliatedShopConvention = config.segment_data.affiliated_shop_convention.convention;
      const userCreatedAt = trueUser.attributes.createdAt ?
        moment(trueUser.attributes.createdAt).format('YYYY-MM-DD') : null;

      this.traits.anonymous = false;
      this.traits.app_skello_io = !!window.location.origin.match('app.skello.io');
      this.traits.email = this.setTraitsAttribute(trueUser.attributes.email);
      this.traits.first_name = this.setTraitsAttribute(trueUser.attributes.firstName);
      this.traits.last_name = this.setTraitsAttribute(trueUser.attributes.lastName);
      this.traits.city = this.setTraitsAttribute(trueUser.attributes.city);
      this.traits.gender = this.setTraitsAttribute(trueUser.attributes.gender);
      this.traits.archived = this.setTraitsAttribute(trueUser.attributes.currentlyArchived);
      this.traits.user_created_at = userCreatedAt;
      this.traits.role = this.setTraitsAttribute(config.segment_data.true_user_licence);
      this.traits.role_licence_name =
        this.setTraitsAttribute(config.segment_data.true_user_licence_original_type);
      // note: necessary for SalesMachine
      // ref: https://segment.com/docs/connections/destinations/catalog/salesmachine/
      this.traits.account_uid = this.setTraitsAttribute(affiliatedShop.id);
      this.traits.shop_id = this.setTraitsAttribute(affiliatedShop.id);
      this.traits.shop_name = this.setTraitsAttribute(affiliatedShop.attributes.name);
      this.traits.shop_country = this.setTraitsAttribute(affiliatedShop.attributes.country);
      this.traits.shop_coach = this.setTraitsAttribute(affiliatedShop.attributes.coach);
      this.traits.shop_convention = this.setTraitsAttribute(affiliatedShopConvention?.legible_name);
      this.traits.flexible_convention = this.setTraitsAttribute(affiliatedShopConvention?.flexible);
      this.traits.shop_modulation = this.setTraitsAttribute(affiliatedShop.attributes.modulation);
      this.traits.shop_with_badging =
        this.setTraitsAttribute(affiliatedShop.attributes.hasPunchClock);
      this.traits.shop_postes_count =
        this.setTraitsAttribute(affiliatedShop.attributes.postesCount);
      this.traits.shop_teams_count =
        this.setTraitsAttribute(affiliatedShop.attributes.teamsCount);
      this.traits.shop_active_users_count =
        this.setTraitsAttribute(affiliatedShop.attributes.usersCount);
      this.traits.shop_smart_planning_activations =
        this.setTraitsAttribute(affiliatedShop.attributes.automaticPlanningUses, 0);
      this.traits.shop_activated_unassigned_shifts =
        this.setTraitsAttribute(affiliatedShop.attributes.activatedUnassignedShifts, false);
    } else {
      this.traits.anonymous = true;
    }

    if (currentOrganisation) {
      const organisationCreatedAt = currentOrganisation.attributes.createdAt ?
        moment(currentOrganisation.attributes.createdAt).format('YYYY-MM-DD') : null;

      this.traits.organisation_id = currentOrganisation.id;
      this.traits.organisation_name = this.setTraitsAttribute(currentOrganisation.attributes.name);
      this.traits.organisation_created_at = this.setTraitsAttribute(organisationCreatedAt);
      this.traits.organisation_status =
        this.setTraitsAttribute(currentOrganisation.attributes.status);
      this.traits.pack_offer_name =
        this.setTraitsAttribute(currentOrganisation.attributes.packOffer.name);
      this.traits.shop_with_hris =
        this.setTraitsAttribute(currentOrganisation.attributes.hrisActive);
      this.traits.business_sector = i18n.t(`business_sector.${currentOrganisation.attributes.businessSector}`) || null;
      this.traits.cluster = this.setTraitsAttribute(currentOrganisation.attributes.clusters);
      this.traits.organisation_origin =
        this.setTraitsAttribute(currentOrganisation.attributes.origin);
    }
  }

  setGroupTraits(currentShop, currentOrganisation) {
    if (currentShop && currentShop.id && currentShop.id !== 'all') {
      this.groupTraits = {
        id: currentShop.id,
        name: currentShop.attributes.name,
        plan: currentOrganisation.attributes.packOffer.name,
        organisation_id: currentOrganisation.id,
        organisation_name: currentOrganisation.attributes.name,
      };
    }
  }

  load() {
    if (!this.canIdentify()) return;

    // Persist false removes user data from localstorage, which was a security issue
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/persistence/#user-settings
    analytics.load(this.segmentKey, {
      user: {
        persist: false,
      },
    });
  }

  page() {
    if (!this.canSendAnalyticsData()) return;

    analytics.page();
  }

  sendAnalytics() {
    if (!this.canIdentify()) return;

    analytics.ready(() => {
      analytics.identify(
        this.stdProperties.user_id,
        this.traits,
        {
          integrations: {
            Intercom: {
              user_hash: this.intercomKey,
            },
          },
        },
      );

      if (this.groupTraits.id) {
        analytics.group(
          this.groupTraits.id,
          this.groupTraits,
        );
      }
    });
  }

  track(vTrackSegment, options = {}) {
    if (!this.canSendAnalyticsData()) return;

    analytics.track(ANALYTICS_KEYS[vTrackSegment],
      {
        ...this.stdProperties,
        ...options,
        ...{ track_segment: vTrackSegment },
      },
    );
  }

  canIdentify() {
    // logImpersonate is false if environment is production and impersonating mode is active
    // it is defined in the config_controller
    return this.segmentKey && this.logImpersonate;
  }

  canSendAnalyticsData() {
    // logImpersonate is false if environment is production and impersonating mode is active
    // it is defined in the config_controller
    const trackingConsent = Cookies.get(STORAGE_CONSENT_KEY)?.split(',') || [];

    return this.segmentKey &&
      this.logImpersonate &&
      trackingConsent.includes(ANALYTICS_CONSENT_KEY);
  }

  setTraitsAttribute(props, fallback = null) {
    return [undefined, null].includes(props) ? fallback : props;
  }
}

export default SkAnalytics;
