import {
  formatEmployees, fullName,
} from '../../utils';
import { formatEvent } from '../../format_event';

const formatPlanningPublishContext = (store, data) => {
  const employees = formatEmployees(store.employees);

  return {
    ...formatEvent(store),
    context: {
      user_source_name: fullName(store.currentUser),
      user_target_ids: data.userIds,
      user_target_names: data.userIds.map(userId => fullName(employees[userId])),
      date: (new Date(data.date)).getTime(),
      shop_id: store.currentShop.id,
      shop_name: store.currentShop.attributes.name,
      sms: !!data.sms,
      mail: !!data.email,
    },
  };
};

export const planningPublish = async (store, data) => [formatPlanningPublishContext(store, data)];
