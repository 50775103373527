import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatEmployeeHourTrackerUpdateContext = (store, data) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    user_target_id: data.employee.id.toString(),
    user_target_name: fullName(data.employee),
    date: data.date,
    values: data.values,
  },
});

export const formatEmployeeHourTrackerUpdate =
  async (store, data) => [formatEmployeeHourTrackerUpdateContext(store, data)];
