import { fullName } from '../../../utils';
import { formatEvent } from '../../../format_event';

const formatShopTeamDeleteContext = (store, { id, name }) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    team_id: id,
    team_name: name,
  },
});

export const formatShopTeamDelete =
  async (store, data) => [formatShopTeamDeleteContext(store, data)];
