import fr from './reports.fr.yml';
import en from './reports.en.yml';
import es from './reports.es.yml';
import de from './reports.de.yml';
import it from './reports.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
