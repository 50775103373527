import { httpClient } from '@skello-utils/clients';

const initialState = {
  platformAlerts: [],
  error: false,
  loading: false,
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  fetchPlatformAlertsSuccess(state, payload) {
    state.platformAlerts = payload.data;
  },

  fetchPlatformAlertsError(state, error) {
    state.error = error;
  },
};

const actions = {
  fetchPlatformAlerts({ commit }, clusterNodeId) {
    commit('performingRequest');
    httpClient
      .get(`/v3/api/platform_alerts?cluster_node_id=${clusterNodeId}`)
      .then(response => {
        commit('fetchPlatformAlertsSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('fetchPlatformAlertsError', error.response.data);
        throw error;
      })
      .finally(() => {
        commit('requestComplete');
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
