import fr from './text_document_templates.fr.yml';
import en from './text_document_templates.en.yml';
import es from './text_document_templates.es.yml';
import de from './text_document_templates.de.yml';
import it from './text_document_templates.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
