import fr from './vue_common.fr.yml';
import en from './vue_common.en.yml';
import es from './vue_common.es.yml';
import de from './vue_common.de.yml';
import it from './vue_common.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
