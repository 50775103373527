import { fullName } from '../../../utils';
import { formatEvent } from '../../../format_event';

const formatShopPosteDeleteContext = (store, { id, name }) => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
    poste_id: id,
    poste_name: name,
  },
});

export const formatShopPosteDelete =
  async (store, data) => [formatShopPosteDeleteContext(store, data)];
