<template>
  <SkOroraDialog
    id="sk-cookie-consent__dialog"
    :class="dialogClasses"
    :icon-options="{ name: showTrackingOptions ? '' : 'CookieIcon' }"
    :is-closable="false"
    :is-dismissable="false"
    :show-footer="!showTrackingOptions"
    :title="dialogTitle"
    size="medium"
  >
    <template #body>
      <main
        v-if="showTrackingOptions"
        class="cookie-consent"
      >
        <div class="sk-text-small-regular">
          {{ $t('cookie_consent.settings.main_description') }}
        </div>
        <div class="cookie-consent__settings-all">
          <SkOroraButton
            variant="secondary"
            @click="denyCookie"
          >
            {{ $t('cookie_consent.settings.refuse_all') }}
          </SkOroraButton>
          <SkOroraButton @click="acceptAllCookies">
            {{ $t('cookie_consent.settings.accept_all') }}
          </SkOroraButton>
        </div>
        <div class="sk-heading-small-semibold">
          {{ $t('cookie_consent.settings.subtitle') }}
        </div>
        <div
          v-for="option in trackingOptions"
          :key="option.title"
          class="cookie-consent__settings-input-section"
        >
          <div :class="inputSectionHeaderClass(option)">
            <div class="sk-text-medium-semibold">
              {{ option.title }}
            </div>
            <div
              v-if="option.value === 'activated'"
              class="sk-text-small-semibold"
            >
              {{ $t('cookie_consent.settings.activated') }}
            </div>
            <SkSwitch
              v-else
              v-model="option.value"
            />
          </div>
          <div class="sk-text-small-regular">
            {{ option.description }}
            <div
              v-if="option.descriptionHighlight"
              class="sk-text-small-semibold"
            >
              {{ option.descriptionHighlight }}
            </div>
          </div>
        </div>
        <div class="cookie-consent__settings-save-section">
          <SkOroraButton @click="handleSaveTrackings">
            {{ $t('cookie_consent.settings.save') }}
          </SkOroraButton>
        </div>
      </main>
      <main
        v-else
        class="cookie-consent cookie-consent__sizable"
      >
        {{ $t('cookie_consent.description') }}
        <a
          :href="$t('cookie_consent.privacy_policy_link')"
          target="_blank"
          rel="noopener norefferer"
          class="cookie-consent__link"
        >
          {{ $t('cookie_consent.privacy_policy') }}.
        </a>
        <br>
        <div
          class="cookie-consent__link"
          @click="denyCookie"
        >
          {{ $t('cookie_consent.navigate_without_approve') }}
        </div>
      </main>
    </template>
    <template #footer>
      <footer class="cookie-consent__footer">
        <SkOroraButton
          size="large"
          variant="secondary"
          @click="setShowTrackingOptions"
        >
          {{ $t('cookie_consent.settings_cta') }}
        </SkOroraButton>
        <SkOroraButton
          size="large"
          @click="acceptAllCookies"
        >
          {{ $t('cookie_consent.accept') }}
        </SkOroraButton>
      </footer>
    </template>
  </SkOroraDialog>
</template>

<script>
import Cookies from 'js-cookie';

import {
  MODAL_SHOW_EVENT,
  MODAL_HIDE_EVENT,
  SkOroraDialog,
  SkOroraButton,
  SkSwitch,
  skListenersMixin,
} from '@skelloapp/skello-ui';
import {
  STORAGE_CONSENT_KEY,
  FUNCTIONAL_CONSENT_KEY,
  MARKETING_CONSENT_KEY,
  PERSONNALIZATION_CONSENT_KEY,
  ANALYTICS_CONSENT_KEY,
  initializeHotjar,
} from './constants';

export default {
  name: 'CookieDialog',
  components: {
    SkOroraButton,
    SkOroraDialog,
    SkSwitch,
  },
  mixins: [skListenersMixin],
  data() {
    return {
      showTrackingOptions: false,
      cookieConsents: null,
      trackingOptions: [
        {
          name: FUNCTIONAL_CONSENT_KEY,
          title: this.$t('cookie_consent.settings.functional.title'),
          description: this.$t('cookie_consent.settings.functional.description'),
          value: 'activated',
          descriptionHighlight: this.$t('cookie_consent.settings.functional.description_highlight'),
        },
        {
          name: MARKETING_CONSENT_KEY,
          title: this.$t('cookie_consent.settings.marketing.title'),
          description: this.$t('cookie_consent.settings.marketing.description'),
          value: false,
        },
        {
          name: PERSONNALIZATION_CONSENT_KEY,
          title: this.$t('cookie_consent.settings.personnalization.title'),
          description: this.$t('cookie_consent.settings.personnalization.description'),
          value: false,
        },
        {
          name: ANALYTICS_CONSENT_KEY,
          title: this.$t('cookie_consent.settings.analytics.title'),
          description: this.$t('cookie_consent.settings.analytics.description'),
          value: false,
        },
      ],
    };
  },
  computed: {
    dialogTitle() {
      return this.showTrackingOptions ? this.$t('cookie_consent.settings.title') : this.$t('cookie_consent.title');
    },
    dialogClasses() {
      return {
        'resizable-dialog': !this.showTrackingOptions,
      };
    },

  },
  mounted() {
    const acceptedTrackings = Cookies.get(STORAGE_CONSENT_KEY)?.split(',') || [];

    if (acceptedTrackings.length === 0) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'sk-cookie-consent__dialog');
    } else {
      this.handleAcceptedCookies(acceptedTrackings);
    }
  },
  methods: {
    handleAcceptedCookies(trackingKeys) {
      if (trackingKeys.includes(MARKETING_CONSENT_KEY)) {
        this.acceptMarketingTracking();
      }

      if (trackingKeys.includes(ANALYTICS_CONSENT_KEY)) {
        this.acceptAnalyticsTracking();
      }

      if (trackingKeys.includes(PERSONNALIZATION_CONSENT_KEY)) {
        this.acceptPersonnalizationTracking();
      }
    },
    handleSaveTrackings() {
      const acceptedTrackings =
        this.trackingOptions.filter(input => !!input.value).map(input => input.name);

      Cookies.set(
        STORAGE_CONSENT_KEY,
        acceptedTrackings,
        {
          expires: 365,
        },
      );

      this.handleAcceptedCookies(acceptedTrackings);

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'sk-cookie-consent__dialog');
    },
    acceptAllCookies() {
      this.acceptAnalyticsTracking();
      this.acceptMarketingTracking();
      this.acceptPersonnalizationTracking();

      Cookies.set(
        STORAGE_CONSENT_KEY,
        [
          FUNCTIONAL_CONSENT_KEY,
          MARKETING_CONSENT_KEY,
          PERSONNALIZATION_CONSENT_KEY,
          ANALYTICS_CONSENT_KEY,
        ],
        {
          expires: 365,
        },
      );

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'sk-cookie-consent__dialog');
    },
    denyCookie() {
      Cookies.set(
        STORAGE_CONSENT_KEY,
        [FUNCTIONAL_CONSENT_KEY],
        {
          expires: 90,
        },
      );

      this.emitOnRoot(MODAL_HIDE_EVENT, null, 'sk-cookie-consent__dialog');
    },
    acceptMarketingTracking() {
      this.activateGoogleTag(MARKETING_CONSENT_KEY);
    },
    acceptAnalyticsTracking() {
      this.activateGoogleTag(ANALYTICS_CONSENT_KEY);
      initializeHotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    },
    acceptPersonnalizationTracking() {
      this.activateGoogleTag(PERSONNALIZATION_CONSENT_KEY);
    },
    activateGoogleTag(event) {
      dataLayer.push({ event });
    },
    setShowTrackingOptions() {
      this.showTrackingOptions = true;
    },
    inputSectionHeaderClass(input) {
      return {
        'cookie-consent__settings-input-section__header': true,
        'cookie-consent__settings-input-section__header--activated': input.value === 'activated',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#sk-cookie-consent__dialog {
  &.resizable-dialog {
    @media all and (max-width: 1000px) {
      top: unset;

      ::v-deep .sk-orora-dialog--medium {
        max-width: unset;
        width: unset;
      }
    }
  }

  ::v-deep .sk-orora-dialog__body-wrapper {
    margin-bottom: 24px;
  }

  .cookie-consent__settings-all {
    padding-top: 16px;
    padding-bottom: 32px;

    @media all and (max-width: 600px) {
      display: flex;
      flex-direction: column;

      .sk-button {
        + .sk-button {
          margin-top: 8px;
          margin-left: 0px !important;
        }
      }
    }

    .sk-button {
      + .sk-button {
        margin-left: 4px;
      }
    }
  }

  .cookie-consent__link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: $fw-semi-bold;
    max-width: 200px;
    color: $sk-black;

    &:hover {
      text-decoration: none;
      background-color: $sk-white;
      color: $sk-grey-50;
    }
  }
}

.cookie-consent {
  padding: 0px 24px 0px 24px;
  color: $sk-grey-50;

  .sk-heading-small-semibold {
    color: $sk-black;
    padding-bottom: 16px;
    font-size: 16px;
  }
}

.cookie-consent__settings-input-section {
  border-bottom: 1px solid $sk-grey-10;
  padding-bottom: 16px;
  margin-bottom: 5px;
}

.cookie-consent__settings-input-section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--activated {
    padding-bottom: 12px;
  }

  ::v-deep .sk-switch__slider {
    margin: 0;
    margin-left: 15px;
  }
}

.cookie-consent__settings-save-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 8px;

  @media all and (max-width: 600px) {
    .sk-button {
      width: 100%;
    }
  }
}

.cookie-consent__footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 24px 30px 24px;

  .sk-button {
    + .sk-button {
      margin-top: 8px;
      margin-left: 0px;
    }
  }
}
</style>
