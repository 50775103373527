export const STORAGE_CONSENT_KEY = 'cookie_consent';
export const FUNCTIONAL_CONSENT_KEY = 'functional';
export const MARKETING_CONSENT_KEY = 'marketing';
export const PERSONNALIZATION_CONSENT_KEY = 'personnalization';
export const ANALYTICS_CONSENT_KEY = 'analytics';

// eslint-disable-next-line func-names
export const initializeHotjar = (function (h, o, t, j, a, r) {
  // eslint-disable-next-line prefer-rest-params, func-names
  h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); };
  // eslint-disable-next-line no-underscore-dangle
  h._hjSettings = { hjid: 741753, hjsv: 6 };
  a = o.getElementsByTagName('head')[0];
  r = o.createElement('script'); r.async = 1;
  // eslint-disable-next-line no-underscore-dangle
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
});
