import fr from './employee_view.fr.yml';
import en from './employee_view.en.yml';
import es from './employee_view.es.yml';
import de from './employee_view.de.yml';
import it from './employee_view.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};
