import { httpClient } from '@skello-utils/clients';

const initialState = {
  loading: false,
  competencies: [],
};

const mutations = {
  performingRequest(state) {
    state.loading = true;
  },

  requestComplete(state) {
    state.loading = false;
  },

  fetchCompetenciesSuccess(state, payload) {
    const competencies = payload.data.map(({ attributes }) => ({
      userId: attributes.userId.toString(),
      posteId: attributes.posteId.toString(),
      primary: attributes.primary,
    }));
    state.competencies = competencies;
  },
};

const actions = {
  fetchCompetencies({ commit }, { shopId, users }) {
    commit('performingRequest');

    const params = {
      user_ids: users.map(user => user.id),
      shop_id: shopId,
    };
    return httpClient
      .get('/v3/api/automatic_planning/competencies', { params })
      .then(response => {
        commit('fetchCompetenciesSuccess', response.data);
        return response;
      })
      .catch(error => { throw error; })
      .finally(() => commit('requestComplete'));
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
