import { httpClient } from '@skello-utils/clients';

const initialState = {
  optiStructure: {},
  postesOpti: [],
  templatesOpti: [],
  weekPlanningPrevision: {},
  posteStructure: {
    manual: {},
    template: {},
  },
  optiStatus: {},
  requestPending: {
    fetchOptiStructure: false,
    fetchTemplatesPosteStructure: false,
    verifyOptiStructure: false,
    updateOptiStructure: false,
    updatePosteStructure: false,
  },
  error: null,
};

const mutations = {
  requestPending(state, requestType) {
    state.requestPending[requestType] = true;
  },
  requestComplete(state, requestType) {
    state.requestPending[requestType] = false;
  },
  catchError(state, payload) {
    state.error = payload;
  },
  // actions are using v1 endpoints -> data is not properly structured
  // and requires V3 formatting
  fetchOptiStructureSuccess(state, payload) {
    // Convert payload structure from v1 to v3
    state.optiStructure = {
      id: payload.opti_structure.id.toString(),
      attributes: payload.opti_structure,
    };
    state.postesOpti = payload.postes.map(poste => ({
      id: poste.id.toString(),
      attributes: poste,
    }));
    state.templatesOpti = payload.templates.map(template => ({
      id: template.id.toString(),
      attributes: template,
    }));
    state.weekPlanningPrevision = {
      id: payload.week_planning_prevision.id.toString(),
      attributes: payload.week_planning_prevision,
    };
    state.posteStructure.manual = {};
    payload.week_planning_prevision_postes.forEach(wppp => {
      state.posteStructure.manual[wppp.poste_id] = wppp.employees_per_day;
    });
  },
  fetchTemplatePosteStructureSuccess(state, payload) {
    state.posteStructure.template = payload.poste_structure;
  },
  verifyOptiStructureSuccess(state, payload) {
    state.optiStatus = payload;
  },
  updateOptiStructureSuccess(state, payload) {
    state.optiStructure = {
      id: opti_structure.id.toString(),
      attributes: payload.opti_structure,
    };
  },
  updatePosteStructureSuccess(state, payload) {
    state.posteStructure[payload.calculation_method] = payload.poste_structure;
    state.requestPending.updatePosteStructure = false;
  },
};

const actions = {
  fetchOptiStructure({ commit }, shopId) {
    commit('requestPending', 'fetchOptiStructure');

    return httpClient
      .get(`/opti/v1/shops/${shopId}/opti_structures`)
      .then(response => {
        commit('fetchOptiStructureSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'fetchOptiStructure'));
  },

  fetchTemplatePosteStructure({ commit }, templateId) {
    commit('requestPending', 'fetchTemplatePosteStructure');

    return httpClient
      .get(`/opti/v1/planning_templates/${templateId}`)
      .then(response => {
        commit('fetchTemplatePosteStructureSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'fetchTemplatePosteStructure'));
  },

  verifyOptiStructure({ commit, state }, params) {
    commit('requestPending', 'verifyOptiStructure');

    const requestParams = {
      date: params.date,
      timezone_offset: params.timezoneOffset,
      opti_structure: state.optiStructure.attributes,
    };

    return httpClient
      .post(`/opti/v1/shops/${params.shopId}/opti_structures/opti_structure_check`, requestParams)
      .then(response => {
        commit('verifyOptiStructureSuccess', response.data);
        return response;
      })
      .catch(error => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'verifyOptiStructure'));
  },

  updateOptiStructure({ commit }, params) {
    commit('requestPending', 'updateOptiStructure');

    return httpClient
      .patch(`opti/v1/shops/${params.shopId}/opti_structure_check`, params)
      .then(response => {
        commit('updateOptiStructureSuccess', params);
        return response;
      })
      .catch(() => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => commit('requestComplete', 'updateOptiStructure'));
  },

  updatePosteStructure({ commit }, params) {
    commit('requestPending', 'updatePosteStructure');

    return httpClient
      .patch('/v3/api/plannings/week_planning_prevision', params)
      .then(response => {
        commit('updatePosteStructureSuccess', params);
        return response;
      })
      .catch(() => {
        commit('catchError', error);
        throw error;
      })
      .finally(() => {
        commit('requestComplete', 'updatePosteStructure');
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
};
