import { fullName } from '../../utils';
import { formatEvent } from '../../format_event';

const formatShopBankHolidaysTrackerDisabledContext = store => ({
  ...formatEvent(store),
  context: {
    user_source_name: fullName(store.currentUser),
    shop_name: store.currentShop.attributes.name,
    shop_id: store.currentShop.id.toString(),
  },
});

export const formatShopBankHolidaysTrackerDisabled =
  async store => [formatShopBankHolidaysTrackerDisabledContext(store)];
